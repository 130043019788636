<script>
import { INTEGRATION_BUILDER_KEY } from '../../../model';


const builderKey = INTEGRATION_BUILDER_KEY;

/**
 * Базовый компонент этапов.
 * Нужно реализовать его методы
 */
export default {
  name: 'BaseStage',
  inject: [builderKey],
  computed: {
    builder() {
      return this[builderKey];
    },
  },
  methods: {
    /**
     * Вызывается в момент, когда нажимается кнопка перехода к слудующему этапу
     */
    verify() {
      throw new Error('Method not implemented');
    },
    /**
     * Метод жизненного цикла этапа, вызывается в момент перехода на этап
     */
    activated() {},
    /**
     * Метод жизненного цикла этапа, вызывается в момент перехода с этапа
     */
    deactivated() {}
  }
}
</script>
