import './types';
import OpenServerApi from "@/interfaces/OpenServerApi";
import { ADD_OUTLETS_PATH, DELETE_OUTLETS_PATH, GET_ALL_OUTLETS_PATH } from './constants';

class SettingsService {
    reindexProducts() {
        return OpenServerApi().post(`/settings/products/reindex`);
    }

    /**
     * Возвращает все склады
     * @returns {Promise<OutletDTO[]>}
     */
    getAllOutlets() {
        return OpenServerApi().get(GET_ALL_OUTLETS_PATH);
    }

    /**
     * Добавляет склады
     * @param {AddOutltetsDTO} inputDTO 
     * @returns {Promise<void>}
     */
    addOutltets(inputDTO) {
        return OpenServerApi().post(ADD_OUTLETS_PATH, inputDTO);
    }

    /**
     * Удаляет склад
     * @param {DeleteOutletDTO[]} inputDTO 
     * @returns {Promise<void>}
     */
    deleteOutlets(inputDTO) {
        return OpenServerApi().post(DELETE_OUTLETS_PATH, inputDTO);
    }
}

export default new SettingsService();
