import OpenServerApi from "@/interfaces/OpenServerApi";

class AnalyticDataService {
    getCategories() {
        return OpenServerApi().get('/analytics/categories');
    }

    getTrends() {
        return OpenServerApi().get('/analytics/trends');
    }

    getMoreCategories(fromCategoryID) {
        return OpenServerApi().get(`/analytics/categories/more/${fromCategoryID}`);
    }

    getMoreTrends(fromProductID) {
        return OpenServerApi().get(`/analytics/trends/more/${fromProductID}`);
    }

    exportCategoriesToExcel(exportKey) {
        return OpenServerApi().get(`/analytics/categories/export/excel/${exportKey}`, {
            responseType: 'blob'
        });
    }

    exportTrendsToExcel(exportKey) {
        return OpenServerApi().get(`/analytics/trends/export/excel/${exportKey}`, {
            responseType: 'blob'
        });
    }

    exportKaspiUrls() {
        return OpenServerApi().get(`datasources/products/reports/urls/kaspi`, {
            responseType: 'blob'
        });
    }

    exportAllProducts() {
        return OpenServerApi().get(`datasources/reports/products/data`, {
            responseType: 'blob'
        });
    }
}

export default new AnalyticDataService();