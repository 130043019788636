import ServiceFacade from "@/services/ServiceFacade";
import { notificatonStatuses, loadingStates } from "@/enumerations";

const state = () => ({
    
});

const actions = {
    async reindexProducts({ commit }) {
        try {
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.warn,
                message: "Индексация началась в фоне, займет 30 секунд"
            }, { root: true });
            commit('core/SET_LOADING_STATUS', loadingStates.BUSY, { root: true });
            const { status } = await ServiceFacade.reindexProducts();
            if (status == 200) {
                commit('core/SET_NOTIFICATION', {
                    status: notificatonStatuses.success,
                    message: "Индексация завершена"
                }, { root: true });
                commit('core/SET_LOADING_STATUS', loadingStates.SUCCESS, { root: true });
            }
        } catch (error) {
            let reason = "";
            switch (error.response.status) {
                case 405: reason = "Слишком частый запрос"; break;
                default: reason = "Неизвестная причина"; break;
            }
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: `Ошибка индексации (${error.response.status}): ${reason}`
            }, { root: true });
            commit('core/SET_LOADING_STATUS', loadingStates.ERROR, { root: true });
        }
    },
}

export default {
    namespaced: true,
    state,
    actions,
}