import { bucketNamespace, datasources, xlsImportOperationTypes, reportTypes } from "../enumerations";
import CompanyDataService from "@/services/CompanyDataService";
import SettingsService from "@/services/SettingsService";
import ProductDataService from "./ProductDataService";
import PlatformsService from "./PlatformsService";
import DashboardService from "./DashboardService";
import DataExpertService from "./DataExpertService";
import CustomerManagementService from "./CustomerManagementService";
import AnalyticDataService from "./AnalyticDataService";
import NewsService from "./NewsService";
import AlarmClockService from "./AlarmClockService";
import FileDownload from 'js-file-download';
import moment from "moment";

class ServiceFacade {
    createCompany(payload) {
        if (typeof payload !== 'object') {
            throw new Error('Payload must be object');
        }
        if (!payload.name || !payload.sellerId || !payload.email || !payload.contactName
            || !payload.phone) {
            throw new Error('Payload not fully completed');
        }
        return CompanyDataService.createCompany(payload);
    }

    fetchLastNotifications() {
        return NewsService.getLastNotifications();
    }

    profile() {
        return CompanyDataService.getProfile();
    }

    fetchDashboard(samplePeriod = 'today') {
        return DashboardService.fetchReport(samplePeriod);
    }

    fetchDataExpert() {
        return DataExpertService.fetchAll();
    }

    fetchDataExpertItems() {
        return DataExpertService.fetchItems();
    }

    startDataExpertJob() {
        return DataExpertService.startJob();
    }

    stopDataExpertJob() {
        return DataExpertService.stopJob();
    }

    reindexProducts() {
        return SettingsService.reindexProducts();
    }

    plugAnalytics() {
        return CompanyDataService.plugAnalytics();
    }

    plugPlatform(platformId) {
        return CompanyDataService.plugPlatform(platformId);
    }

    plugCRM() {
        return CustomerManagementService.plugCRM();
    }

    plugDataSource(datasource) {
        return ProductDataService.plugDataSource(datasource);
    }

    fetchPlatformIntegrations(datasource = datasources.Marketradar) {
        return CustomerManagementService.fetchPlatformIntegrations(datasource);
    }

    callPlatformIntegrationWebhook(sourceId) {
        if (!sourceId) {
            throw new Error("SourceID should not be empty");
        }
        return CustomerManagementService.callPlatformIntegrationWebhook(sourceId);
    }

    saveProductsBy(datasource, payload, forCreate = true) {
        if (!payload && typeof payload !== 'object') {
            throw new Error('Payload must be object or array');
        }
        if (!Array.isArray(payload)) {
            payload = [payload];
        }
        return forCreate
            ? ProductDataService.createProducts(datasource, payload)
            : ProductDataService.updateProducts(datasource, payload);
    }

    importProducts(operation, datasource, payload) {
        if (!Object.values(xlsImportOperationTypes).includes(operation))
            throw new Error("unknown import type");
        return ProductDataService.importProducts(operation, datasource, payload);
    }

    getDatasourceProductInfo(datasource, sku) {
        return ProductDataService.getProductInfo(bucketNamespace.DATASOURCES, datasource, sku);
    }

    getSupplierProductInfo(datasource, sku) {
        return ProductDataService.getProductInfo(bucketNamespace.SUPPLIERS, datasource, sku);
    }

    fetchDatasourceProductHistory(datasource, sku) {
        return ProductDataService.fetchHistory(bucketNamespace.DATASOURCES, datasource, sku);
    }

    fetchSupplierProductHistory(datasource, sku) {
        return ProductDataService.fetchHistory(bucketNamespace.SUPPLIERS, datasource, sku);
    }

    fetchDatasourceProductBySku(datasource, sku) {
        return ProductDataService.getProductBySku(bucketNamespace.DATASOURCES, datasource, sku);
    }

    fetchSupplierProductBySku(datasource, sku) {
        return ProductDataService.getProductBySku(bucketNamespace.SUPPLIERS, datasource, sku);
    }

    fetchBucketProducts({
        namespace,
        slot,
        page = 1,
        term = '',
        sort,
        filters,
        buildGraphCategories
    } = {}) {
        let baseURL = namespace === bucketNamespace.DATASOURCES ? '/products/get' : `/supplier/${slot}/products/get`;
        return ProductDataService.getProducts({ page, term, sort, filters, buildGraphCategories, baseURL });
    }

    autocomplete(term = "") {
        let baseURL = "/products/search";
        return ProductDataService.autocomplete({ baseURL, term });
    }

    connectProduct(datasource, payload) {
        return ProductDataService.connectProduct(datasource, payload);
    }

    disconnectProduct(datasource, payload) {
        return ProductDataService.disconnectProduct(datasource, payload);
    }

    uploadPhotos(datasource, payload) {
        return ProductDataService.uploadPhotos(datasource, payload);
    }

    deleteProduct(datasource, sku) {
        if (!sku) {
            throw new Error('SKU must not be undefined');
        }
        return ProductDataService.deleteProduct(datasource, sku);
    }

    removeProducts(datasource, payload) {
        return ProductDataService.removeProducts(datasource, payload);
    }

    updateCatalogPrice(datasource, fetchPriceKey) {
        if (!fetchPriceKey) {
            throw new Error('Price key must be defined');
        }
        return ProductDataService.updatePrice(datasource, fetchPriceKey);
    }

    updateDataSource(datasource, payload) {
        if (!Array.isArray(payload) && typeof payload !== 'object') {
            throw new Error('Payload must be object or array');
        }
        return ProductDataService.updateDataSource(datasource, payload);
    }

    dataSourceBucket(catalog) {
        if (catalog !== bucketNamespace.SUPPLIERS && catalog !== bucketNamespace.DATASOURCES) {
            throw new Error("unknown catalog type");
        }
        return ProductDataService.getDataSources(catalog);
    }

    fetchCategoriesTree() {     // default use kaspi
        return PlatformsService.fetchKaspiCategoriesTree();
    }

    fetchSellerCategoriesTree() {     // fetch categories collection by products 
        return ProductDataService.fetchSellerCategoriesTree();
    }

    costEstimate(datasource, sku, queries) {
        return ProductDataService.costEstimate(datasource, sku, queries);
    }

    fetchOrders() {
        return CustomerManagementService.fetchOrders();
    }

    changeOrderStatus(code, status, data) {
        return CustomerManagementService.changeStatus(code, status, data);
    }

    activateAlarm(type) {
        AlarmClockService.activateAlarm(type);
    }

    checkTimerCode(code) {
        return AlarmClockService.checkTimerCode(code);
    }

    createProductTag(tag) {
        return CompanyDataService.createProductTag(tag);
    }

    updateProductTag(oldName, tag) {
        return CompanyDataService.updateProductTag(oldName, tag);
    }

    updatePP(body, platform) {
        return CompanyDataService.updatePP(body, platform);
    }

    async downloadExcelReport(report) {
        let response, filename;
        if (!report)
            throw new Error("report not defined!");
        const today = moment().format("DD.MM.YYYY");
        switch (report) {
            case reportTypes.KASPI_URLS: {
                response = await AnalyticDataService.exportKaspiUrls();
                break;
            }
            case reportTypes.ALL_PRODUCTS: {
                response = await AnalyticDataService.exportAllProducts();
                break;
            }
            default: {
                throw new Error("Unknown report requested!");
            }
        }
        let filenameFromResponse = response.headers['content-disposition']?.split(';')?.[1]?.trim().replace(/"/g, "").replace('filename=', "");
        filename = filenameFromResponse ? decodeURIComponent(filenameFromResponse) : `Отчет-${today}.xlsx`;
        FileDownload(response.data, filename);
    }

    async downloadWildberriesStocks() {
        let response, filename;
        const today = moment().format("DD.MM.YYYY HH:mm");
        response = await PlatformsService.downloadWildberriesStocksExcel();
        let filenameFromResponse = response.headers['content-disposition']?.split(';')?.[1]?.trim().replace(/"/g, "").replace('filename=', "");
        filename = filenameFromResponse ? decodeURIComponent(filenameFromResponse) : `Остатки-для-Wildberries-${today}.xlsx`;
        FileDownload(response.data, filename);
    }

    async downloadWildberriesPrices() {
        let response, filename;
        const today = moment().format("DD.MM.YYYY HH:mm");
        response = await PlatformsService.downloadWildberriesPricesExcel();
        let filenameFromResponse = response.headers['content-disposition']?.split(';')?.[1]?.trim().replace(/"/g, "").replace('filename=', "");
        filename = filenameFromResponse ? decodeURIComponent(filenameFromResponse) : `Цены-для-Wildberries-${today}.xlsx`;
        FileDownload(response.data, filename);
    }
}

export default new ServiceFacade();