export default {
    data() {
        return {
            colors: {
                aqua: "#40C4FF",
                atomic: "#37474F",
                black: "#000000",
                cyan: "cyan",
                teal: "teal",
                brown: "brown",
                spanishOrange: "#EA6400",
                fauxCobalt: "#004FAC",
                inactive: "#CCCCCC",
                faded: "#DEDEDE",
                grayscale: "rgba(0,0,0,.87)",
                quincy: "#6E5B4B",
                grey: "grey",
                blueGrey: "blue-grey",
                white: "#fff",
                transparent: "#ffffffff",
                success: "#8BC34A",
                red: "red",
                navyBlue: "#4682B4",
                orange: "orange",
                green: "green",
                islamicGreen: "#21A600",
                gray81: "#CFCFCF",
                gray97: "#f7f7f7",
                suvaGrey: "#8E8E8E",
                whitesmoke: "#F5F5F5",
                fanta: "#F7B840",
                cornflowerBlue: "#A8CAEF",
                amourIce: "#FBEBEC",
                cumulus: "#FDFBB6",
                warning: "warning",
                darkSalmon: "#E9967A",
                blueLighten1: "#1E88E5",
            }
        }
    }
}