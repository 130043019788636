<!-- eslint-disable no-undef -->
<script>
import { BaseStage } from './shared';

/**
 * @typedef {"ALWAYS" | "NEW_ONLY" | "NEVER"} SyncValueType
 */

/**
 * @typedef {"ARCHIVE" | "DELETE" | "NOTHING"} ActionType
 */

export default {
  name: 'IntegrationSyncParams',
  extends: BaseStage,
  computed: {
    syncVariants() {
      return [
        { value: "ALWAYS", text: this.$t('integration.syncVariants.ALWAYS') },
        { value: "NEW_ONLY", text: this.$t('integration.syncVariants.NEW_ONLY') },
        { value: "NEVER", text: this.$t('integration.syncVariants.NEVER') }
      ]
    },
    actionVariants() {
      return [
        { value: "ARCHIVE", text: this.$t('integration.actionVariants.ARCHIVE') },
        { value: "DELETE", text: this.$t('integration.actionVariants.DELETE') },
        { value: "NOTHING", text: this.$t('integration.actionVariants.NOTHING') }
      ]
    }
  },
  methods: {
    verify() {
      return true;
    }
  }
}
</script>

<template>
  <v-form class="integration-sync-params-form d-flex flex-column">
    <v-card outlined rounded="lg" class="v-form-card">
      <v-card-title>
        {{ $t('integration.addIntegration.stepper.step.syncParams.content.syncProductSettings.card.title') }}
      </v-card-title>

      <v-card-text>
        <v-select
          :items="syncVariants"
          :label="$t('integration.addIntegration.stepper.step.syncParams.content.syncProductSettings.card.syncPrice.label')"
          v-model="builder.integrationSyncParams.syncPrice"
        />
        <v-select
          :items="syncVariants"
          :label="$t('integration.addIntegration.stepper.step.syncParams.content.syncProductSettings.card.syncOutlets.label')"
          v-model="builder.integrationSyncParams.syncOutlets"
        />
        <v-select
          :items="syncVariants"
          :label="$t('integration.addIntegration.stepper.step.syncParams.content.syncProductSettings.card.syncImages.label')"
          v-model="builder.integrationSyncParams.syncImages"
        />
        <v-select
          :items="syncVariants"
          :label="$t('integration.addIntegration.stepper.step.syncParams.content.syncProductSettings.card.syncDescription.label')"
          v-model="builder.integrationSyncParams.syncDescription"
        />
        <v-select
          :items="syncVariants"
          :label="$t('integration.addIntegration.stepper.step.syncParams.content.syncProductSettings.card.syncParams.label')"
          v-model="builder.integrationSyncParams.syncParams"
        />
      </v-card-text>
    </v-card>

    <v-card outlined rounded="lg" class="v-form-card">
      <v-card-text>
        <v-select
          :items="actionVariants"
          :label="$t('integration.addIntegration.stepper.step.syncParams.content.syncProductSettings.card.notFoundProductsAction.label')"
          v-model="builder.integrationSyncParams.notFoundProductsAction"
        />
      </v-card-text>
    </v-card>
    
    <v-card outlined rounded="lg" class="v-form-card">
      <v-card-text>
        <v-checkbox
          :label="$t('integration.addIntegration.stepper.step.syncParams.content.syncProductSettings.card.syncSourceProductsOnly.label')"
          v-model="builder.integrationSyncParams.syncSourceProductsOnly"
        />
      </v-card-text>
    </v-card>
  </v-form>
</template>

<style>
.integration-sync-params-form {
  gap: 1rem;
}
</style>
