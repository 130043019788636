import { getField, updateField } from 'vuex-map-fields';
import ServiceFacade from "@/services/ServiceFacade";
import { orderStates, notificatonStatuses, loadingStates, orderActions } from "@/enumerations";
import TimeoutGenerator from "@/helpers/TimeoutGenerator";

const state = () => ({
    locked: false,
    lastFetchDate: null,
    loadingStatus: loadingStates.BEGIN,
    orders: {
        [orderStates.NEW]: [
        ],
        [orderStates.SIGN_REQUIRED]: [
        ],
        [orderStates.KASPI_DELIVERY]: [
        ],
        [orderStates.DELIVERY]: [
        ],
        [orderStates.PICKUP]: [
        ],
        [orderStates.ARCHIVE]: [
        ]
    },
    orderDetailForm: {
        dialog: false,
        content: {}
    },
    geoCoordsViewForm: {
        dialog: false
    },
    apiOrdersForm: {
        dialog: false
    },
    animatedOrders: "",
    loadingOrders: "",
});

const actions = {
    resetSandbox({ commit }) {
        commit('REINIIALIZE', {
            locked: false,
            lastFetchDate: null,
            loadingStatus: loadingStates.BEGIN,
            orders: {
                [orderStates.NEW]: [
                ],
                [orderStates.SIGN_REQUIRED]: [
                ],
                [orderStates.DELIVERY]: [
                ],
                [orderStates.PICKUP]: [
                ],
                [orderStates.ARCHIVE]: [
                ]
            },
            animatedOrders: "",
            loadingOrders: "",
        });
    },
    async fetchOrders({ commit, state }, backgroundMode = false) {
        try {
            !backgroundMode && commit('SET_LOADING_STATUS', loadingStates.BUSY);
            const { status, data } = await ServiceFacade.fetchOrders();
            if (status == 200 && data) {
                if (backgroundMode
                    && Array.isArray(data[orderStates.NEW])
                    && state.orders[orderStates.NEW].length < data[orderStates.NEW].length) {
                    let notificationMessage = "Поступили новые обновления по заказам!";
                    commit('core/SET_NOTIFICATION', {
                        status: notificatonStatuses.success,
                        message: notificationMessage
                    }, { root: true });
                    window.dispatchEvent(new CustomEvent("playSound"));
                }
                data[orderStates.KASPI_DELIVERY] = data[orderStates.DELIVERY] ?
                    data[orderStates.DELIVERY].filter(order => order.state === orderStates.KASPI_DELIVERY)
                    : [];
                data[orderStates.DELIVERY] = data[orderStates.DELIVERY] ?
                    data[orderStates.DELIVERY].filter(order => order.state === orderStates.DELIVERY)
                    : [];

                commit('LOAD_ORDERS', data);
                !backgroundMode && commit('SET_LOADING_STATUS', loadingStates.SUCCESS);
            }
        } catch (error) {
            !backgroundMode && commit('SET_LOADING_STATUS', loadingStates.ERROR);
            if (error.response && (error.response.status === 403 && (error.response.data.reason === "API_KEY_MISSING" || error.response.data.reason === "NO_SUBSCRIPTION"))) {
                commit("LOCK_ORDERS", true); return;
            }
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: error.message || "Error"
            }, { root: true })
        }
    },
    async changeOrder({ commit }, { order, action, reason, comment }) {
        try {
            commit('SET_ORDER_LOADING', {
                orderCode: order.code,
                loading: true,
            })
            const { status, data } = await ServiceFacade.changeOrderStatus(order.code, action, { reason, comment });
            let message = "";
            switch (action) {
                case orderActions.ACCEPT: message = `Заказ ${order.code} успешно принят`; break;
                case orderActions.CANCEL: message = `Заказ ${order.code} успешно отменен`; break;
            }
            if (status == 200 && data) {
                commit('core/SET_NOTIFICATION', {
                    status: notificatonStatuses.success,
                    message
                }, { root: true });
                commit('SET_ORDER_LOADING', {
                    orderCode: order.code,
                    loading: false,
                })
                commit('UPDATE_ORDER', data)
                commit('ANIMATE_ORDER', data)
            }
        } catch (error) {
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: error.message || "Error"
            }, { root: true });
            commit('SET_ORDER_LOADING', {
                orderCode: order.code,
                loading: false,
            })
        }
    },
    /*          Order detail form     */
    loadOrderDetailForm({ commit }, order) {
        commit('SET_ORDER_DETAIL_FORM', order);
        commit('TOGGLE_ORDER_DETAIL_FORM', true);
    },
    closeOrderDetailForm({ commit }) {
        commit('SET_ORDER_DETAIL_FORM', {});
        commit('TOGGLE_ORDER_DETAIL_FORM', false);
    },
    /*          GEO coords view form     */
    openGeoCoordsViewForm({ commit }) {
        commit('DISPLAY_GEO_COORDS_VIEW_FORM');
    },
    openOrdersAPIForm({ commit }) {
        commit('DISPLAY_ORDERS_API_VIEW_FORM');
    }
}

const mutations = {
    updateField,
    REINIIALIZE(state, {
        locked,
        lastFetchDate,
        loadingStatus,
        orders
    }) {
        state.locked = locked;
        state.orders = { ...orders };
        state.loadingStatus = loadingStatus;
        state.lastFetchDate = lastFetchDate
    },
    LOAD_ORDERS(state, orders) {
        state.orders = { ...orders };
        state.lastFetchDate = new Date();
    },
    LOCK_ORDERS(state, flag) {
        state.locked = flag;
    },
    TOGGLE_ORDER_DETAIL_FORM(state, dialog) {
        state.orderDetailForm.dialog = dialog;
    },
    SET_ORDER_DETAIL_FORM(state, content) {
        state.orderDetailForm.content = { ...content };
    },
    DISPLAY_GEO_COORDS_VIEW_FORM(state) {
        state.geoCoordsViewForm.dialog = true;
    },
    DISPLAY_ORDERS_API_VIEW_FORM(state) {
        state.apiOrdersForm.dialog = true;
    },
    SET_LOADING_STATUS(state, status) {
        state.loadingStatus = status;
    },
    SET_ORDER_LOADING(state, { orderCode, loading }) {
        if (loading) {
            state.loadingOrders = `${state.loadingOrders}{${orderCode}}`
        } else {
            state.loadingOrders = state.loadingOrders.replace(`{${orderCode}}`, "");
        }
    },
    UPDATE_ORDER(state, { order }) {
        for (let key of Object.keys(state.orders)) {
            let orderIndex = state.orders[key].findIndex(o => o.code === order.code);
            if (state.orders[key][orderIndex]) {
                state.orders[key].splice(orderIndex, 1);
            }
        }
        state.orders[order.state].unshift(order);
        if (state.orderDetailForm.content?.id === order.id) {
            state.orderDetailForm.content = order;
        }
    },
    async ANIMATE_ORDER(state, { order }) {
        state.animatedOrders = `${state.animatedOrders}{${order.id}}`
        await TimeoutGenerator.timeWait(1500);
        state.animatedOrders = state.animatedOrders.replace(`{${order.id}}`, "");
    },
}

const getters = {
    getField,
    loadingStatus: (state) => state.loadingStatus,
    locked: (state) => state.locked,
    orders: (state) => state.orders,
    orderDetailForm: (state) => state.orderDetailForm,
    geoCoordsViewForm: (state) => state.geoCoordsViewForm,
    apiOrdersForm: (state) => state.apiOrdersForm,
    freshOrdersCount: (state) => {
        return state.orders[orderStates.NEW].length;
    },
    amountByOrderState: (state) => (orderState) => {
        return state.orders[orderState]?.reduce((acc, o) => o.totalPrice + acc, 0) || 0
    },
    hasGeoCoords: (state) => (orderState, orderId) => {
        return state.orders[orderState]?.some(o => o.id === orderId && o.coordinates) || false;
    },
    geoCoordsDeliveryAddresses: (state) => (orderStates = []) => {
        let geoData = [];
        for (const it of orderStates) {
            let shear = state.orders[it]
                .filter(o => o.deliveryAddress
                    && o.deliveryAddress.formattedAddress
                    && o.coordinates
                    && o.coordinates.split(' ').length == 2
                )
                .map(({ deliveryAddress, coordinates, code, state, products, totalPrice }) => {
                    return {
                        address: deliveryAddress.formattedAddress,
                        coords: coordinates.split(' ').reverse(),
                        code,
                        state,
                        products,
                        totalPrice
                    }
                });
            geoData = [
                ...geoData,
                ...shear
            ];
        }
        return geoData;
    },
    pipeline: (state) => {
        return {
            leadsCount: Object.keys(state.orders)?.reduce(
                (acc, orderState) => state.orders[orderState].length + acc,
                0
            ) || 0,
            totalAmount: Object.keys(state.orders)
                .map(orderState => state.orders[orderState]?.reduce(
                    (acc, order) => order.totalPrice + acc, 0)
                )
                .reduce(
                    (acc, sum) => sum + acc,
                    0
                ) || 0,
        }
    },
    animatedOrders: (state) => state.animatedOrders,
    loadingOrders: (state) => state.loadingOrders,
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}