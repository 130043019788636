import {
    bucketNamespace,
    datasources,
    salesTriggers,
    confirmationTypes
} from "@/enumerations";

export default {
    methods: {
        onCreateRecentlyProducts(canCreateDeferredJobs = false, products) {
            this.$store.dispatch('eventBus/createRecentlyCreatedProducts', {
                products,
                canCreateDeferredJobs
            });
        },
        onShiftAndUpdateProducts(targetBucketSpace, products) {
            if (targetBucketSpace === bucketNamespace.SUPPLIERS) {
                if (this.$store.getters["datasources/identify"](datasources["Marketradar"])) {
                    let canCreateDeferredJobs = true;
                    this.onCreateRecentlyProducts.call(this, canCreateDeferredJobs, products);
                }
            }
            if (targetBucketSpace === bucketNamespace.DATASOURCES) {
                this.$store.dispatch('eventBus/addLatestProductUpdates', products);
            }
        },
        onRemoveProducts(products) {
            this.$store.dispatch('eventBus/createRecentlyDeletedProducts', products);
        },
        onRejectProducts(products) {
            this.$store.dispatch('eventBus/createRecentlyDiscontinuedProducts', products);
        },
        onSyncInventory(products) {
            if (Array.isArray(products) && products[0]) {
                this.$store.dispatch('productUIForm/mountProductModel', products.shift());
            }
        },
        async onProductAction(confirmationAction, bucketSpace, payload = null, withNotify = true) {
            if (confirmationAction === confirmationTypes.CREATE_PRODUCT) {
                let canCreateDeferredJobs = false;
                this.$store.dispatch(`datasources/createProduct`, {
                    actionHookFn: this.onCreateRecentlyProducts.bind(this, canCreateDeferredJobs),
                    productType: 'uno'
                });
            }
            else if (confirmationAction === confirmationTypes.CRAFT_COMPOSITE_PRODUCT) {
                let canCreateDeferredJobs = false;
                this.$store.dispatch(`datasources/createProduct`, {
                    actionHookFn: this.onCreateRecentlyProducts.bind(this, canCreateDeferredJobs),
                    productType: 'set'
                });
            }
            else if (confirmationAction === confirmationTypes.CRAFT_DUPLICATE_PRODUCT) {
                let canCreateDeferredJobs = false;
                this.$store.dispatch(`datasources/createProduct`, {
                    actionHookFn: this.onCreateRecentlyProducts.bind(this, canCreateDeferredJobs),
                    productType: 'child'
                });
            }
            else if (confirmationAction === confirmationTypes.SAVE_PRODUCT) {
                this.$store.dispatch(`datasources/saveProduct`,
                    this.onShiftAndUpdateProducts.bind(this, bucketNamespace.DATASOURCES));
            }
            else if (confirmationAction === confirmationTypes.INVENTORY_MODIFIED) {
                await this.$store.dispatch(`datasources/partialUpdateProduct`, {
                    partialProduct: payload,
                    updateHookFn: this.onSyncInventory.bind(this),
                    withNotify
                })
            }
            else if (confirmationAction === confirmationTypes.PARTIAL_UPDATE_PRODUCT) {
                await this.$store.dispatch(`datasources/partialUpdateProduct`, {
                    partialProduct: payload,
                    updateHookFn: this.onShiftAndUpdateProducts.bind(this, bucketNamespace.DATASOURCES),
                    withNotify
                })
            }
            else if (confirmationAction === confirmationTypes.DELETE_PRODUCT) {
                this.$store.dispatch(`datasources/deleteProduct`,
                    this.onRemoveProducts.bind(this));
            }
            else if (
                confirmationAction === confirmationTypes.REMOVE_PRODUCTS
            ) {
                this.$store.dispatch(`datasources/removeProductSet`,
                    this.onRemoveProducts.bind(this));
            }
            else if (
                confirmationAction === confirmationTypes.SELECT_DELIVERY_POINTS ||
                confirmationAction === confirmationTypes.TRADING_BOTS ||
                confirmationAction === confirmationTypes.PRICE_MARKUP ||
                confirmationAction === confirmationTypes.PRICE_MARGIN ||
                confirmationAction === confirmationTypes.PRICE_DISCOUNT ||
                confirmationAction === confirmationTypes.NEST_CATEGORY_IN_PRODUCTS ||
                confirmationAction === confirmationTypes.NEST_TAGS_IN_PRODUCTS ||
                confirmationAction === confirmationTypes.NEST_PLATFORMS_IN_PRODUCTS
            ) {
                this.$store.dispatch(
                    `datasources/updateProductSet`,
                    {
                        action: confirmationAction,
                        updateHookFn: this.onShiftAndUpdateProducts.bind(this, bucketSpace)
                    }
                );
            }
            else if (
                confirmationAction === confirmationTypes.CHARGE_PRODUCTS ||
                confirmationAction === confirmationTypes.ADD_PRODUCT
            ) {
                this.$store.dispatch(
                    `${bucketSpace}/switchSaleConnection`,
                    {
                        triggerType: salesTriggers.SALE_ON,
                        multipleChoice: confirmationAction === confirmationTypes.CHARGE_PRODUCTS
                            ? true
                            : false,
                        afterActionHookFn: this.onShiftAndUpdateProducts.bind(this, bucketSpace)
                    }
                );
            }
            else if (
                confirmationAction === confirmationTypes.REJECT_PRODUCTS ||
                confirmationAction === confirmationTypes.DEACTIVATE_PRODUCT
            ) {
                this.$store.dispatch(
                    `${bucketSpace}/switchSaleConnection`,
                    {
                        triggerType: salesTriggers.SALE_OFF,
                        multipleChoice: confirmationAction === confirmationTypes.REJECT_PRODUCTS
                            ? true
                            : false,
                        afterActionHookFn: this.onRejectProducts.bind(this)
                    }
                );
            }
        }
    }
}