<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      offset-y
      nudge-width="690"
    >
      <template v-if="!isMobile" v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon :color="colors.black" size="25"> mdi-keyboard-outline </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar width="64" height="64">
              <v-img
                src="@/assets/img/keyboard-shortcuts/shortcuts.png"
                contain
                alt="Shortcut"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                >Горячие клавиши Маркетрадара</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list class="px-1">
          <template v-for="item in productHotkeysGuideBox">
            <v-subheader
              v-if="item.header"
              :key="item.header"
            >{{ item.header }}</v-subheader>
            <v-list-item v-else :key="item.print">
              <v-list-item-content>
                <v-row align="center">
                  <v-img
                    v-if="item.code"
                    :src="shortcutPrintGlyph(item.code)"
                    max-height="40"
                    max-width="40"
                    contain
                    :alt="item.code"
                  ></v-img>
                  <template v-else>
                    <template v-for="(shortcut, j) in item.combinations">
                      <span
                        class="ml-4 headline font-weight-black"
                        :key="j"
                        v-if="j > 0"
                        >+</span
                      >
                      <v-img
                        :key="shortcut"
                        :src="shortcutPrintGlyph(shortcut)"
                        :class="j > 0 && 'ml-4'"
                        max-height="40"
                        max-width="40"
                        contain
                        :alt="shortcut"
                      ></v-img>
                    </template>
                  </template>
                  <span class="ml-12 subtitle-1" v-text="item.tip"></span>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { hotKeys } from "@/enumerations";
import ColorPalette from "@/mixins/ColorPalette";
import ScreenResolutionMixin from "@/mixins/ScreenResolution";

export default {
  mixins: [ColorPalette, ScreenResolutionMixin],
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    productHotkeysGuideBox() {
      return [
        { header: "Работа с товарами", icon: "mdi-storefront-outline" },
        { ...hotKeys.CREATE_PRODUCT },
        { ...hotKeys.FORWARD_TO_SUPPLIERS },
        { ...hotKeys.FOCUS_TO_SEARCHBOX },
      ];
    },
  },
  methods: {
    shortcutPrintGlyph(key) {
      return require(`@/assets/img/keyboard-shortcuts/${key}.png`);
    },
  },
};
</script>

<style>
</style>