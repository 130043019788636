<script>
import { BaseStage } from '../shared'
import OneCParamsForm from './OneCParams.form.vue';
import MoyskladParamsForm from './MoyskladParams.form.vue';
import ProSkladParamsForm from './MoyskladParams.form.vue';


export default {
  name: 'DataParamsSetter',
  extends: BaseStage,
  components: {
    OneCParamsForm,
    MoyskladParamsForm,
    ProSkladParamsForm
  },
  computed: {
    form() {
      switch(this.builder.selectedType.name) {
        case '1C': return OneCParamsForm;
        case 'Moysklad': return MoyskladParamsForm;
        case 'ProSklad': return ProSkladParamsForm;
        default: return OneCParamsForm;
      }
    }
  },
  methods: {
    verify() {
      // Первый ребенок должен быть формой.
      return this.$refs.dataParamsForm.$children[0].validate()
    }
  }
}
</script>

<template>
  <component ref="dataParamsForm" :is="form" />
</template>
