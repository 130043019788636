import './types';
import CustomerManagementService from '../../../../services/CustomerManagementService';
import store from '../../../../store';

/**
 * 
 * @param {DeleteIntegrationFeatureDTO} inputDTO 
 */
export async function deleteIntegrationFeature(inputDTO) {
  try {
    const dto = {
      id: inputDTO.id,
      existingProductsAction: inputDTO.existingProductsAction || 'NOTHING',
    }
    await CustomerManagementService.deleteIntegration(dto);

    await store.dispatch("serviceModals/fetchPlatformIntegrations");
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.description);
    } else throw error;
  }
}
