<script>
export default {
  props: {
    prop: String,
    value: [String, Number],
    color: String,
  }
}
</script>

<template>
  <v-row class="void mx-0 mb-5 field-section d-flex flex-nowrap justify-space-between align-start">

    <v-list-item-title class="text-wrap field-section_prop">
      {{ prop }}
    </v-list-item-title>

    <v-list-item-title
      :class="['text-right field-section_value', color || '']"
      style="white-space: normal"
    >
      <slot>{{ value }}</slot>
    </v-list-item-title>

   </v-row>
</template>

<style lang="scss">
@import 'vuetify/src/styles/styles.sass';

.field-section {
  
  .field-section_prop, .field-section_value {
    flex-grow: 1;
    flex-shrink: 1;
    align-self: flex-start;
  }
}
</style>
