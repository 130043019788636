export default {
    data() {
        return {
            aspectRatioList: [
                "1024×576",
                "1024×768",
                "1152×648",
                "1280×720",
                "1280×800",
                "1280×960",
                "1366×768",
                "1440×900",
                "1400×1050",
                "1440×1080",
                "1600×900",
                "1600×1200",
                "1680×1050",
                "1856×1392",
                "1920×1080",
                "1920×1200",
                "1920×1440",
                "2048×1536",
                "2560×1440",
                "2560×1600",
                "3840×2160"
            ],
            windowSize: {
                width: window.innerWidth,
                height: window.innerHeight
            }
        }
    },
    computed: {
        resolution() {
            return {
                width: screen.width,
                height: screen.height
            }
        },
        isSmallScreen() {
            return this.resolution.width <= 1024;
        },
        isMobile() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        }
    },
    methods: {
        resizeWindow() {
            this.windowSize = {
                width: window.innerWidth,
                height: window.innerHeight
            }
        }
    },
    mounted() {
        window.addEventListener('resize', this.resizeWindow);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeWindow);
    }
}