<template>
  <v-dialog
    v-model="serviceContentViewModal.dialog"
    persistent
    max="800"
    overlay-opacity="0.16"
  >
    <v-card class="overflow-hidden">
      <v-app-bar
        absolute
        :color="colors.fauxCobalt"
        dark
        elevate-on-scroll
        scroll-target="#scrolling-content"
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon light @click="close">
          <v-icon :color="colors.white">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-sheet id="scrolling-content" class="overflow-y-auto py-16 px-8" height="600">
          <template v-if="connectPermission">
            <v-row class="void d-flex align-center mb-5">
              <v-img
                v-if="modalImage?.length"
                :src="modalImage"
                contain
                height="300"
              />
            </v-row>
            <v-card-text class="text-h6" v-if="modalDescription.length">
               {{ modalDescription }}
            </v-card-text>
            <iframe
              ref="frame"
              v-show="contentloaded"
              :src="url"
              @load="load"
              frameborder="0"
              allowfullscreen
            ></iframe>
            <v-info-board
              class="pa-2"
              v-if="url && !contentloaded"
              message="Загрузка сообщения..."
              displayIcon="mdi-video-input-antenna"
            ></v-info-board>
          </template>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import ColorPalette from "@/mixins/ColorPalette";
import VInfoBoard from "@/components/info-board/VInfoBoard";

export default {
  props: {
    title: String,
    url: String,
    modalDescription: String,
    modalImage: String,
  },
  mixins: [ColorPalette],
  components: {
    VInfoBoard,
  },
  computed: {
    ...mapFields("serviceModals", ["serviceContentViewModal"]),
    connectPermission() {
      return this.serviceContentViewModal.dialog == true;
    },
  },
  data() {
    return {
      contentloaded: false,
    };
  },
  methods: {
    load: function () {
      this.contentloaded = true;
      setTimeout(() => {
          localStorage.setItem("last-modal-check-time", new Date().getTime());
      }, 5000);
    },
    close() {
      this.serviceContentViewModal.dialog = false;
    },
  },
};
</script>