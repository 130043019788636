import './types';
import OpenServerApi from "@/interfaces/OpenServerApi";
import {
    VERIFY_INTEGRATION_PATH,
    ADD_INTEGRATION_PATH,
    DELETE_INTEGRATION_PATH,
    EDIT_INTEGRATION_PATH,
    ADD_ORDERS_OPTIONS_VERIFY_PATH,
    ADD_ORDERS_OPTIONS_PATH,
} from './constants';

class CustomerManagementService {
    fetchOrders() {
        return OpenServerApi().get('/orders');
    }
    changeStatus(code, status, data) {
        return OpenServerApi().post('/orders/change', {
            code,
            status,
            data
        });
    }
    fetchPlatformIntegrations(datasourceId) {
        return OpenServerApi().get(`/datasources/${datasourceId}/sources/all`);
    }
    callPlatformIntegrationWebhook(sourceId) {
        return OpenServerApi().get(`/datasources/marketradar/sources/${sourceId}/fetch`);
    }

    /**
     * Добавить заказы
     * @param {AddOrderOptionsDTO} inputDTO 
     * @returns {Promise<Response>}
     */
    addOrderOptions(inputDTO) {
        return OpenServerApi().post(ADD_ORDERS_OPTIONS_PATH, inputDTO);
    }
    /**
     * Верификация данных добавления заказов
     * @param {AddOrderOptionsDTO} inputDTO 
     * @returns {Promise<Response>}
     */
    addOrderOptionsVerify(inputDTO) {
        return OpenServerApi().post(ADD_ORDERS_OPTIONS_VERIFY_PATH, inputDTO);
    }

    /**
     * Проверка добавляемой интеграции
     * @param {VerifyIntegrationDTO} inputDTO 
     */
    verifyIntegration(inputDTO) {
        return OpenServerApi().post(VERIFY_INTEGRATION_PATH, inputDTO);
    }

    /**
     * Добавляет новую интеграцию
     * @param {AddIntegrationDTO} inputDTO 
     */
    addIntegration(inputDTO) {
        return OpenServerApi().post(ADD_INTEGRATION_PATH, inputDTO);
    }

    /**
     * Удаляет интеграцию
     * @param {DeleteIntegrationDTO} inputDTO 
     * @returns {Promise<void>}
     */
    deleteIntegration(inputDTO) {
        return OpenServerApi().post(DELETE_INTEGRATION_PATH, inputDTO);
    }

    /**
     * Изменяет данные интеграции
     * @param {EditIntegrationDTO} inputDTO 
     * @returns {Promise<void>}
     */
    editIntegration(inputDTO) {
        return OpenServerApi().post(EDIT_INTEGRATION_PATH, inputDTO);
    }
}

export default new CustomerManagementService();
