import moment from "moment-timezone";

class TextFormatter {
    ruDateFormat(dateString) {
        let ru = moment(dateString).locale('ru');
        return ru.format('DD MMMM YYYY');
    }
    timeFormat(dateString) {
        return moment(dateString).format('HH:mm');
    }
    dateFormat(dateString) {
        return moment(dateString).tz("Asia/Almaty").format("DD.MM.YYYY HH:mm");
    }
    dateTextFormat(dateString) {
        return moment(dateString).locale('ru').tz("Asia/Almaty").format("DD MMMM, HH:mm");
    }
    dateTextFormatWeekday(dateString) {
        return moment(dateString).locale('ru').tz("Asia/Almaty").format("dd, DD MMMM, HH:mm");
    }
    dateTextFormatCompact(dateString) {
        return moment(dateString).locale('ru').tz("Asia/Almaty").format("DD MMM, HH:mm");
    }
    dateTextFormatDayMonth(dateString) {
        return moment(dateString).locale('ru').tz("Asia/Almaty").format("D MMM");
    }
    moneyFormat(money) {
        let number = typeof money === "string" ? parseFloat(money) : money;
        return new Intl.NumberFormat("ru-RU").format(number);
    }
    formattedNumber(number) {
        let n = typeof number === "string" ? parseInt(number) : number;
        return new Intl.NumberFormat("ru-RU").format(n);
    }
    shortcut(text, maxlen) {
        if (text.length > maxlen) {
            const substring = text.substr(0, maxlen);
            let finite =
                substring.lastIndexOf(".") >= 0
                    ? substring.lastIndexOf(".")
                    : substring.lastIndexOf(" ");
            return substring.substr(0, finite)
        }
        return text;
    }
}

export default new TextFormatter();