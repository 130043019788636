import OpenServerApi from "@/interfaces/OpenServerApi";
import { bucketNamespace, xlsImportOperationTypes } from "../enumerations";

class ProductDataService {
    plugDataSource(datasource) {
        return OpenServerApi().post(`/suppliers/${datasource}/apply`, null);
    }
    getDataSources(catalog) {
        if (catalog === bucketNamespace.DATASOURCES) {
            return OpenServerApi().get('/datasources');
        }
        else if (catalog === bucketNamespace.SUPPLIERS) {
            return OpenServerApi().get('/suppliers');
        }
    }
    updatePrice(datasource, fetchPriceKey) {
        return OpenServerApi().get(`/fetch/datasource/${datasource}/${fetchPriceKey}`);
    }
    updateDataSource(datasource, payload) {
        return OpenServerApi().post(`/datasources/${datasource}/update`, payload);
    }
    createProducts(datasource, payload) {
        return OpenServerApi().post(`/datasources/${datasource}/products/create`, payload);
    }
    updateProducts(datasource, payload) {
        return OpenServerApi().post(`/datasources/${datasource}/products/update`, payload);
    }
    autocomplete({baseURL, term = ''}) {
        let url = `${baseURL}?term=${encodeURIComponent(term)}`;
        return OpenServerApi().get(url);
    }
    getProducts({ page = 1, term = '', sort, buildGraphCategories = false, filters = [], baseURL }) {
        let url = `${baseURL}?page=${page}`;

        term?.length > 0 && (url += `&term=${encodeURIComponent(term)}`)
        sort && (url += `&sort=${sort}`)
        buildGraphCategories && (url += `&buildGraphCategories=${buildGraphCategories}`)

        for (const { prop, data } of filters) {
            if (Array.isArray(data)) {
                for (const it of data) {
                    url += `&filter[${prop}]=${encodeURIComponent(it)}`
                }
            }
            else if (data !== undefined) {
                url += `&filter[${prop}]=${encodeURIComponent(data)}`
            }
        }
        
        return OpenServerApi().get(url);
    }
    removeProducts(datasource, payload) {
        return OpenServerApi().post(`/datasources/${datasource}/products/delete`, payload);
    }
    importProducts(operation, datasource, payload) {
        switch (operation) {
            case xlsImportOperationTypes.DEFAULT:
                return OpenServerApi().post(`/datasources/${datasource}/products/import/kaspiexcel`, payload);

            case xlsImportOperationTypes.BATCH:
                return OpenServerApi().post(`/datasources/${datasource}/products/import/batch`, payload);

            default:
                return undefined;
        }
    }
    uploadPhotos(datasource, formData) {
        return OpenServerApi().post(
            `/datasources/${datasource}/products/upload/images`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    }
    deleteProduct(datasource, sku) {
        return OpenServerApi().post(`/datasources/${datasource}/products/delete`, [{ sku }]);
    }
    connectProduct(datasource, payload) {
        return OpenServerApi().post(`/suppliers/${datasource}/products/connect`, payload);
    }
    disconnectProduct(datasource, payload) {
        return OpenServerApi().post(`/suppliers/${datasource}/products/disconnect`, payload);
    }
    getProductBySku(catalog, datasource, sku) {
        return OpenServerApi().get(`/${catalog}/${datasource}/products/sku/${encodeURIComponent(sku)}`);
    }
    getProductInfo(catalog, datasource, sku) {
        return OpenServerApi().get(`/${catalog}/${datasource}/products/info/${encodeURIComponent(sku)}`);
    }
    costEstimate(datasource, sku, { dealerPrice, margin, price, minPrice, minProfitPercent, marginPercent, discountPercent, kaspiFeePercent, prices, kaspiTrading }) {
        const options = {
            params: {
                ...(dealerPrice && { dealerPrice }),
                ...(price && { price }),
                ...(minPrice && { minPrice }),
                ...(minProfitPercent && { minProfitPercent }),
                ...(margin >= 0 && { margin }),
                ...(marginPercent >= 0 && { marginPercent }),
                ...(discountPercent > 0 && { discountPercent }),
                ...(kaspiFeePercent >= 0 && { kaspiFeePercent }),
                ...(prices?.length && { prices }),
                ...(kaspiTrading !== undefined && { kaspiTrading }),
            }
        };
        return OpenServerApi().get(`/datasources/${datasource}/products/sku/${encodeURIComponent(sku)}/prices/estimated`, options);
    }
    fetchHistory(catalog, datasource, sku) {
        return OpenServerApi().get(`/${catalog}/${datasource}/products/history/${encodeURIComponent(sku)}`);
    }
    fetchSellerCategoriesTree() {
        return OpenServerApi().get('/products/categories/get');
    }
}

export default new ProductDataService();