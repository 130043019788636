<template>
  <v-card
    v-if="display"
    class="action__sheet pt-5 px-7 pb-3"
    :width="width"
    height="auto"
  >
    <v-row class="void">
      <v-flex xs6>
        <span class="sheet_title">Выбрано {{ selectedCounter }}</span>
        <v-btn
          v-if="!isMobile"
          class="ml-5"
          icon
          width="40"
          height="40"
          @click="selectAllProducts"
        >
          <v-icon>mdi-check-all</v-icon>
        </v-btn>
      </v-flex>
      <v-flex xs6 class="d-flex justify-end">
        <v-btn
          icon
          class="ml-4"
          width="40"
          height="40"
          @click="pullOffProducts"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-flex>
    </v-row>
    <v-row class="mx-0 px-0">
      <v-flex xs12 class="pt-3">
        <custom-button
          :v-if="tradingAvailable"
          :label="tradingCommandBtn.label"
          :icon="tradingCommandBtn.icon"
          :color="tradingCommandBtn.color"
          :handler="tradingCommandBtn.handler"
          :height="48"
          :dense="true"
          :disabled="tradingFuncDisabled"
        ></custom-button>
        <custom-button
          :label="deliveryCommandBtn.label"
          :icon="deliveryCommandBtn.icon"
          :color="deliveryCommandBtn.color"
          :handler="deliveryCommandBtn.handler"
          :height="48"
          :dense="true"
        ></custom-button>
        <custom-button
          :class="[!isMobile && 'px-8']"
          :label="priceMarginCommandBtn.label"
          :icon="priceMarginCommandBtn.icon"
          :color="priceMarginCommandBtn.color"
          :handler="priceMarginCommandBtn.handler"
          :height="48"
          :dense="true"
        ></custom-button>
        <custom-button
          :class="[!isMobile && 'px-8']"
          :label="priceMarkupCommandBtn.label"
          :icon="priceMarkupCommandBtn.icon"
          :color="priceMarkupCommandBtn.color"
          :handler="priceMarkupCommandBtn.handler"
          :height="48"
          :dense="true"
        ></custom-button>
        <custom-button
          :class="[!isMobile && 'px-8']"
          :label="priceDiscountCommandBtn.label"
          :icon="priceDiscountCommandBtn.icon"
          :color="priceDiscountCommandBtn.color"
          :handler="priceDiscountCommandBtn.handler"
          :height="48"
          :dense="true"
        ></custom-button>
        <custom-button
          v-if="!isMobile"
          class="px-6"
          :label="categoryGraphCommandBtn.label"
          :icon="categoryGraphCommandBtn.icon"
          :color="categoryGraphCommandBtn.color"
          :handler="categoryGraphCommandBtn.handler"
          :height="48"
          :dense="true"
        ></custom-button>
        <custom-button
          v-if="!isMobile"
          class="px-6"
          :label="marketplaceSelectionBtn.label"
          :icon="marketplaceSelectionBtn.icon"
          :color="marketplaceSelectionBtn.color"
          :handler="marketplaceSelectionBtn.handler"
          :height="48"
          :dense="true"
        ></custom-button>
        <custom-button
          v-if="!isMobile"
          class="px-6"
          :label="suiteCreationCommandBtn.label"
          :icon="suiteCreationCommandBtn.icon"
          :color="suiteCreationCommandBtn.color"
          :handler="suiteCreationCommandBtn.handler"
          :height="48"
          :dense="true"
          :disabled="suiteCreationFuncDisabled"
        ></custom-button>
        <custom-button
            v-if="!isMobile"
            class="px-6"
            :label="suiteDuplicateCommandBtn.label"
            :icon="suiteDuplicateCommandBtn.icon"
            :color="suiteDuplicateCommandBtn.color"
            :handler="suiteDuplicateCommandBtn.handler"
            :height="48"
            :dense="true"
            :disabled="suiteDuplicateFuncDisabled"
        ></custom-button>
        <tag-popover-menu
          class="px-6"
          slotActivator="button"
          :activeTags="[]"
          :recommendedTags="company.productTags || []"
          :createProductTagFunc="createProductTagFunc"
          :updateProductTagFunc="updateProductTagFunc"
          :display="true"
          :multiselect="true"
          :withConfirmation="true"
          @connectMultiTags="onApplyTagsConfirm"
        ></tag-popover-menu>
        <custom-button
          v-if="!isMobile"
          class="px-6"
          :label="removeCommandBtn.label"
          :icon="removeCommandBtn.icon"
          :color="removeCommandBtn.color"
          :handler="removeCommandBtn.handler"
          :height="48"
          :dense="true"
        ></custom-button>
      </v-flex>
      <v-flex xs12 class="pt-3" v-if="isMobile">
        <v-row class="void" justify="start">
          <template>
            <v-btn icon @click="selectAllProducts">
              <v-icon size="36">mdi-check-all</v-icon>
            </v-btn>
            <v-btn icon class="ml-12" @click="pullOffProducts">
              <v-icon size="32">mdi-cancel</v-icon>
            </v-btn>
            <custom-button
              class="ml-12"
              :label="removeCommandBtn.label"
              :icon="removeCommandBtn.icon"
              :color="removeCommandBtn.color"
              :handler="removeCommandBtn.handler"
              :height="!isMobile ? 48 : 40"
            ></custom-button>
          </template>
        </v-row>
      </v-flex>
    </v-row>
  </v-card>
</template>

<script>
import "./action_sheet.css";
import { mapGetters } from "vuex";
import { confirmationTypes } from "@/enumerations";
import ColorPaletteMixin from "@/mixins/ColorPalette";
import ScreenResolutionMixin from "@/mixins/ScreenResolution";
import ProductActionsMixin from "@/mixins/ProductActionsMixin";
import CustomButton from "@/components/buttons/CustomButton";
import TagPopoverMenu from "@/components/dropdown-menu/TagPopoverMenu";

export default {
  name: "ProductActionSheet",
  components: {
    CustomButton,
    TagPopoverMenu,
  },
  mixins: [ColorPaletteMixin, ScreenResolutionMixin, ProductActionsMixin],
  data() {
    return {
      unsubscribe: null,
      width: null,
      createProductTagFunc: function (model) {
        return this.$store.dispatch("profile/createProductTag", model);
      },
      updateProductTagFunc: function (data) {
        return this.$store.dispatch("profile/updateProductTag", data);
      },
    };
  },
  computed: {
    ...mapGetters("core", {
      navBar: "navBar",
    }),
    ...mapGetters("profile", {
      company: "company",
    }),
    ...mapGetters("datasources", {
      productActionSheet: "productActionSheet",
      hasProductSuiteComplete: "hasProductSuiteComplete",
      hasProductDuplicateComplete: "hasProductDuplicateComplete",
      hasProductForTrading: "hasProductForTrading",
    }),
    tradingCommandBtn() {
      return {
        label: "Торговый бот",
        icon: "mdi-robot-excited",
        color: this.colors.fauxCobalt,
        handler: this.onEnableBots.bind(this),
      };
    },
    deliveryCommandBtn() {
      return {
        label: "Точки вывоза",
        icon: "mdi-package-variant-closed",
        color: this.colors.fauxCobalt,
        handler: this.onSetDeliveryPoints.bind(this),
      };
    },
    priceMarginCommandBtn() {
      return {
        label: "Наценка",
        icon: "mdi-percent",
        color: this.colors.fauxCobalt,
        handler: this.onSetPriceMargin.bind(this),
      };
    },
    priceMarkupCommandBtn() {
      return {
        label: "Накрутка",
        icon: "mdi-lightning-bolt-outline",
        color: this.colors.fauxCobalt,
        handler: this.onSetPriceMarkup.bind(this),
      };
    },
    priceDiscountCommandBtn() {
      return {
        label: "Скидка",
        icon: "mdi-sale-outline",
        color: this.colors.fauxCobalt,
        handler: this.onSetPriceDiscount.bind(this),
      };
    },
    removeCommandBtn() {
      return {
        label: "Удалить",
        icon: "mdi-cart-off",
        color: this.colors.red,
        handler: this.onRemoveConfirm.bind(this),
      };
    },
    categoryGraphCommandBtn() {
      return {
        label: "Категория",
        icon: "mdi-folder-outline",
        color: this.colors.fauxCobalt,
        handler: this.onGraphCategoriesView.bind(this),
      };
    },
    marketplaceSelectionBtn() {
      return {
        label: "Маркетплейсы",
        icon: "mdi-shopping",
        color: this.colors.fauxCobalt,
        handler: this.onDisplayPlatfromSelection.bind(this),
      };
    },
    suiteCreationCommandBtn() {
      return {
        label: "Создать комплект",
        icon: "mdi-shape",
        color: this.colors.fauxCobalt,
        handler: this.onDisplayCompositeWizard.bind(this),
      };
    },
    suiteDuplicateCommandBtn() {
      return {
        label: "Создать субтовары",
        icon: "mdi-content-duplicate",
        color: this.colors.fauxCobalt,
        handler: this.onDisplayDuplicateWizard.bind(this),
      };
    },
    display() {
      return this.productActionSheet.selected.length > 0;
    },
    selectedCounter() {
      const len = this.productActionSheet.selected.length;
      if (len === 1) {
        return `${len} товар`;
      } else if (len > 1 && len < 5) {
        return `${len} товара`;
      } else {
        return `${len} товаров`;
      }
    },
    suiteCreationFuncDisabled() {
      return this.hasProductSuiteComplete(this.productActionSheet.selected);
    },
    suiteDuplicateFuncDisabled() {
      return this.hasProductDuplicateComplete(this.productActionSheet.selected)
    },
    tradingFuncDisabled() {
      return !this.company.tradingBotsLimit || !this.hasProductForTrading(this.productActionSheet.selected);
    },
    tradingAvailable() {
      return this.company.tradingBots > 0;
    }
  },
  methods: {
    calculateWidth(pixels) {
      this.width = `calc(100% - ${pixels}px)`;
    },
    onApplyTagsConfirm(tagCount) {
      this.$store.dispatch("core/setConfirmDialog", {
        headline: null,
        type: confirmationTypes.NEST_TAGS_IN_PRODUCTS,
        dialogText: !tagCount
          ? `Вы не выбрали ни одной метки, вы действительно хотите удалить все метки с ${this.productActionSheet.selected.length} товар(ов)?`
          : `Вы действительно хотите применить ${tagCount} метки(у) на ${this.productActionSheet.selected.length} товар(ов)?`,
        cancelBtnLabel: "Отмена",
        confirmBtnLabel: "Подтвердить",
      });
    },
    onRemoveConfirm() {
      this.$store.dispatch("core/setConfirmDialog", {
        headline: null,
        type: confirmationTypes.REMOVE_PRODUCTS,
        dialogText: `Вы действительно хотите удалить ${this.productActionSheet.selected.length} товаров?`,
        cancelBtnLabel: "Отмена",
        confirmBtnLabel: "Подтвердить",
      });
    },
    onActionSubscribe(action) {
      if (action.type === "core/toggleDrawer") {
        action.payload
          ? this.calculateWidth(this.navBar.maxWidth)
          : this.calculateWidth(0);
      }
      if (action.type === "core/autoMinimizeDrawer") {
        action.payload
          ? this.calculateWidth(this.navBar.miniWidth)
          : this.calculateWidth(this.navBar.maxWidth);
      }
    },
    onSetDeliveryPoints() {
      this.$store.dispatch("formModels/openDeliveryPointsForm");
    },
    onEnableBots() {
      this.$store.dispatch("formModels/openTradingBotsForm");
    },
    onSetPriceMarkup() {
      this.$store.dispatch("formModels/openPriceMarkupForm");
    },
    onSetPriceMargin() {
      this.$store.dispatch("formModels/openPriceMarginForm");
    },
    onSetPriceDiscount() {
      this.$store.dispatch("formModels/openPriceDiscountForm");
    },
    onGraphCategoriesView() {
      this.$store.commit("serviceModals/OPEN_GRAPH_CATEGORIES_MODAL");
    },
    onDisplayCompositeWizard() {
      this.$store
      .dispatch("stepWizard/openProductCompositeWizard", this.productActionSheet.selected)
      .then(this.pullOffProducts)
    },
    onDisplayDuplicateWizard() {
      this.$store
          .dispatch("stepWizard/openProductDuplicateWizard", this.productActionSheet.selected)
          .then(this.pullOffProducts)
    },
    onDisplayPlatfromSelection() {
      this.$store.commit("serviceModals/DISPLAY_PLATFORM_SELECTION_MODAL");
    },
    selectAllProducts() {
      this.$store.dispatch("datasources/selectAllProducts");
    },
    pullOffProducts() {
      this.$store.dispatch("datasources/pullOffProducts");
    },
  },
  created() {
    if (this.navBar.drawer && !this.navBar.mini) {
      this.calculateWidth(this.navBar.maxWidth);
    } else if (this.navBar.mini) {
      this.calculateWidth(this.navBar.miniWidth);
    } else if (!this.navBar.drawer) {
      this.calculateWidth(0);
    }
    this.unsubscribe = this.$store.subscribeAction(this.onActionSubscribe);
  },
  beforeDestroy() {
    this.unsubscribe && this.unsubscribe();
  },
};
</script>
