import * as firebase from "firebase/app"
import "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyANg-Nmc0-PK_L7dyjDKOuvpZrsBMqSgeA",
    authDomain: "kaspi-2e75a.firebaseapp.com",
    databaseURL: "https://kaspi-2e75a.firebaseio.com",
    projectId: "kaspi-2e75a",
    storageBucket: "kaspi-2e75a.appspot.com",
    messagingSenderId: "309467230611",
    appId: "1:309467230611:web:7948ae540368e6d0c87866"
}

class FirebaseAuth {
    constructor() {
        this.observer = null;
        this.auth = {
            signUpTriggerEnabled: false,
            isNewSession: false
        }
    }

    setup() {
        firebase.initializeApp(firebaseConfig);
        firebase.auth().onAuthStateChanged(this.onUserChanged);
    }

    subscribe(observer) {
        this.observer = observer;
    }

    getToken() {
        return this.getCurrentUser().getIdToken();
    }

    getCurrentUser() {
        return firebase.auth().currentUser;
    }

    async getTokenExpirationDate() {
        const idTokenResult = await this.getCurrentUser().getIdTokenResult();
        return new Date(idTokenResult.expirationTime);
    }

    signUp = async (email, password) => {
        if (!this.observer)
            throw new Error('Observer not defined!');
        await firebase.auth().createUserWithEmailAndPassword(email, password);
        this.auth.signUpTriggerEnabled = true;
    }

    signIn = async (email, password) => {
        if (!this.observer)
            throw new Error('Observer not defined!');
        await firebase.auth().signInWithEmailAndPassword(email, password);
        this.auth.isNewSession = true;
    }

    signOut = async () => {
        await firebase.auth().signOut();
    }

    onUserChanged = async (user) => {
        if (this.observer) {
            console.log("User logged in ? ", user && true);
            if (!user) {
                this.auth.isNewSession = false;
                this.auth.signUpTriggerEnabled && (this.auth.signUpTriggerEnabled = false);
                this.observer.authChanged();
            }
            else {
                const [token, expirationDate] = await Promise.all([
                    this.getToken(),
                    this.getTokenExpirationDate()
                ])
                this.observer.authChanged(
                    token && typeof token === 'string',
                    expirationDate,
                    this.auth.signUpTriggerEnabled,
                    this.auth.isNewSession
                );
            }
        }
    }
}

export default new FirebaseAuth();