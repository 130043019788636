import { getField, updateField } from 'vuex-map-fields';
import { notificatonStatuses } from "@/enumerations";
import {
    ProductFormPrototype, CostEstimatePrototype
} from "@/models/ProductUIModel";
import ServiceFacade from "@/services/ServiceFacade";
import TimeoutGenerator from "@/helpers/TimeoutGenerator";

const state = () => ({
    dialog: false,
    readOnly: false,
    tabActivities: {
        general: true,
        history: false,
        inventory: false,
        spec: false,
        prices: false,
        outlets: false,
    },
    photoSlide: 0,
    UIModel: ProductFormPrototype.pureClone(),
    UICostEstimateOptionBuffer: CostEstimatePrototype.pureClone()
});

const actions = {
    mountProductModel({ commit }, ProductModel) {
        let ProductUITemplate = ProductFormPrototype.pureClone();

        for (const prop in ProductModel) {
            if (prop in ProductUITemplate.basic) {
                ProductUITemplate.basic[prop] = ProductFormPrototype.copyMemoryBlock(prop, ProductModel)
            }
            if (prop in ProductUITemplate.trading) {
                ProductUITemplate.trading[prop] = ProductFormPrototype.copyMemoryBlock(prop, ProductModel)
            }
            if (prop in ProductUITemplate.inventories) {
                ProductUITemplate.inventories[prop] = ProductFormPrototype.copyMemoryBlock(prop, ProductModel)
            }
        }

        commit('MUTATE_PRODUCT_UI_MODEL', ProductUITemplate);
    },
    async openAndLoadProductUIForm({ commit, dispatch }, { PartialProductModel, readOnly }) {
        commit('TOGGLE_PRODUCT_UI_VISIBILITY', true);
        commit('SET_READONLY_PROPERTY', readOnly);
        commit('SET_PHOTO_SLIDE', 0);
        await dispatch('mountProductModel', PartialProductModel);
    },
    async closeProductUIForm({ commit }) {
        commit('TOGGLE_PRODUCT_UI_VISIBILITY', false);
        const ProductUITemplate = ProductFormPrototype.pureClone();
        const CostEstimateTBuffer = CostEstimatePrototype.pureClone();
        await TimeoutGenerator.timeWait(250);

        commit('MUTATE_COST_ESTIMATE_OPTION_BUFFER', CostEstimateTBuffer);
        commit('MUTATE_PRODUCT_UI_MODEL', ProductUITemplate);
        commit('SET_TAB_ACTIVITIES', {
            general: true,
            history: false,
            inventory: false,
            spec: false,
            prices: false,
            outlets: false,
        });
    },
    async updateCategoryDispatchAction({ commit, state }, { bucketID, treeViewOptions }) {
        try {
            const isCreation = false;
            const { sku } = state.UIModel.basic;

            const { status } = await ServiceFacade.saveProductsBy(bucketID, {
                sku,
                ...treeViewOptions
            }, isCreation);

            if (status == 200) {
                commit('BIND_PRODUCT_CATEGORY', treeViewOptions)
                commit('core/SET_NOTIFICATION', {
                    status: notificatonStatuses.success,
                    message: "Категория привязана к товару!"
                }, { root: true });
            }
        } catch (error) {
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: error.message || "Ошибка при подключении выбранной категории к товару!"
            }, { root: true });
        }
    },
    resetTradingSwitchIfMinPriceMissing({ commit, state }) {
        const { minPrice } = state.UIModel.trading;
        if (!(parseInt(minPrice) > 0)) {
            commit('DISABLE_TRADING');
        }
    },
    async costEstimateDispatchAction({ commit, state }, bucketID) {
        const { sku } = state.UIModel.basic;
        const {
            dealerPrice,
            margin,
            price,
            minPrice,
            minProfitPercent,
            discountPercent,
            marginPercent,
            kaspiFeePercent,
            prices,
            kaspiTrading,
        }
            = state.UIModel.trading;
        const currentTaskQID = new Date().getTime();

        try {
            commit('PUSH_NEW_TASKQID', currentTaskQID);

            const payload = {
                dealerPrice, margin, price,
                minPrice, minProfitPercent, marginPercent, discountPercent,
                kaspiFeePercent, prices, kaspiTrading
            }
            const { status, data } =
                await ServiceFacade.costEstimate(
                    bucketID,
                    sku,
                    payload
                );

            if (status == 200) {
                if (state.UICostEstimateOptionBuffer.sys.taskQueue.includes(currentTaskQID)) {
                    [...state.UICostEstimateOptionBuffer.sys.taskQueue]
                        .pop() == currentTaskQID &&
                        commit('REFRESH_COST_ESTIMATE', data);
                    commit(
                        'SET_TASK_QUEUE',
                        state.UICostEstimateOptionBuffer.sys.taskQueue.filter(qid => qid > currentTaskQID)
                    )
                }
            }
        } catch (error) {
            commit('SET_TASK_QUEUE', []);
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: error.message || "Error"
            }, { root: true });
        }
    }
}

const mutations = {
    updateField,
    TOGGLE_PRODUCT_UI_VISIBILITY(state, flag) {
        state.dialog = flag;
    },
    SET_READONLY_PROPERTY(state, readonly) {
        state.readOnly = readonly;
    },
    SET_TAB_ACTIVITIES(state, activities) {
        state.tabActivities = {
            ...activities
        }
    },
    MUTATE_PRODUCT_UI_MODEL(state, UIModel) {
        state.UIModel = {
            ...UIModel
        }
    },
    BIND_PRODUCT_CATEGORY(state, { rootCategory, categories, category }) {
        state.UIModel.basic.rootCategory = rootCategory;
        state.UIModel.basic.categories = categories;
        state.UIModel.basic.category = category;
    },
    ADD_PRODUCT_IMAGES(state, newImages) {
        state.UIModel.inventories.images = [
            ...state.UIModel.inventories.images || [],
            ...newImages
        ];
    },
    SET_PHOTO_SLIDE(state, slide) {
        state.photoSlide = slide;
    },
    MUTATE_COST_ESTIMATE_OPTION_BUFFER(state, buffer) {
        state.UICostEstimateOptionBuffer = {
            ...buffer
        }
    },
    SET_TASK_QUEUE(state, queue) {
        state.UICostEstimateOptionBuffer.sys.taskQueue = [...queue];
    },
    PUSH_NEW_TASKQID(state, qid) {
        state.UICostEstimateOptionBuffer.sys.taskQueue.push(qid);
    },
    DISABLE_TRADING(state) {
        state.UIModel.trading.kaspiTrading = false;
    },
    REFRESH_COST_ESTIMATE(state, { estimated, recommended, platformProduct, trading }) {
        state.UICostEstimateOptionBuffer.computed.estimated = { ...estimated }
        state.UICostEstimateOptionBuffer.computed.recommended = recommended
        state.UICostEstimateOptionBuffer.computed.platformProduct = { ...platformProduct }
        state.UICostEstimateOptionBuffer.computed.trading = { ...trading }
    }
}

const getters = {
    getField,
    UIModel: (state) => state.UIModel,
    basic: (state) => state.UIModel.basic,
    sku: (state) => state.UIModel.basic.sku,
    closed: (state) => !state.dialog
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}