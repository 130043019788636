import { lowStockActions } from "@/enumerations";

class ProductFormHelper {
    generateOutlets(outletsTemplate) {
        const outlets = {};
        outletsTemplate.forEach(({ id }) => {
            outlets[id] = {
                id,
                available: false,
                lowStockAction: lowStockActions.NOTHING,
                minQuantity: null,
                quantity: null
            }
        });
        return outlets;
    }
}

export default new ProductFormHelper();