import { render, staticRenderFns } from "./TagPopoverMenu.vue?vue&type=template&id=42588ec7&scoped=true"
import script from "./TagPopoverMenu.vue?vue&type=script&lang=js"
export * from "./TagPopoverMenu.vue?vue&type=script&lang=js"
import style0 from "./TagPopoverMenu.vue?vue&type=style&index=0&id=42588ec7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42588ec7",
  null
  
)

export default component.exports