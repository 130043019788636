<script>
import { deleteIntegrationFeature } from '../model';


export default {
  props: {
    integration: Object,
  },
  data() {
    return {
      actionVariants: [
        {
          text: this.$t('integration.actionVariants.NOTHING'),
          value: 'NOTHING',
        },
        {
          text: this.$t('integration.actionVariants.ARCHIVE'),
          value: 'ARCHIVE',
        },
        {
          text: this.$t('integration.actionVariants.DELETE'),
          value: 'DELETE',
        },
      ],
      existingProductsAction: 'NOTHING',
      loading: false,
      error: null,
    }
  },
  methods: {
    async confirm() {
      this.loading = true;

      try {
        await deleteIntegrationFeature({
          id: this.integration.id,
          existingProductsAction: this.existingProductsAction
        })
        this.$emit('deleted');
        
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      this.$emit('canceled');
    }
  }
}
</script>

<template>
  <v-card class="v-form-card">
    <v-card-title :style="{'word-break': 'auto-phrase'}">
      {{ $t('integration.deleteIntegration.confirmation.title', {name: integration.name}) }}
    </v-card-title>

    <v-alert v-if="error" type="error">{{ error.message || error }}</v-alert>
    
    <v-card-text>
      <v-select
        :label="$t('integration.deleteIntegration.confirmation.existingProductsAction.label')"
        :items="actionVariants"
        v-model="existingProductsAction"
      />
    </v-card-text>

    <v-card-actions class="justify-end">
      <v-btn
        height="40"
        width="120"
        class="figma-btn figma-btn-red"
        outlined
        type="button"
        :disabled="loading"
        @click="cancel"
      >{{ $t('button.cancel') }}</v-btn>

      <v-btn
        height="40"
        width="120"
        color="red"
        class="figma-btn white--text"
        type="button"
        :loading="loading"
        @click="confirm"
      >{{ $t('button.delete') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
