<script>
import { BaseStage } from './shared'


export default {
  name: 'IntegrationTypeSetter',
  extends: BaseStage,
  computed: {
    selectedType() {
      if (!this.builder.selectedType) return false;
      return this.builder.selectedType;
    },
    types() {
      return this.builder.types;
    }
  },
  methods: {
    changeType(type) {
      this.builder.setType(type);
    },
    getURL(type) {
      return type.icon;
    },
    verify() {
      return true;
    }
  }
}
</script>

<template>
  <v-row class="integration-type-setter" justify="center">
    <div v-for="item in types" :key="item.name">
      
      <v-tooltip bottom>
        <template v-slot:activator="{on, attrs}">
          <v-card
            :aspect-ratio="1/1"
            :disabled="item.isDisabled"
            :class="{'is-selected': selectedType.name === item.name}"
            width="100"
            height="100"
            class="integration-type-setter_item pa-4"
            @click="changeType(item)"
            v-bind="attrs" v-on="on"
          >
            <v-img :src="item.icon" />
          </v-card>
        </template>

        {{ item.name }}
      </v-tooltip>

    </div>
  </v-row>
</template>

<style lang="scss">
@import 'vuetify/src/styles/styles.sass';

.integration-type-setter {
  gap: 1rem;
}

.integration-type-setter_item {
  background-color: map-get($grey, 'darken-5');
  border: solid 4px rgba(0,0,0,0);
  display: flex;
  justify-content: center;
  align-items: center;
  
  &.is-selected {
    border-color: var(--v-primary-base);
  }
}
</style>
