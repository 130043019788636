export const STEPPER_EVENT_NAMES = {
  BEFORE_CHANGE: 'beforeChange',
  AFTER_CHANGE: 'afterChange'
}

/**
 * @typedef {'prev' | 'next' | 'finish'} StepperAction
 */

class ChangeEvent extends Event {
  /**
   * @type {number}
   */
  currentStep;

  /**
   * @type {StepperAction}
   */
  action;
  
  /**
   * @param {string} eventName
   * @param {number} currentStep 
   * @param {StepperAction} action 
   */
  constructor(eventName, currentStep, action) {
    super(eventName);
    this.action = action;
    this.currentStep = currentStep;
  }
}

export class BeforeChangeEvent extends ChangeEvent {
  /**
   * @type {number}
   */
  nextStep;
  
  /**
   * @param {number} currentStep 
   * @param {number} nextStep 
   * @param {StepperAction} action 
   */
  constructor(currentStep, nextStep, action) {
    super(STEPPER_EVENT_NAMES.BEFORE_CHANGE, currentStep, action);
    this.nextStep = nextStep;
  }
}

export class AfterChangeEvent extends ChangeEvent {
  /**
   * @type {number}
   */
  prevStep;
  
  /**
   * 
   * @param {number} currentStep 
   * @param {number} prevStep 
   * @param {StepperAction} action 
   */
  constructor(currentStep, prevStep, action) {
    super(STEPPER_EVENT_NAMES.AFTER_CHANGE, currentStep, action);
    this.prevStep = prevStep;
  }
}

export class FinishedEvent extends Event {
  /**
   * @type {any}
   */
  data;

  /**
   * 
   * @param {any} data 
   */
  constructor(data) {
    super('finish');
    this.data = data;
  }
}

