import CryptoJS from 'crypto-js';

class HashGenerator {
    createHash(key) {
        const SaltLength = 9;
        var salt = this.generateSalt(SaltLength);
        var hash = this.md5(key + salt);
        return salt + hash;
    }
    generateSalt(len) {
        var set = '0123456789abcdefghijklmnopqurstuvwxyzABCDEFGHIJKLMNOPQURSTUVWXYZ',
            setLen = set.length,
            salt = '';
        for (var i = 0; i < len; i++) {
            var p = Math.floor(Math.random() * setLen);
            salt += set[p];
        }
        return salt;
    }
    generateDigitCodes(len) {
        var set = '0123456789',
            setLen = set.length,
            codes = '';
        for (var i = 0; i < len; i++) {
            var p = Math.floor(Math.random() * setLen);
            codes += set[p];
        }
        return codes;
    }
    generateCapitalLetters(len) {
        var set = 'ABCDEFGHIJKLMNOPQURSTUVWXYZ',
            setLen = set.length,
            codes = '';
        for (var i = 0; i < len; i++) {
            var p = Math.floor(Math.random() * setLen);
            codes += set[p];
        }
        return codes;
    }
    md5(string) {
        return CryptoJS.MD5(string);
    }
    sha256sum(info) {
        return CryptoJS.SHA256(info).toString(CryptoJS.enc.Hex);
    }
    verifycsum(data, csum) {
        return this.sha256sum(data) === csum;
    }
    encrypt(secret, data) {
        return CryptoJS.AES.encrypt(data, secret).toString();
    }
    decrypt(secret, ciphertext) {
        var bytes = CryptoJS.AES.decrypt(ciphertext, secret);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
    genSixSymbolCode() {
        const len = 6;
        return this.generateCapitalLetters(len / 2).concat(this.generateDigitCodes(len / 2));
    }
    genRandomHash(len = 32) {
        return Math.random().toString(36).substring(2, len);
    }
    genRanHex = hexLen => [...Array(hexLen)].map(() => Math.floor(Math.random() * 16).toString(16)).join('')
}

export default new HashGenerator();