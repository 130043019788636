import { getField, updateField } from 'vuex-map-fields';
import {
    datasources,
    notificatonStatuses,
    loadingStates,
    salesTriggers,
    sortingTypes
} from "@/enumerations";
import ServiceFacade from "@/services/ServiceFacade";
import ProductDataFactory from "@/models/ProductDataFactory";
import HashGenerator from "@/helpers/HashGenerator";
import ErrorGenerator from "@/helpers/ErrorGenerator";
import store from '../index';
import { onlinePlatforms } from '../../enumerations';

export default {
    state: {
        syncStatus: loadingStates.BEGIN,
        bucketInfo: {},
        bucketProducts: [],
        datasources: [],
        searchTermsHistory: [],
        searchQuery: '',
        sortType: sortingTypes[0].value,
        pagination: {
            page: 0,
            pages: 0,
            totalVisible: 10
        },
        facets: [],
        fullFacetsCapture: {},
        graphCategories: [],
        bucketSize: 0,
        productCount: 0,
        filters: {},    // template
        initialFilterChecksum: undefined,
        verifiedFilterChecksum: undefined,
        namespace: null
    },
    actions: {
        clearBucket({ commit }) {
            commit('SET_BUCKET_PRODUCTS', []);
            commit('SET_LIST_OF_DATASOURCES', {
                list: []
            });
            commit('SET_FACETS', []);
            commit('SET_SEARCH_QUERY', '');
            commit('CLEAR_TERMS_HISTORY', []);
            commit('SET_FILTER_CHECKSUM', undefined);
            commit('SET_SNAPSHOT_OF', {});

            commit('SET_TOTAL_PAGES', 0);
            commit('SET_CURRENT_PAGE', 0);
        },
        swipeOrSearch({ commit, dispatch }, page = 1) {
            commit('SET_CURRENT_PAGE', page);
            dispatch('asyncRefreshBucket', { withSlots: false });
        },
        /*      Service actions     */
        async asyncRefreshBucket({ commit, dispatch }, { withSlots = true, withProducts = true }) {
            try {
                commit('eventBus/SET_TCP_FETCHING_STATUS', loadingStates.BUSY, { root: true })
                let actions = new Array();

                withProducts && actions.push(dispatch('loadInventoryProducts'))
                withSlots && actions.push(dispatch('loadInventorySlots'))

                await Promise.all(actions);
                commit('eventBus/SET_TCP_FETCHING_STATUS', loadingStates.SUCCESS, { root: true })

                const marketBoxes = [...store.getters['core/marketBoxes']].map((market) => {
                    const m = {
                        ...market,
                        isLocked: !(store.getters['datasources/platformsOf'] || []).find((platform) => platform.id === market.id)
                    };
                    if (market.id === onlinePlatforms.ALL) m.isLocked = false;

                    return m;
                });

                store.commit('core/SET_MARKET_DASHBOARD', marketBoxes);
                
            } catch (error) {
                commit('core/SET_NOTIFICATION', {
                    status: notificatonStatuses.error,
                    message: error.message
                }, { root: true });
                commit(
                    'eventBus/SET_TCP_FETCHING_STATUS',
                    ErrorGenerator.tcpConnectionErrorCode(error),
                    { root: true }
                )
            }
        },
        async loadInventoryProducts({ commit, state }) {
            const options = {
                namespace: state.namespace,
                slot: state.bucketInfo.id,
                page: state.pagination.page,
                term: state.searchQuery,
                sort: sortingTypes.find((item) => item.value == state.sortType)?.type,
                buildGraphCategories: !state.fullFacetsCapture.category ? true : false,
                filters:
                    Object.keys(state.filters)
                        .filter(fk => state.filters[fk] !== undefined)
                        .map((fk) => {
                            let obj = { prop: fk, data: null }
                            if (state.filters[fk]?.value !== undefined) {
                                obj.data = state.filters[fk]?.value;
                            } else {
                                obj.data = state.filters[fk]
                            }
                            return obj;
                        })
            };
            var { status, data } = await ServiceFacade.fetchBucketProducts(options);
            commit('SET_FILTER_CHECKSUM', HashGenerator.sha256sum(JSON.stringify(state.filters)));

            if (status == 200) {
                let iPage = data?.page > 1 ? data?.page - 1 : 0;

                if (Array.isArray(data?.facets)) {
                    const snapshotTuples = ['category', 'brand', 'sourceName'];

                    tupleLoop:
                    for (const T of snapshotTuples) {
                        let encryptedSnapshot = undefined;

                        if (T === 'category') {
                            const { category } = data.fullshotCaptureOf || {};
                            if (category && !state.fullFacetsCapture[T]) {
                                encryptedSnapshot = HashGenerator.encrypt(
                                    state.namespace + ":" + T,
                                    JSON.stringify(category)
                                )
                            }
                        } else {
                            const facetRecordObject = data?.facets.find((facet) => Array.isArray(facet[T]))?.[T];

                            if (facetRecordObject && !state.fullFacetsCapture[T]) {
                                encryptedSnapshot = HashGenerator.encrypt(
                                    state.namespace + ":" + T,
                                    JSON.stringify(facetRecordObject).replace(/:\d+/gmi, ":0")
                                )
                            }
                        }

                        if (!encryptedSnapshot) {
                            continue tupleLoop;
                        }

                        commit('CHANGE_SNAPSHOT_OF', {
                            model: T,
                            cipher: encryptedSnapshot
                        });
                    }
                }

                commit('SET_BUCKET_SIZE', data?.total || 0);
                commit('SET_PRODUCT_COUNT', data?.count || 0);
                commit('PUSH_SEARCH_TERM', state.searchQuery);
                commit('SET_TOTAL_PAGES', data?.pages || 0);
                commit('SET_CURRENT_PAGE', data?.page);
                commit('SET_FACETS', data?.facets || []);
                commit('SET_BUCKET_PRODUCTS', ProductDataFactory.produce(
                    data?.products?.map((item, index) => {
                        return {
                            ...item,
                            __position: index + iPage * 100 + 1,
                        }
                    }) || state.bucketProducts
                ));

                data = null;
            }
        },
        async loadInventorySlots({ commit, state }) {
            const { status, data } = await ServiceFacade.dataSourceBucket(state.namespace)

            if (status == 200) {
                // if (state.namespace == bucketNamespace.DATASOURCES) {
                    const { downloadLinks, fetchPriceKey, priceExports } = data;
                    if (downloadLinks && fetchPriceKey && priceExports) {
                        commit('SET_DATASOURCE_EXPORT', { downloadLinks, fetchPriceKey, priceExports });
                    }
                // }
                // if (!state.datasources.length) {
                    commit('SET_LIST_OF_DATASOURCES', { list: data[state.namespace] });
                // }                
            }
        },
        async plugDataSource({ commit }, id) {
            try {
                commit('SET_SYNC_STATUS', loadingStates.BUSY);
                const { status } = await ServiceFacade.plugDataSource(id);

                if (status == 200) {
                    commit('SET_SYNC_STATUS', loadingStates.SUCCESS);
                    commit(
                        'core/SET_SIMPLE_DIALOG',
                        `Спасибо, ваша заявка на подключение ${id} принята. Мы с вами свяжемся для завершения интеграции.`,
                        { root: true }
                    );
                }
            } catch (error) {
                commit('core/SET_NOTIFICATION', {
                    status: notificatonStatuses.error,
                    message: error.message || "Error"
                }, { root: true });
                commit('SET_SYNC_STATUS', loadingStates.ERROR);
            }
        },
        /*      Sales           */
        async switchSaleConnection({ commit, rootGetters, state, dispatch }, {
            triggerType,
            multipleChoice = false,
            afterActionHookFn
        }) {
            try {
                let response;
                let notificationMessage;
                let postData;
                let datasourceId;

                if (multipleChoice) {
                    const { selected } = state.productActionSheet;

                    datasourceId = state.bucketInfo.id;
                    postData = selected.map(sku => {
                        return {
                            sku
                        }
                    });
                } else {
                    const productUIForm = rootGetters["productUIForm/basic"];

                    datasourceId = state.bucketInfo.id === datasources["Marketradar"]
                        ? (productUIForm.sourceId || productUIForm.datasource)
                        : state.bucketInfo.id;
                    postData = [{ sku: productUIForm.sku }];
                }

                commit('SET_SYNC_STATUS', loadingStates.BUSY);

                if (triggerType === salesTriggers.SALE_ON) {
                    response = await ServiceFacade.connectProduct(datasourceId, postData)
                    notificationMessage = "Товар(ы) выставлен(ы) на продажу";
                }
                else {
                    response = await ServiceFacade.disconnectProduct(datasourceId, postData)
                    notificationMessage = "Товар(ы) снят(ы) с продажи";
                }

                const { status, data } = response;
                if (status == 200) {
                    commit('SET_SYNC_STATUS', loadingStates.SUCCESS);
                    commit('core/SET_NOTIFICATION', {
                        status: notificatonStatuses.success,
                        message: notificationMessage
                    }, { root: true });
                    if (state.productActionSheet.selected.length > 0) {
                        dispatch('pullOffProducts');
                    }

                    afterActionHookFn(
                        Array.isArray(data.products) && data.products?.length > 0
                            ? [...data.products]
                            : [...postData]
                    );
                }
            } catch (error) {
                commit('core/SET_NOTIFICATION', {
                    status: notificatonStatuses.error,
                    message: error.message || "Error"
                }, { root: true });
                commit('SET_SYNC_STATUS', loadingStates.ERROR);
            }
        }
    },
    mutations: {
        updateField,
        SET_SEARCH_QUERY(state, query) {
            state.searchQuery = query;
        },
        PUSH_SEARCH_TERM(state, term) {
            state.searchTermsHistory = [
                ...state.searchTermsHistory,
                term
            ]
        },
        CLEAR_TERMS_HISTORY(state, history = []) {
            state.searchTermsHistory = [...history];
        },
        SET_FILTER_CHECKSUM(state, sum) {
            state.verifiedFilterChecksum = sum;
        },
        CHANGE_SNAPSHOT_OF(state, { model, cipher }) {
            state.fullFacetsCapture = {
                ...state.fullFacetsCapture,
                [model]: cipher
            }
        },
        SET_SNAPSHOT_OF(state, newCapture) {
            state.fullFacetsCapture = {
                ...newCapture
            }
        },
        SET_FACETS(state, facets) {
            state.facets = [...facets];
        },
        SET_SORT_BY(state, type) {
            state.sortType = type;
        },
        SET_SYNC_STATUS(state, status) {
            state.syncStatus = status;
        },
        SET_ACTIVE_BUCKET(state, iD) {
            state.bucketInfo = Object.freeze({
                ...state.datasources.find(datasource => datasource.id === iD)
            });
        },
        SET_LIST_OF_DATASOURCES(state, { list }) {
            state.datasources = Object.freeze([...list]);
        },
        SET_ACTIVE_BUCKET_SETTINGS(state, settings) {
            state.bucketInfo = Object.freeze({
                ...state.bucketInfo,
                ...settings
            });
        },
        SET_BUCKET_PRODUCTS(state, products) {
            state.bucketProducts = [
                ...products
            ];
        },
        SET_BUCKET_SIZE(state, size) {
            state.bucketSize = size;
        },
        SET_PRODUCT_COUNT(state, count) {
            state.productCount = count;
        },
        SET_CURRENT_PAGE(state, page) {
            state.pagination = {
                ...state.pagination,
                page
            }
        },
        SET_TOTAL_PAGES(state, pages) {
            state.pagination = {
                ...state.pagination,
                pages
            }
        }
    },
    getters: {
        bucketID: (state) => state.bucketInfo ? state.bucketInfo.id : undefined,
        syncStatus: (state) => state.syncStatus,
        bucketInfo: (state) => state.bucketInfo,
        bucketProducts: (state) => state.bucketProducts,
        hasEmptyInventoryProducts: (state) => !state.bucketProducts.length,
        facets: (state) => state.facets,
        isActingFiltersChecksum: (state) => HashGenerator.verifycsum(JSON.stringify(state.filters), state.verifiedFilterChecksum),
        isInitialFilters: (state) => HashGenerator.verifycsum(JSON.stringify(state.filters), state.initialFilterChecksum),
        pagination: (state) => state.pagination,
        currentSearchTerm: (state) => state.searchTermsHistory[state.searchTermsHistory.length - 1] || '',
        dataSourceShortList: (state) => state.datasources.map(({ id, name }) => {
            return { id, name }
        }),
        descryptedFullShot: (state) => (facet) => {
            const ciphertext = state.fullFacetsCapture[facet];
            if (!ciphertext) {
                return false;
            }
            const descryptedSnapshot = HashGenerator.decrypt(state.namespace + ":" + facet, ciphertext);
            return descryptedSnapshot && JSON.parse(descryptedSnapshot);
        },
        identify: (state) => (datasource) => {
            return datasource === state.bucketInfo.id;
        },
        getField
    }
}
