import store from "../store";

const navExclusions = ["Authentication", "CompanySetup", "Analytics", "Home"];

export default async (to, from, next) => {
    const { firebaseAuth, profileIsActive, profileSetupActive } = store.state.profile;
    const loggedInFirebase = firebaseAuth.loggedIn;
    const requaresAuth = to.matched.some(record => record.meta.requaresAuth);
    const nearestRoutesWith = to.matched.slice().reverse().find(r => r.meta);

    if (requaresAuth && !loggedInFirebase) {
        return next({ name: "Authentication", replace: true });
    }
    else if (loggedInFirebase) {
        if (!navExclusions.includes(to.name)) {
            store.dispatch("core/setActiveNav", to.name);
        }
        if (nearestRoutesWith && nearestRoutesWith.meta) {
            store.dispatch('core/setTitle', nearestRoutesWith.meta.title || "n/A");
        }
        /* **   Redirect    * **/
        if (profileIsActive) {
            if (to.name === "CompanySetup") {
                return next({ name: "Products" });
            }
        }
        if (!profileIsActive && !profileSetupActive) {
            try {
                const profileReady = await store.dispatch("profile/fetchProfile");
                if (!profileReady) {
                    await store.dispatch("profile/profileSetupTrigger");
                    return next({ name: "CompanySetup" });
                }
                else if (profileReady) {
                    window.dispatchEvent(new CustomEvent("order@sync"));
                    window.dispatchEvent(new CustomEvent("news@sync"));
                }
            } catch (e) {
                return next(false);  // abort the current navigation
            }
        }

        /* **   Access control    * **/
        if (to.name === "Home" || to.name === "Authentication") {
            return next({ name: "Products", replace: true });
        }
        if (to.name === "ProductSearch" && from.name !== "Products") {
            return next({ name: "Products", replace: true });
        }

    }
    next();
}