<script>
import { editSourceParameters } from '../model';

export default {
  props: {
    id: String,
    sourceParams: Object
  },
  data() {
    return {
      loading: false,
      error: null,
      params: {
        url: '',
        headerName: '',
        automaticImport: '',
        downloadLogin: '',
        downloadPassword: ''
      },
      automaticImportVariants: [
        {
          text: this.$t('integration.automaticImportVariants.NEVER'),
          value: "NEVER",
        },
        {
          text: this.$t('integration.automaticImportVariants.EVERY_1_HOUR'),
          value: "EVERY_1_HOUR",
        },
        {
          text: this.$t('integration.automaticImportVariants.EVERY_30_MIN'),
          value: "EVERY_30_MIN",
        },
        {
          text: this.$t('integration.automaticImportVariants.EVERY_15_MIN'),
          value: "EVERY_15_MIN",
        },
        {
          text: this.$t('integration.automaticImportVariants.EVERY_1_DAY'),
          value: "EVERY_1_DAY",
        },
      ]
    }
  },
  created() {
    this.params = { 
      url: this.sourceParams.url,
      headerName: this.sourceParams.headerName,
      automaticImport: this.sourceParams.automaticImport,
      downloadLogin: this.sourceParams.downloadLogin,
      downloadPassword: this.sourceParams.downloadPassword
    };
  },
  computed: {
    rules() {
      return [
        (v) => (v.trim()).length > 0 || 'Required'
      ]
    },
  },
  methods: {
    async save() {
      this.loading = true;
      
      try {
        const params = { ...this.params };

        await editSourceParameters(this.id, params);
        this.$emit('saved');
      } catch(error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      this.$emit('canceled');
    }
  }
}
</script>

<template>
  <v-form :disabled="this.loading" :value="true" @submit.prevent="save" class="d-flex flex-column edit-source-parameters-form">
    <v-alert v-if="error" type="error">{{ error.message || error }}</v-alert>

    <v-card>
      <v-card-text>
        <v-text-field
          :rules="rules"
          :label="$t('integration.card.dataParams.url')"
          v-model="params.url"
        />
        <v-text-field
          :rules="rules"
          :label="$t('integration.card.dataParams.headerName')"
          v-model="params.headerName"
        />
    
        <v-text-field
          :label="$t('integration.card.dataParams.downloadLogin')"
          v-model="params.downloadLogin"
        />
    
        <v-text-field
          :label="$t('integration.card.dataParams.downloadPassword')"
          v-model="params.downloadPassword"
        />
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-text>
        <v-select
          :label="$t('integration.card.dataParams.automaticImport')"
          v-model="params.automaticImport"
          :items="automaticImportVariants"
        />
      </v-card-text>
    </v-card>

    <div class="d-flex justify-space-between">
      <v-btn
        color="primary"
        class="figma-btn "
        outlined
        type="button"
        @click="cancel"
        :disabled="this.loading"
      >{{ $t('button.cancel') }}</v-btn>
      
      <v-btn
        color="primary"
        class="figma-btn "
        type="submit"
        :loading="this.loading"
      >{{ $t('button.save') }}</v-btn>
    </div>
  </v-form>
</template>

<style>
.edit-source-parameters-form {
  gap: 1rem;
}
</style>
