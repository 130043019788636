import CustomerManagementService from '../../../../services/CustomerManagementService';
import store from '@/store'

/**
 * 
 * @param {string} id 
 * @param {{
 *  syncPrice: "ALWAYS" | "NEW_ONLY" | "NEVER",
 *  syncOutlets: "ALWAYS" | "NEW_ONLY" | "NEVER",
 *  syncImages: "ALWAYS" | "NEW_ONLY" | "NEVER",
 *  syncDescription: "ALWAYS" | "NEW_ONLY" | "NEVER",
 *  syncParams: "ALWAYS" | "NEW_ONLY" | "NEVER" ,
 *  notFoundProductsAction: "ARCHIVE" | "DELETE" | "NOTHING",
 *  syncSourceProductsOnly: true,
 * }} inputDTO 
 */
export async function editSyncParameters(id, inputDTO) {
  try {
    const integration = store
      .getters['serviceModals/platformsIntegrationServiceModal']
      .sources.find(el => el.id === id);
    
    const sourceParameters = {
      ...integration.sourceParameters
    };
    // Это поле нельзя передавать
    delete sourceParameters.type;
      
    const res = await CustomerManagementService.editIntegration({
      id,
      syncParameters: inputDTO,
      sourceParameters
    });

    integration.syncParameters = {
      ...integration.syncParameters,
      ...res.data.syncParameters
    }
  } catch(error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else error;
  }
}
