import Vue from 'vue'
import VueRouter from 'vue-router'
import NavigationGuard from '../guards/NavigationGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      requaresAuth: true,
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/dashboard/Dashboard.vue'),
    meta: {
      title: "Обзор",
      requaresAuth: true,
    }
  },
  {
    path: '/auth',
    name: 'Authentication',
    component: () => import('../views/auth/Auth.vue'),
    meta: {
      layout: 'authentication-layout'
    }
  },
  {
    path: '/company/setup',
    name: 'CompanySetup',
    component: () => import('../views/company/CompanySetup.vue'),
    meta: {
      title: "Создание магазина",
      requaresAuth: true,
      layout: 'basic-layout'
    }
  },
  {
    path: '/company/about',
    name: 'CompanyAbout',
    component: () => import('../views/company/CompanyAbout.vue'),
    meta: {
      title: "Профиль компании",
      requaresAuth: true,
      layout: 'basic-layout'
    }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../views/orders/Orders.vue'),
    meta: {
      title: "Работа с заказами",
      requaresAuth: true,
    }
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/products/Products.vue'),
    meta: {
      title: "Работа с товарами",
      requaresAuth: true,
    }
  },
  {
    path: '/product-search',
    name: 'ProductSearch',
    component: () => import('../views/suppliers/ProductSearch.vue'),
    meta: {
      layout: 'easy-layout',
      title: "Поиск у поставщика",
      requaresAuth: true,
    }
  },
  {
    path: '/analytics',
    name: 'Analytics',
    redirect: '/analytics/reports'
  },
  {
    path: '/analytics/reports',
    name: 'Reports',
    component: () => import('../views/internal-analytics/InternalAnalytics.vue'),
    meta: {
      title: "Экспорт",
      requaresAuth: true,
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/settings/Settings.vue'),
    meta: {
      title: "Настройки",
      requaresAuth: true,
    }
  },
  {
    path: '/dataexpert',
    name: 'DataExpert',
    component: () => import('../views/dataexpert/Report.vue'),
    meta: {
      title: "Data Expert: Мониторинг ваших товаров на Kaspi.kz",
      requaresAuth: true,
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(NavigationGuard)

export default router
