import { Stepper } from './stepper/Stepper';
import { INTEGRATIONS_TYPE } from './constants';
import CustomerManagementService from '../../../../services/CustomerManagementService';
import { VerifyError } from './errors';
import { addIntegrationFeature } from '../../../../features/integration/addIntegration';

/**
 * @typedef {{
 *  count: number
 * }} ProductDTO
 */

/**
 * @typedef {{
 *  id: string,
 *  name: string
 * }} PriceDTO
 */

/**
 * @typedef {{
 *  id: string,
 *  name: string
 * }} OutletDTO
 */

/**
 * @typedef {{
 *  "products": ProductDTO,
 *  "prices": PriceDTO[],
 *  "outlets": OutletDTO[]
 * }} IntegrationDataDTO
 */

export class IntegrationBuilder extends Stepper {
  types = INTEGRATIONS_TYPE;

  /**
   * @type {string}
   */
  selectedType = INTEGRATIONS_TYPE[0];

  /**
   * @type {{[p:string]: any}}
   */
  dataParams;

  /**
   * Данные интеграции после проверки
   * @type {IntegrationDataDTO}
   */
  integrationData;

  integrationSyncParams = {
    syncPrice: "ALWAYS",
    syncOutlets: "ALWAYS",
    syncImages: "ALWAYS",
    syncDescription: "ALWAYS",
    syncParams: "ALWAYS" ,
    notFoundProductsAction: "ARCHIVE",
    syncSourceProductsOnly: true,
  };

  prices = [];

  /**
   * Указывает на то что билдер что то загружает
   */
  loading=false;

  error=null;

  /**
   * Частота автоматического импорта
   */
  automaticImport="NEVER";

  /**
   * Вызывается когда интеграция успешно добавлена
   * @type {() => void}
   */
  onFinished;

  prev() {
    super.prev();
    this.error = null;
  }

  /**
   * @param {string} type 
   */
  setType(type) {
    this.selectedType = type;
  }

  async dataVerify() {
    const result = await CustomerManagementService.verifyIntegration({
      type: this.selectedType.id,
      ...this.dataParams,
    });

    if (!result.data.success) throw new VerifyError(result.data);

    this.integrationData = result.data.data;
    return this.integrationData;
  }

  async finish() {
    this.error = null;
    this.loading = true;

    try {
      await addIntegrationFeature({
        sourceParameters: {
          type: this.selectedType.id,
          automaticImport: this.automaticImport,
          ...this.dataParams,
        },
        syncParameters: this.integrationSyncParams,
        prices: this.prices,
      });

      if (this.onFinished) this.onFinished();
    } catch(error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  }
}
