<script>
import { INTEGRATION_KEY } from '../constants';
import { DeleteIntegrationConfirmation } from '../../../../../../features/integration/deleteIntegration';

export default {
  components: {
    DeleteIntegrationConfirmation
  },
  data() {
    return {
      showConfirmation: false,
    }
  },
  inject: [INTEGRATION_KEY],
  computed: {
    integration() {
      return this[INTEGRATION_KEY];
    },
  },
  methods: {
    show() {
      this.showConfirmation = true;
    },
    hide() {
      this.showConfirmation = false;
    }
  }
}
</script>

<template>
  <v-dialog max-width="600px" v-model="showConfirmation">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-title>Удалить</v-list-item-title>
      </v-list-item>
    </template>
    
    <DeleteIntegrationConfirmation
      @canceled="hide"
      @deleted="hide"
      :integration="integration"
    />
  </v-dialog>
</template>
