<template>
  <v-snackbar
    v-model="snackbar"
    :multi-line="multiLine"
    :timeout="timeout"
    :centered="isMobile"
    :top="isMobile"
  >
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn
        class="branded-btn"
        :color="color"
        text
        v-bind="attrs"
        @click="snackbar = false"
        >Close</v-btn
      >
    </template>
  </v-snackbar>
</template>

<script>
import { notificationColors } from "@/enumerations";
import ScreenResolutionMixin from "@/mixins/ScreenResolution";

export default {
  name: "Notification",
  mixins: [ScreenResolutionMixin],
  data() {
    return {
      multiLine: true,
      snackbar: false,
      timeout: 8000,
      color: "",
      message: "",
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "core/SET_NOTIFICATION") {
        if (this.snackbar) this.snackbar = false;
        this.color = notificationColors[state.core.ui.notification.status];
        this.message = state.core.ui.notification.message;
        this.snackbar = !this.snackbar;
      }
    });
  },
};
</script>