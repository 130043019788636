<template>
  <div id="inspire">
    <notification />
    <market-action-sheet></market-action-sheet>
    <!-- <transition name="fade"> -->
    <router-view />
    <!-- </transition> -->
  </div>
</template>

<script>
import MarketActionSheet from "../components/sheets/MarketActionSheet";
import Notification from "../components/notification/Notification";

export default {
  name: "EasyLayout",
  components: { Notification, MarketActionSheet },
};
</script>