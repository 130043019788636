import Vue from 'vue';
import Vuex from "vuex";
import core from "./modules/core";
import analytics from "./modules/analytics";
import dataExpert from "./modules/dataExpert";
import profile from "./modules/profile";
import settings from "./modules/settings";
import formModels from "./modules/formModels";
import serviceModals from "./modules/serviceModals";
import stepWizard from "./modules/stepWizard";
import uploaders from "./modules/uploaders";
import categories from "./modules/categories";
import platforms from "./modules/platforms";
import datasources from "./modules/datasources";
import suppliers from "./modules/suppliers";
import orders from "./modules/orders";
import eventBus from "./modules/eventBus";
import productUIForm from "./modules/productUIForm";

Vue.use(Vuex);

export default new Vuex.Store({
   modules: {
       core,
       analytics,
       profile,
       settings,
       formModels,
       stepWizard,
       serviceModals,
       uploaders,
       categories,
       platforms,
       datasources,
       suppliers,
       orders,
       eventBus,
       dataExpert,
       productUIForm
   }
})