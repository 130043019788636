<script>
import {
  IntegrationTypeSetter,
  DataParamsSetter,
  IntegrationVerification,
  IntegrationSyncParams,
  IntegrationPriceType
} from './stages';
import {
  IntegrationBuilder,
  INTEGRATION_BUILDER_KEY,
} from '../model';
import InitIntegrationStageVue from './InitIntegration.stage.vue';


const builderKey = INTEGRATION_BUILDER_KEY;

export default {
  name: 'IntegraionBuilderVue',
  components: {
    DataParamsSetter,
    InitIntegrationStageVue,
    IntegrationTypeSetter,
    IntegrationVerification,
    IntegrationSyncParams,
    IntegrationPriceType
  },
  data() {
    return {
      builder: new IntegrationBuilder(5),
    };
  },
  provide: function () {
    this.builder.onFinished = this.onFinished;
    
    return {
      [builderKey]: this.builder,
    };
  },
  methods: {
    async onFinished() {
      this.$emit('finished', this.builder.dataParams.name);
    }
  }
}
</script>

<template>
  <v-stepper v-model="builder.step">
    <v-stepper-header>
      <v-stepper-step step="1">{{ $t("integration.addIntegration.stepper.step.integrationType.label") }}</v-stepper-step>
      <v-stepper-step step="2">{{ $t("integration.addIntegration.stepper.step.dataParams.label") }}</v-stepper-step>
      <v-stepper-step step="3">{{ $t("integration.addIntegration.stepper.step.verification.label") }}</v-stepper-step>
      <v-stepper-step step="4">{{ $t("integration.addIntegration.stepper.step.priceType.label") }}</v-stepper-step>
      <v-stepper-step step="5">{{ $t("integration.addIntegration.stepper.step.syncParams.label") }}</v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>

      <InitIntegrationStageVue :step="1" :isActive="builder.step === 1">
        <IntegrationTypeSetter />
      </InitIntegrationStageVue>

      <InitIntegrationStageVue :step="2" :isActive="builder.step === 2">
        <DataParamsSetter />
      </InitIntegrationStageVue >

      <InitIntegrationStageVue :step="3" :isActive="builder.step === 3">
        <IntegrationVerification />
      </InitIntegrationStageVue>

      <InitIntegrationStageVue :step="4" :isActive="builder.step === 4">
        <IntegrationPriceType />
      </InitIntegrationStageVue>

      <InitIntegrationStageVue :step="5" :isActive="builder.step === 4">
        <IntegrationSyncParams />
      </InitIntegrationStageVue>

    </v-stepper-items>
  </v-stepper>
</template>
