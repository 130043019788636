<template>
  <v-card
    v-if="display"
    class="action__sheet pt-5 px-7 pb-3"
    width="100%"
    height="auto"
  >
    <v-row class="void">
      <h5 class="sheet_title">Выбрано {{ selectedCounter }}</h5>
    </v-row>
    <v-row class="mx-0 px-0" justify="space-between">
      <v-flex xs6 md6 class="pt-3">
        <custom-button
          :label="chargeCommandBtn.label"
          :icon="chargeCommandBtn.icon"
          :color="chargeCommandBtn.color"
          :handler="chargeCommandBtn.handler"
          :height="48"
        ></custom-button>
        <custom-button
          class="ml-4 px-8"
          :label="dischargeCommandBtn.label"
          :icon="dischargeCommandBtn.icon"
          :color="dischargeCommandBtn.color"
          :handler="dischargeCommandBtn.handler"
          :height="48"
        ></custom-button>
      </v-flex>
      <v-flex xs12 md6 class="pt-3">
        <v-row class="void" justify="end">
          <template v-if="!isMobile">
            <v-btn
              class="selection-all-btn mr-4"
              width="160"
              height="40"
              @click="selectAllProducts"
            >
              <span> Выделить все </span>
            </v-btn>
            <v-btn
              class="cancel-btn ml-4"
              width="auto"
              height="40"
              @click="pullOffProducts"
            >
              <span> Отменить выбор </span>
            </v-btn>
          </template>
          <template v-else>
            <v-btn icon @click="selectAllProducts">
              <v-icon size="36">mdi-select-all</v-icon>
            </v-btn>
            <v-btn icon class="ml-12" @click="pullOffProducts">
              <v-icon size="32">mdi-cancel</v-icon>
            </v-btn>
          </template>
        </v-row>
      </v-flex>
    </v-row>
  </v-card>
</template>

<script>
import "./action_sheet.css";
import { mapGetters } from "vuex";
import { confirmationTypes } from "@/enumerations";
import ScreenResolutionMixin from "@/mixins/ScreenResolution";
import ColorPaletteMixin from "@/mixins/ColorPalette";
import CustomButton from "@/components/buttons/CustomButton";

export default {
  name: "MarketActionSheet",
  components: {
    CustomButton,
  },
  mixins: [ScreenResolutionMixin, ColorPaletteMixin],
  computed: {
    ...mapGetters("suppliers", {
      productActionSheet: "productActionSheet",
    }),
    actions() {
      return {
        CHARGE_PRODUCTS: confirmationTypes.CHARGE_PRODUCTS,
        REJECT_PRODUCTS: confirmationTypes.REJECT_PRODUCTS,
      };
    },
    chargeCommandBtn() {
      return {
        label: "Выставить на продажу",
        icon: "mdi-cart",
        color: this.colors.fauxCobalt,
        handler: this.onConfirm.bind(this, this.actions.CHARGE_PRODUCTS),
      };
    },
    dischargeCommandBtn() {
      return {
        label: "Снять с продажи",
        icon: "mdi-cart-off",
        color: this.colors.fauxCobalt,
        handler: this.onConfirm.bind(this, this.actions.REJECT_PRODUCTS),
      };
    },
    display() {
      return this.productActionSheet.selected.length > 0;
    },
    selectedCounter() {
      const len = this.productActionSheet.selected.length;
      if (len === 1) {
        return `${len} товар`;
      } else if (len > 1 && len < 5) {
        return `${len} товара`;
      } else {
        return `${len} товаров`;
      }
    },
  },
  methods: {
    onConfirm(type) {
      this.$store.dispatch("core/setConfirmDialog", {
        headline: null,
        type,
        dialogText: `Вы действительно хотите ${
          type === this.actions.CHARGE_PRODUCTS ? "выставить" : "снять"
        } ${this.productActionSheet.selected.length} товаров на продажу?`,
        cancelBtnLabel: "Отмена",
        confirmBtnLabel: "Подтвердить",
      });
    },
    selectAllProducts() {
      this.$store.dispatch("suppliers/selectAllProducts");
    },
    pullOffProducts() {
      this.$store.dispatch("suppliers/pullOffProducts");
    },
  },
};
</script>
