import { getField, updateField } from 'vuex-map-fields';
import ServiceFacade from "@/services/ServiceFacade";
import HashGenerator from "@/helpers/HashGenerator";
import { notificatonStatuses, loadingStates, alerts } from "@/enumerations";

const state = () => ({
    company: {
        sellerId: '',
        email: '',
        name: '',
        plan: '',
        exportKey: '',
        status: {},
        hasDatasources: false,
        moduleAnalyticsEnabled: false,
        cities: [],
        monitoringEnabled: false,
        kaspiPickupPoints: [],
        catalogBatchSize: 0,
        alert: {},
        proSkladEnabled: false,
        productTags: []
    },
    pickupPointsUIForm: {},
    profileCreationForm: {
        sellerId: '',
        email: '',
        name: '',
        contactName: '',
        phone: {
            formatNational: '',
            original: ''
        },
    },
    auth: {
        accessToken: null,
        expiresIn: null
    },
    firebaseAuth: {
        loggedIn: false,
        expirationDate: null
    },
    notifications: [],
    profileIsActive: false,
    profileSetupActive: false
});

/******      Service actions     ******/
const actions = {
    refreshExpireTime({ commit }, expirationDate) {
        commit('SET_FIREBASE_SESSION_EXPIRATION', expirationDate);
    },
    loginFirebase({ commit }, expirationDate) {
        commit("SET_LOGGED_IN", true);
        commit('SET_FIREBASE_SESSION_EXPIRATION', expirationDate);
    },
    destroyProfile({ commit, state }) {
        commit('PROFILE_SWITCH_OFF');
        state.profileSetupActive && commit('COMPLETE_PROFILE_SETUP');

        commit('SET_COMPANY', {
            sellerId: '',
            email: '',
            name: '',
            plan: '',
            exportKey: '',
            status: {},
            hasDatasources: false,
            moduleAnalyticsEnabled: false,
            cities: [],
            monitoringEnabled: false,
            kaspiPickupPoints: [],
            catalogBatchSize: 0,
            alert: {},
            proSkladEnabled: false,
        });
        state.profileCreationForm.sellerId && commit('MUTATE_PROFILE_CREATION_FORM', {
            sellerId: '',
            email: '',
            name: '',
            contactName: '',
            phone: {
                formatNational: '',
                original: ''
            },
        });
        commit('UPDATE_NEWS_FEED', []);

        commit("SET_LOGGED_IN", false);
        commit('SET_FIREBASE_SESSION_EXPIRATION', null);
    },
    profileSetupTrigger({ commit }) {
        commit('SET_COMPANY', {
            sellerId: '',
            email: 'Пустой аккаунт',
            name: 'Новый магазин',
            plan: '',
            exportKey: '',
            status: {},
            hasDatasources: false,
            cities: [],
            monitoringEnabled: false,
            kaspiPickupPoints: []
        });
        commit('SET_PROFILE_SETUP_ACTIVE');
    },
    updateProfileCreationFormItem({ commit, state }, { model, value }) {
        let form = { ...state.profileCreationForm };
        form[model] = value;
        commit('MUTATE_PROFILE_CREATION_FORM', form);
    },
    removeSubscriptionAlerts({ commit, state }) {
        commit('SET_COMPANY', {
            ...state.company,
            alerts: state.company.alerts.filter(a => ![alerts.SUBSCRIPTION_EXPIRING, alerts.ACCOUNT_SUSPENDED].includes(a.id))
        });
    },
    autoCloseLastNotifications({ commit, state }) {
        const notifications = state.notifications.map(n => {
            return {
                ...n,
                unreadNotification: false
            }
        })
        commit('UPDATE_NEWS_FEED', notifications);
    },
    async createProfile({ commit, state }) {
        try {
            commit('core/SET_LOADING_STATUS', loadingStates.BUSY, { root: true });
            const { status } = await ServiceFacade.createCompany({
                ...state.profileCreationForm,
                phone: state.profileCreationForm.phone.formatNational
            });
            if (status == 200) {
                commit('core/SET_LOADING_STATUS', loadingStates.SUCCESS, { root: true });
                commit('COMPLETE_PROFILE_SETUP');
            }
        } catch (error) {
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: error.message || "Error"
            }, { root: true })
            commit('core/SET_LOADING_STATUS', loadingStates.ERROR, { root: true });
        }
    },
    async fetchProfile({ commit }) {
        try {
            commit('core/SET_LOADING_STATUS', loadingStates.BUSY, { root: true });
            const { status, data } = await ServiceFacade.profile();
            if (status == 200) {
                const pickupPointsUIFormModel = {};
                ["kaspi", "halyk", "forte", "ozon", "satu", "website", "website2", "jmart", "wildberries", "yandexmarket", "tomas"].map(platform => {
                    pickupPointsUIFormModel[platform] = data[`${platform}PickupPoints`]?.map(pp => {
                        if (!Array.isArray(pp.outlets)) {
                            pp.outlets = new Array();
                        }
                        return {
                            ...pp,
                            edit: false,
                            lastOutletsCommit: HashGenerator.encrypt('outletHashCommit', JSON.stringify(pp.outlets))
                        }
                    }) || [];
                });

                commit('core/SET_LOADING_STATUS', loadingStates.SUCCESS, { root: true });
                commit('SET_COMPANY', data);
                commit('SET_PICKUP_POINTS_UI_FORM', pickupPointsUIFormModel || {});
                commit('PROFILE_SWITCH_ON');
                return true;
            }
        } catch (error) {
            commit('core/SET_LOADING_STATUS', loadingStates.ERROR, { root: true });
            if (error.response && error.response.status == 404) {
                return false;
            }
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: error.message || "Error"
            }, { root: true });
            return Promise.reject(error);
        }
    },
    async fetchLastNotifications({ commit }) {
        try {
            const { status, data } = await ServiceFacade.fetchLastNotifications();
            if (status == 200) {
                const dateLastNotificationCheck =
                    localStorage.getItem("date_last_notification_check") &&
                    new Date(
                        new Number(localStorage.getItem("date_last_notification_check"))
                    );
                const notifications = data.notifications.map(n => {
                    return {
                        ...n,
                        unreadNotification: !dateLastNotificationCheck
                            ? true
                            : new Date(n.dateCreated).getTime() > dateLastNotificationCheck.getTime()
                    }
                })
                commit('UPDATE_NEWS_FEED', notifications);
            }
        } catch (error) {
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: "Невозможно синхронизироваться с последними новостями Маркетрадара:("
            }, { root: true });
        }
    },
    async createProductTag({ commit }, data) {
        try {
            const { status } = await ServiceFacade.createProductTag(data);
            if (status == 200) {
                commit('ADD_NEW_PRODUCT_TAG', data);
            }
        } catch (error) {
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: error.message || "Error"
            }, { root: true });
            commit('core/SET_LOADING_STATUS', loadingStates.ERROR, { root: true });
        }
    },
    async updateProductTag({ dispatch, commit }, data) {
        try {
            const { newTag, oldTag } = data;
            const { status } = await ServiceFacade.updateProductTag(oldTag.name, newTag);
            if (status == 200) {
                commit("UPDATE_PRODUCT_TAG", data);
                if (newTag.name !== oldTag.name) {
                    dispatch("eventBus/refreshProductTag", data, { root: true });
                }
            }
        } catch (error) {
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: error.message || "Error"
            }, { root: true });
            commit('core/SET_LOADING_STATUS', loadingStates.ERROR, { root: true });
        }
    },
    async plugPlatform({ commit }, id) {
        try {
            commit('core/SET_LOADING_STATUS', loadingStates.BUSY, { root: true });
            commit(
                'core/SET_SIMPLE_DIALOG',
                `Отправка в процессе...`,
                { root: true }
            )

            const { status } = await ServiceFacade.plugPlatform(id);

            if (status == 200) {
                commit('core/SET_LOADING_STATUS', loadingStates.SUCCESS, { root: true });
                commit(
                    'core/SET_SIMPLE_DIALOG',
                    `Спасибо, ваша заявка на подключение ${id} принята. Мы с вами свяжемся для завершения интеграции.`,
                    { root: true }
                )
            }
        } catch (error) {
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: error.message || "Error"
            }, { root: true });
            commit('core/SET_LOADING_STATUS', loadingStates.ERROR, { root: true });
        }
    },
    async plugAnalytics({ commit }) {
        try {
            await ServiceFacade.plugAnalytics();

            commit('core/SET_LOADING_STATUS', loadingStates.SUCCESS, { root: true });
            commit(
                'core/SET_SIMPLE_DIALOG',
                `Спасибо, ваша заявка на подключение Аналитики продаж принята.`,
                { root: true }
            )
        } catch (error) {
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: error.message || "Error"
            }, { root: true });
            commit('core/SET_LOADING_STATUS', loadingStates.ERROR, { root: true });
        }
    },
    async plugCRM({ commit }) {
        try {
            commit('core/SET_LOADING_STATUS', loadingStates.BUSY, { root: true });
            commit(
                'core/SET_SIMPLE_DIALOG',
                `Отправка в процессе...`,
                { root: true }
            )

            const { status } = await ServiceFacade.plugCRM();

            if (status == 200) {
                commit('core/SET_LOADING_STATUS', loadingStates.SUCCESS, { root: true });
                commit(
                    'core/SET_SIMPLE_DIALOG',
                    `Спасибо, ваша заявка на подключение принята. Мы с вами свяжемся для завершения интеграции.`,
                    { root: true }
                )
            }
        } catch (error) {
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: error.message || "Error"
            }, { root: true });
            commit('core/SET_LOADING_STATUS', loadingStates.ERROR, { root: true });
        }
    },
}

// mutations
const mutations = {
    updateField,
    SET_LOGGED_IN(state, value) {
        state.firebaseAuth.loggedIn = value;
    },
    SET_ACCESS_TOKEN(state, accessToken) {
        state.auth.accessToken = accessToken;
    },
    SET_FIREBASE_SESSION_EXPIRATION(state, expirationDate) {
        state.firebaseAuth.expirationDate = expirationDate;
    },
    SET_COMPANY(state, company) {
        state.company = { ...company };
    },
    MUTATE_PROFILE_CREATION_FORM(state, form) {
        state.profileCreationForm = { ...form }
    },
    SET_PROFILE_SETUP_ACTIVE(state, bool = true) {
        state.profileSetupActive = bool;
    },
    COMPLETE_PROFILE_SETUP(state, bool = false) {
        state.profileSetupActive = bool;
    },
    PROFILE_SWITCH_ON(state, bool = true) {
        state.profileIsActive = bool;
    },
    PROFILE_SWITCH_OFF(state, bool = false) {
        state.profileIsActive = bool;
    },
    ADD_NEW_PRODUCT_TAG(state, tag) {
        state.company.productTags.unshift(tag);
    },
    UPDATE_PRODUCT_TAG(state, { newTag, oldTag }) {
        const tags = [...state.company.productTags];
        tags.forEach((element, index) => {
            if (element.name == oldTag.name) {
                tags[index] = {
                    ...newTag
                }
            }
        });
        console.log(tags)
        state.company.productTags = [
            ...tags
        ]
    },
    UPDATE_NEWS_FEED(state, notifications) {
        state.notifications = [...notifications]
    },
    SET_PICKUP_POINTS_UI_FORM(state, UIForm) {
        state.pickupPointsUIForm = {...UIForm}
    }
}

// getters
const getters = {
    getField,
    company: (state) => state.company,
    notifications: (state) => state.notifications,
    profileIsActive: (state) => state.profileIsActive,
    profileChecked: (state) => state.profileIsActive || state.profileSetupActive,
    profileCreationForm: (state) => state.profileCreationForm,
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}