import Vue from 'vue';
import BucketCore from "@/store/generics/bucket.core";
import BucketDashboard from "@/store/generics/bucket.dashboard";
import ServiceFacade from "@/services/ServiceFacade";
import ProductDataFactory from "@/models/ProductDataFactory";
import { ProductFormPrototype } from "@/models/ProductUIModel";
import HashGenerator from "@/helpers/HashGenerator";
import TimeoutGenerator from "@/helpers/TimeoutGenerator";
import {
    bucketNamespace,
    notificatonStatuses,
    loadingStates,
    confirmationTypes,
    onlinePlatforms
} from "@/enumerations";
import {duplicateWizardDTO} from "@/DTO/duplicateWizardDTO";

const filterModel = {
    available: [],
    archived: [],
    hasReservations: [],
    published: [],
    isManualPrice: [],
    hasNegativeProfit: [],
    productType: [],
    brand: [],
    sourceName: [],
    category: [],
    tags: [],
    outletsAvailability: [],
    platforms: [onlinePlatforms["ALL"]],
    hasImages: [],
    hasDescription: [],
    hasParams: [],
    hasKaspiUrl: [],
    kaspiTradingEnabled: [],
    hasErrors: [],
}

const fullFacetCaptureModel = {
    brand: null,
    sourceName: null,
    category: null,
}

const state = () => ({
    ...BucketCore.state,
    ...BucketDashboard.state,
    frozenSnapshot: false,
    dataSourceExport: {
        downloadLinks: {},
        fetchPriceKey: '',
        priceExports: [],
    },
    filters: {
        ...filterModel
    },
    fullFacetsCapture: {
        ...fullFacetCaptureModel
    },
    initialFilterChecksum: HashGenerator.sha256sum(JSON.stringify(filterModel)),
    namespace: bucketNamespace.DATASOURCES
});

const actions = {
    ...BucketCore.actions,
    ...BucketDashboard.actions,
    forgetDatasourceExportSettings({ commit }) {
        commit('SET_DATASOURCE_EXPORT', {
            downloadLinks: {},
            fetchPriceKey: '',
            priceExports: [],
        });
    },
    /*      Service actions     */
    async updateDataSource({ commit, state, dispatch, rootGetters }) {
        try {
            const { id } = state.bucketInfo;
            const {
                enabledKaspi,
                kaspiFeePercent,
                kaspiMarginPercent,
                enabledHalyk,
                halykMarginPercent,
                jmartMarginPercent,
                halykLoanPeriod,
                ozonMarginPercent,
                enabledSatu, } = rootGetters["formModels/dataSourceForm"];

            commit('SET_SYNC_STATUS', loadingStates.BUSY);
            const { status } = await ServiceFacade.updateDataSource(
                id,
                {
                    enabledKaspi,
                    kaspiFeePercent: parseInt(kaspiFeePercent),
                    kaspiMarginPercent: parseInt(kaspiMarginPercent),
                    enabledHalyk,
                    halykMarginPercent: parseInt(halykMarginPercent),
                    jmartMarginPercent: parseInt(jmartMarginPercent),
                    halykLoanPeriod: parseInt(halykLoanPeriod),
                    enabledSatu,
                });
            let notificationMessage = "Изменения сохранены! Каталог товаров обновлен!";

            if (status == 200) {
                const newData = {
                    enabledKaspi,
                    kaspiFeePercent,
                    kaspiMarginPercent,
                    enabledHalyk,
                    halykMarginPercent,
                    jmartMarginPercent,
                    ozonMarginPercent,
                    halykLoanPeriod,
                    enabledSatu,
                }
                commit('SET_ACTIVE_BUCKET_SETTINGS', newData);
                commit('SET_SYNC_STATUS', loadingStates.SUCCESS);

                await TimeoutGenerator.timeWait(200);
                dispatch('eventBus/registerSystemEvent', 0xba, { root: true });

                commit('core/SET_NOTIFICATION', {
                    status: notificatonStatuses.success,
                    message: notificationMessage
                }, { root: true });
            }
        } catch (error) {
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: error.message || "Error"
            }, { root: true })
            commit('SET_SYNC_STATUS', loadingStates.ERROR);
        }
    },
    /*      Products            */
    async createProduct({ commit, state, rootGetters }, { actionHookFn, productType = 'uno' }) {
        try {
            const datasource = state.bucketInfo;
            let notificationMessage;
            let productAsPayload = {};

            commit('SET_SYNC_STATUS', loadingStates.BUSY);

            if (productType === 'uno') {
                const { sku, model, brand, price, dealerPrice, margin, outlets } = rootGetters["stepWizard/productCreationWizard"];
                productAsPayload = {
                    sku, model, brand, price, dealerPrice, margin, outlets
                }
            }
            if (productType === 'set') {
                const { productKit } = rootGetters["stepWizard/productCompositeWizard"];
                const { sku, model, brand, price, dealerPrice, inventory } = productKit
                productAsPayload = {
                    sku: sku.v,
                    model: model.v,
                    brand: brand.v,
                    price: price.v,
                    dealerPrice: dealerPrice.v,
                    productType,
                    productParts: inventory.map(cursor => cursor.sku)
                }
            }

            if (productType === 'child') {
                const {data, syncFields} = rootGetters["stepWizard/productDuplicateWizard"];
                productAsPayload = data.map(obj => duplicateWizardDTO(obj, syncFields) )
            }

            const { status, data } = await ServiceFacade.saveProductsBy(datasource.id, productAsPayload);
            if (status === 200) {
                notificationMessage = "Продукт успешно создан!";
                commit('SET_SYNC_STATUS', loadingStates.SUCCESS);
                actionHookFn(data.products);

                TimeoutGenerator.timeWait(200)
                    .then(() => commit('core/SET_NOTIFICATION', {
                        status: notificatonStatuses.success,
                        message: notificationMessage
                    }, { root: true }));
            }

        } catch (error) {
            let message;

            if (error.response && error.response.status === 400 && error.response.data && error.response.data.code === "PRODUCT_EXIST") {
                message = "[400] Продукт с таким SKU уже существует";
            }
            else {
                message = error.message || "Error";
            }
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message
            }, { root: true });
            commit('SET_SYNC_STATUS', loadingStates.ERROR);
        }
    },
    async saveProduct({ commit, state, rootGetters }, updateHookFn) {
        const datasource = state.bucketInfo;
        const isCreation = false;

        const ProductFormModel = ProductFormPrototype.destruct(rootGetters["productUIForm/UIModel"]);
        const ProductStatelessModel = rootGetters["eventBus/currentlyOpenProduct"] || {};

        try {
            const { sku } = ProductFormModel;
            commit('SET_SYNC_STATUS', loadingStates.BUSY);

            const diffs = ProductDataFactory.diffEntries(ProductFormModel, ProductStatelessModel);
            const { status, data } = await ServiceFacade.saveProductsBy(datasource.id, {
                ...diffs, sku
            }, isCreation);
            let notificationMessage = "Продукт обновлен!";

            if (status == 200) {
                commit('SET_SYNC_STATUS', loadingStates.SUCCESS);
                updateHookFn(data.products);

                TimeoutGenerator.timeWait(200)
                    .then(() => commit('core/SET_NOTIFICATION', {
                        status: notificatonStatuses.success,
                        message: notificationMessage
                    }, { root: true }));
            }
        } catch (error) {
            let message;

            if (error.response.status === 400 && error.response.data?.code) {
                switch (error.response.data.code) {
                    case "PRODUCT_EXIST": message = "[400] Продукт с таким SKU уже существует"; break;
                    case "INVALID_DATA": message = "[400] Неверные данные для товара"; break;
                    default: message = `[400] ${error.response.data.code}`;
                }
            } else {
                message = error.message || "Error";
            }
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message
            }, { root: true });
            commit('SET_SYNC_STATUS', loadingStates.ERROR);
        }
    },
    async removeProductSet({ commit, dispatch, state }, updateHookFn) {
        try {
            const datasourceId = state.bucketInfo.id;
            const { selected } = state.productActionSheet;

            let postData = selected.map(sku => {
                return {
                    sku
                }
            });

            commit('SET_SYNC_STATUS', loadingStates.BUSY);

            const { status } = await ServiceFacade.removeProducts(datasourceId, postData)
            let notificationMessage = "Товар(ы) удален(ы)";

            if (status == 200) {
                updateHookFn(postData);
                commit('SET_SYNC_STATUS', loadingStates.SUCCESS);
                commit('core/SET_NOTIFICATION', {
                    status: notificatonStatuses.success,
                    message: notificationMessage
                }, { root: true });
            }

            dispatch('pullOffProducts');
        } catch (error) {
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: error.message || "Error"
            }, { root: true });
            commit('SET_SYNC_STATUS', loadingStates.ERROR);
        }
    },
    async updateProductSet({ commit, state, dispatch, rootGetters }, {
        action,
        updateHookFn
    }) {
        const datasource = state.bucketInfo;
        const { selected } = state.productActionSheet;
        const isCreation = false;
        let body = null;
        let mutableFragment = {};
        let notificationMessage;

        commit('SET_SYNC_STATUS', loadingStates.BUSY);

        switch (action) {
            case confirmationTypes.SELECT_DELIVERY_POINTS: {
                const { boxes } = rootGetters["formModels/deliveryPointsForm"]
                let kaspiPp = {};
                for (const key in boxes) {
                    if (typeof boxes[key] !== 'object') {
                        kaspiPp[key] = {
                            available: false
                        }
                    }
                    else {
                        kaspiPp[key] = { ...boxes[key] }
                    }
                }
                mutableFragment = {
                    kaspiPp
                }
                break;
            }
            case confirmationTypes.TRADING_BOTS: {
                const { tradingEnabled } = rootGetters["formModels/tradingBotsForm"]
                mutableFragment = {
                    kaspiTrading: tradingEnabled == true
                }
                break;
            }
            case confirmationTypes.PRICE_MARKUP: {
                const { marginPercent } = rootGetters["formModels/priceMarkupForm"]
                mutableFragment = {
                    marginPercent: marginPercent ? parseInt(marginPercent) : -1
                }
                break;
            }
            case confirmationTypes.PRICE_MARGIN: {
                const { margin } = rootGetters["formModels/priceMarginForm"]
                mutableFragment = {
                    margin: margin ? parseInt(margin) : -1
                }
                break;
            }
            case confirmationTypes.PRICE_DISCOUNT: {
                const { discountPercent } = rootGetters["formModels/priceDiscountForm"]
                mutableFragment = {
                    discountPercent: discountPercent ? parseInt(discountPercent) : -1
                }
                break;
            }
            case confirmationTypes.NEST_CATEGORY_IN_PRODUCTS: {
                const { treeViewOptions } = rootGetters["serviceModals/graphCategoriesModal"];
                const { category, categories, rootCategory } = treeViewOptions;
                mutableFragment = {
                    category, categories, rootCategory
                }
                break;
            }
            case confirmationTypes.NEST_TAGS_IN_PRODUCTS: {
                const { newTags, rewriteTags } = rootGetters["serviceModals/tagPopoverModal"];
                mutableFragment["tags"] = new Array();

                if (newTags.length) {
                    body = selected.map(sku => {
                        const productTags = state.bucketProducts.find(item => item.sku === sku)?.["tags"] || [];
                        mutableFragment["tags"] = rewriteTags ?
                            [...newTags.map(tag => tag.name)]
                            :
                            [
                                ...productTags,
                                ...newTags.filter(tag => !productTags.includes(tag.name)).map(tag => tag.name)
                            ];
                        return {
                            sku,
                            ...mutableFragment
                        }
                    });
                }
                break;
            }
            case confirmationTypes.NEST_PLATFORMS_IN_PRODUCTS: {
                const { radioGroups } = rootGetters["serviceModals/platformSelectionModal"];

                body = selected.map(sku => {
                    mutableFragment["platforms"] = []

                    radioLoop:
                    for (const radio of radioGroups) {
                        if (radio.option === 'doNothing') {
                            continue radioLoop;
                        }
                        else if (radio.option === 'add') {
                            mutableFragment["platforms"].push({ id: `${radio.platform}`, enabled: true });
                        } else if (radio.option === 'remove') {
                            mutableFragment["platforms"].push({ id: `${radio.platform}`, enabled: false });
                        }
                    }

                    return {
                        sku,
                        ...mutableFragment
                    }
                });

                break;

            }
            default:
                break;
        }

        if (!body) {
            body = selected.map(sku => {
                return {
                    sku,
                    ...mutableFragment
                }
            });
        }

        try {
            const { status, data } = await ServiceFacade.saveProductsBy(datasource.id, body, isCreation);
            notificationMessage = "Продукты обновлены!";

            if (status == 200) {
                updateHookFn(data.products);
                commit('SET_SYNC_STATUS', loadingStates.SUCCESS);
                commit('core/SET_NOTIFICATION', {
                    status: notificatonStatuses.success,
                    message: notificationMessage
                }, { root: true })
            }

            dispatch('pullOffProducts');
        } catch (error) {
            let message;

            if (error.response.status === 400 && error.response.data?.code) {
                switch (error.response.data.code) {
                    case "BOTS_LIMIT_EXCEED": message = "[400] Достигнут лимит ботов"; break;
                    case "INVALID_DATA": message = "[400] Неверные данные для товара"; break;
                    default: message = `[400] ${error.response.data.code}`;
                }
            } else {
                message = error.message || "Error";
            }
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message
            }, { root: true });
            commit('SET_SYNC_STATUS', loadingStates.ERROR);
        }
    },
    async partialUpdateProduct({ commit, state }, {
        partialProduct,
        updateHookFn,
        withNotify = true
    }) {
        const datasource = state.bucketInfo;
        const isCreation = false;

        commit('SET_SYNC_STATUS', loadingStates.BUSY);

        const body = {
            ...partialProduct
        }

        try {
            const { status, data } = await ServiceFacade.saveProductsBy(datasource.id, body, isCreation);

            if (status == 200) {
                updateHookFn(data.products);
                commit('SET_SYNC_STATUS', loadingStates.SUCCESS);

                if (withNotify) {
                    let notificationMessage = "Продукт обновлен!";
                    commit('core/SET_NOTIFICATION', {
                        status: notificatonStatuses.success,
                        message: notificationMessage
                    }, { root: true });
                }
            }
        } catch (error) {
            let message;

            if (error.response.status === 400 && error.response.data?.code) {
                switch (error.response.data.code) {
                    case "PRODUCT_EXIST": message = "[400] Продукт с таким SKU уже существует"; break;
                    case "INVALID_DATA": message = "[400] Неверные данные для товара"; break;
                    default: message = `[400] ${error.response.data.code}`;
                }
            } else {
                message = error.message || "Error";
            }

            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: message
            }, { root: true });
            commit('SET_SYNC_STATUS', loadingStates.ERROR);
        }
    },
    async deleteProduct({ commit, state, rootGetters }, updateHookFn) {
        try {
            const datasource = state.bucketInfo;
            const sku = rootGetters["productUIForm/sku"];

            commit('SET_SYNC_STATUS', loadingStates.BUSY);
            const { status } = await ServiceFacade.deleteProduct(datasource.id, sku);
            let notificationMessage = "Продукт удален!";

            if (status == 200) {
                commit('SET_SYNC_STATUS', loadingStates.SUCCESS);
                updateHookFn([{ sku }]);

                TimeoutGenerator.timeWait(200)
                    .then(() => commit('core/SET_NOTIFICATION', {
                        status: notificatonStatuses.success,
                        message: notificationMessage
                    }, { root: true }));
            }
        } catch (error) {
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: error.message || "Error"
            }, { root: true });
            commit('SET_SYNC_STATUS', loadingStates.ERROR);
        }
    }
}

// mutations
const mutations = {
    ...BucketCore.mutations,
    ...BucketDashboard.mutations,
    SET_DATASOURCE_EXPORT(state, settings) {
        state.dataSourceExport = Object.freeze({ ...settings });
    },
    FREEZE_SNAPSHOT(state, flag) {
        state.frozenSnapshot = flag;
    },
    CHANGE_BUCKET_PRODUCT(state, updatedProduct) {
        let indxOfCurrentlyRef = state.bucketProducts
            .findIndex((currentlyProduct) => currentlyProduct.sku === updatedProduct.sku);

        if (indxOfCurrentlyRef >= 0) {
            Vue.set(state.bucketProducts, indxOfCurrentlyRef, {
                ...ProductDataFactory.refineProduct(updatedProduct)
            });
        }
    }
}

// getters
const getters = {
    ...BucketCore.getters,
    ...BucketDashboard.getters,
    dataSourceExport: (state) => state.dataSourceExport,
    importPlugIn: (state) => (pluginType) => {
        return Array.isArray(state.bucketInfo.sources) && state.bucketInfo.sources.find(s => s.type === pluginType)
    },
    platformsOf: (state) => {
        return state.datasources.length > 0 && state.datasources[0]?.["platforms"] ?
            state.datasources[0]["platforms"]
            : [];
    },
    hasProductSuiteComplete: (state) => (skuArrays) => {
        return skuArrays.some(sku => {
            return state.bucketProducts.find(product => product.sku == sku)?.productType !== 'uno' || false;
        })
    },
    hasProductDuplicateComplete: (state) => (skuArrays) => {
        return skuArrays.some(sku => {
            return state.bucketProducts.find(product => product.sku === sku)?.productType !== 'uno' || false;
        })
    },
    hasProductForTrading: (state) => (skuArrays) => {
        return skuArrays.some(sku => {
            return state.bucketProducts.find(product => product.sku == sku)?.url?.length > 10
                && state.bucketProducts.find(product => product.sku == sku)?.hasMinPrice;
        })
    },
    frozenSnapshot: (state) => state.frozenSnapshot,
    productCount: (state) => state.productCount
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}