const BasicModelStruct = {
    sku: undefined,
    exportSku: undefined,
    brand: undefined,
    model: undefined,
    productType: 'uno',
    connected: undefined,
    datasource: undefined,
    source: undefined,
    sourceId: undefined,
    rootCategory: undefined,
    category: undefined,
    categories: [],
    description: undefined,
    url: undefined,
    available: true,
    archived: false,
    enabledKaspi: true,
    enabledForte: false,
    enabledHalyk: false,
    enabledSatu: false,
    enabledJmart: false,
    enabledOzon: false,
    allowModify: true,
    kaspiExtraSkus: [],
    satuProductVariants: [],
    satuGroupId: undefined,
}

const PricesModelStruct = {
    dealerPrice: undefined,
    rrPrice: undefined,
    margin: undefined,
    price: undefined,
    minPrice: undefined,
    minProfitPercent: undefined,
    discountPercent: undefined,
    marginPercent: undefined,
    kaspiFeePercent: undefined,
    priceOzon: undefined,
    priceForte: undefined,
    priceHalyk: undefined,
    priceJmart: undefined,
    marginHalyk: undefined,
    marginForte: undefined,
    marginJmart: undefined,
    prices: [],
    kaspiTrading: undefined,
}

const InventoriesModelStruct = {
    productParts: [],
    images: [],
    outlets: undefined,
    kaspiPp: {},
    reservations: [],
    params: [],
    history: {},
}

class CostEstimatePrototype {
    static pureClone() {
        return {
            computed: {
                platformProduct: undefined,
                estimated: undefined,
                recommended: [],
                trading: undefined,
            },
            sys: {
                taskQueue: []
            }
        }
    }
}

class ProductFormPrototype {
    static pureClone() {
        return {
            basic: {
                ...BasicModelStruct
            },
            trading: {
                ...PricesModelStruct
            },
            inventories: {
                ...InventoriesModelStruct
            }
        };
    }
    static copyMemoryBlock(prop, ModelSegment) {
        let clonedMemoryBlock;
        if (Array.isArray(ModelSegment[prop])) {
            clonedMemoryBlock = [...ModelSegment[prop]]
        }
        else if (ModelSegment[prop] !== null && typeof ModelSegment[prop] === 'object') {
            clonedMemoryBlock = { ...ModelSegment[prop] }
        }
        else {
            clonedMemoryBlock = ModelSegment[prop]
        }
        return clonedMemoryBlock;
    }
    static destruct(ProductUITemplate) {
        const model = Object.create({});
        for (const hierarchyComponent in ProductUITemplate) {
            for (const prop in ProductUITemplate[hierarchyComponent]) {
                model[prop] = ProductFormPrototype.copyMemoryBlock(
                    prop, ProductUITemplate[hierarchyComponent]
                )
            }
        }
        return model;
    }
}

module.exports = {
    ProductFormPrototype,
    CostEstimatePrototype
}