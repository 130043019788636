<template>
  <div id="auth">
    <notification />
    <v-main>
      <v-container class="void auth-grid" fluid grid-list-xl>
        <router-view />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Notification from "../components/notification/Notification";
export default {
  name: "AuthenticationLayout",
  components: { Notification },
};
</script>

<style>
.auth-grid {
  background-color: #f2f4f5 !important;
  width: 100%;
  height: 100%;

  padding-top: 80px !important;
}

@media only screen and (max-width: 600px) {
  .auth-grid {
    padding-top: 0px !important;
  }
}
</style>