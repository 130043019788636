import oneCIcon from '@/assets/img/brands/1c_logo.png';
import MoyskladIcon from '@/assets/img/brands/moysklad_logo.png';
import ProSkladIcon from '@/assets/img/brands/prosklad_logo.png';

export const INTEGRATION_BUILDER_KEY = 'IntegrationBuilderKey';

export const INTEGRATIONS_TYPE = [
  {
    name: '1C',
    id: 'mryml',
    icon: oneCIcon,
    isDisabled: false,
  },
  {
    name: 'Moysklad',
    id: 'moysklad',
    icon: MoyskladIcon,
    isDisabled: true,
  },
  {
    name: 'ProSklad',
    id: 'prosklad',
    icon: ProSkladIcon,
    isDisabled: true,
  },
]
