import ServiceFacade from "@/services/ServiceFacade";
import {
    notificatonStatuses, loadingStates
} from "@/enumerations";

const state = () => ({
    kaspi: {
        categoriesTree: []
    }
});

const actions = {
    async fetchCategoriesTree({ commit }) {     
        try {
            const { status, data } = await ServiceFacade.fetchCategoriesTree();
            if (status == 200) {
                commit('SET_KASPI_CATEGORIES_TREE', data);
            }
        } catch (error) {
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: error.message || "Error"
            }, { root: true })
            commit('core/SET_LOADING_STATUS', loadingStates.ERROR, { root: true });
        }
    },
    async fetchSellerCategoriesTree({ commit }) {     
        try {
            const { status, data } = await ServiceFacade.fetchSellerCategoriesTree();
            if (status == 200) {
                commit('SET_KASPI_CATEGORIES_TREE', data);
            }
        } catch (error) {
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: error.message || "Error"
            }, { root: true })
            commit('core/SET_LOADING_STATUS', loadingStates.ERROR, { root: true });
        }
    }
}

const mutations = {
    SET_KASPI_CATEGORIES_TREE(state, tree) {
        state.kaspi.categoriesTree = Object.freeze([...tree]);
    }
}

const getters = {
    kaspiCategoriesTree: (state) => state.kaspi.categoriesTree
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}