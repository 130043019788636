import {
    notificatonStatuses
} from "@/enumerations";
import { getField, updateField } from 'vuex-map-fields';
import ServiceFacade from "@/services/ServiceFacade";

const state = () => ({
    platformsIntegrationServiceModal: {
        dialog: false,
        syncActive: false,
        fetching: false,
        hasErrors: false,
        sources: []
    },
    platformSelectionModal: {
        dialog: false,
        radioGroups: []
    },
    serviceContentViewModal: {
        dialog: false
    },
    specCreationServiceModal: {
        dialog: false
    },
    mobileProductFiltersModal: {
        dialog: false
    },
    carouselViewModal: {
        dialog: false,
        imageCollection: []
    },
    dataExpertPricesModal: {
        dialog: false
    },
    orderConfirmationModal: {
        dialog: false,
        action: "",
        order: null,
        reason: { desc: "Отказ покупателя", code: "BUYER_CANCELLATION_BY_MERCHANT" },
        comment: "",
    },
    graphCategoriesModal: {
        dialog: false,
        treeViewOptions: {
            category: null,
            categories: [],
            rootCategory: null
        }
    },
    tagPopoverModal: {
        newTags: [],
        rewriteTags: false
    }
});

const actions = {
    async fetchPlatformIntegrations({ commit }) {
        commit('CHANGE_PLATFORMS_INTEGRATION_COMPONENT', {
            field: "fetching",
            value: true
        });

        try {
            const { status, data } = await ServiceFacade.fetchPlatformIntegrations();
            if (status == 200 && Array.isArray(data)) {
                commit('CHANGE_PLATFORMS_INTEGRATION_COMPONENT', {
                    field: "sources",
                    value: [...data]
                });
            }
        } catch (error) {
            commit('CHANGE_PLATFORMS_INTEGRATION_COMPONENT', {
                field: "hasErrors",
                value: true
            });
        } finally {
            commit('CHANGE_PLATFORMS_INTEGRATION_COMPONENT', {
                field: "fetching",
                value: false
            });
        }
    },
    async callPlatformIntegrationWebhook({ commit, dispatch }, SourceID) {
        commit('CHANGE_PLATFORMS_INTEGRATION_COMPONENT', {
            field: "syncActive",
            value: true
        });

        try {
            const { status, data } = await ServiceFacade.callPlatformIntegrationWebhook(SourceID);
            if (status == 200 && data?.success == true) {
                dispatch('fetchPlatformIntegrations');
                commit('core/SET_NOTIFICATION', {
                    status: notificatonStatuses.success,
                    message: `Синхронизация для платформы «${SourceID}» прошла успешно!`
                }, { root: true });
            } else {
                commit('CHANGE_PLATFORMS_INTEGRATION_COMPONENT', {
                    field: "hasErrors",
                    value: true
                });
            }
        } catch (error) {
            commit('CHANGE_PLATFORMS_INTEGRATION_COMPONENT', {
                field: "hasErrors",
                value: true
            });
        } finally {
            commit('CHANGE_PLATFORMS_INTEGRATION_COMPONENT', {
                field: "syncActive",
                value: false
            });
        }
    }
}

// mutations
const mutations = {
    updateField,
    DISPLAY_SERVICE_CONTENT_MODAL(state) {
        state.serviceContentViewModal.dialog = true;
    },
    DISPLAY_PLATFORM_SELECTION_MODAL(state) {
        state.platformSelectionModal.dialog = true;
    },
    DISPLAY_SPEC_CREATION_MODAL(state) {
        state.specCreationServiceModal.dialog = true;
    },
    CLOSE_SPEC_CREATION_MODAL(state) {
        state.specCreationServiceModal.dialog = false;
    },
    DISPLAY_MOBILE_PRODUCTS_FILTER_MODAL(state) {
        state.mobileProductFiltersModal.dialog = true;
    },
    CLOSE_MOBILE_PRODUCTS_FILTER_MODAL(state) {
        state.mobileProductFiltersModal.dialog = false;
    },
    OPEN_CAROUSEL_MODAL(state, { imageCollection }) {
        state.carouselViewModal.dialog = true;
        state.carouselViewModal.imageCollection = [...imageCollection];
    },
    CLOSE_CAROUSEL_MODAL(state) {
        state.carouselViewModal.dialog = false;
        state.carouselViewModal.imageCollection = [];
    },
    DISPLAY_PLATFORMS_INTEGRATION_MODAL(state) {
        state.platformsIntegrationServiceModal.dialog = true;
    },
    CLOSE_PLATFORMS_INTEGRATION_MODAL(state) {
        state.platformsIntegrationServiceModal = {
            dialog: false,
            syncActive: false,
            fetching: false,
            hasErrors: false,
            sources: []
        }
    },
    CHANGE_PLATFORMS_INTEGRATION_COMPONENT(state, { field, value }) {
        state.platformsIntegrationServiceModal[field] = value;
    },
    DISPLAY_DATAEXPERT_PRICES_MODAL(state) {
        state.dataExpertPricesModal.dialog = true;
    },
    CLOSE_DATAEXPERT_PRICES_MODAL(state) {
        state.dataExpertPricesModal.dialog = false;
    },
    DISPLAY_ORDER_CONFIRMATION_MODAL(state, {action, order}) {
        state.orderConfirmationModal = {
            dialog: true,
            action,
            order,
            reason: { desc: "Отказ покупателя", code: "BUYER_CANCELLATION_BY_MERCHANT" },
            comment: "",
        };
    },
    CLOSE_ORDER_CONFIRMATION_MODAL(state) {
        state.orderConfirmationModal = {
            dialog: false,
            action: "",
            order: null,
            reason: { desc: "Отказ покупателя", code: "BUYER_CANCELLATION_BY_MERCHANT" },
            comment: "",
        }
    },
    OPEN_GRAPH_CATEGORIES_MODAL(state) {
        state.graphCategoriesModal.dialog = true;
    }
}

// getters
const getters = {
    specCreationServiceModal: (state) => state.specCreationServiceModal,
    mobileProductFiltersModal: (state) => state.mobileProductFiltersModal,
    platformsIntegrationServiceModal: (state) => state.platformsIntegrationServiceModal,
    platformSelectionModal: (state) => state.platformSelectionModal,
    carouselViewModal: (state) => state.carouselViewModal,
    dataExpertPricesModal: (state) => state.dataExpertPricesModal,
    orderConfirmationModal: (state) => state.orderConfirmationModal,
    graphCategoriesModal: (state) => state.graphCategoriesModal,
    tagPopoverModal: (state) => state.tagPopoverModal,
    getField
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}