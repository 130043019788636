<script>
import { INTEGRATION_KEY } from './constants';
import HeaderMenu from './HeaderMenu';
import FormatMixin from '@/mixins/Format';

export default {
  components: {
    HeaderMenu
  },
  mixins: [FormatMixin],
  inject: [INTEGRATION_KEY],
  computed: {
    integration() {
      return this[INTEGRATION_KEY];
    },
    statusTitle() {
      switch(this.integration.lastImport.status) {
        case 'SUCCESS': return this.integration.lastImport.statusMessage;
        case 'ERROR': return this.integration.lastImport.statusMessage;
        default: return this.$t('label.noData');
      }
    }
  },
  methods: {
    makeImport() {
      this.$store.dispatch(
        "serviceModals/callPlatformIntegrationWebhook",
        this.integration.id
      );
    },
  }
}
</script>

<template>
  <v-card-title class="pa-7 align-start">
    <div class="d-flex flex-column">
      <span class="text-h5">
        {{ $t('integration.card.header.title', {name: integration.name}) }}
      </span>
      <span class="caption">
        {{ $t('integration.card.header.lastImport', {date: toDateFormat(integration.lastImport.dateUpdated)}) }}
      </span>
    </div>

    <v-tooltip bottom>
      <template v-slot:activator="{on, attrs}">
        <div class="mx-4" v-on="on" v-bind="attrs">
          <v-icon v-if="!integration.lastImport.status" color="grey">mdi-check-bold</v-icon>
          <v-icon v-if="integration.lastImport.status==='SUCCESS'" color="green">mdi-check-bold</v-icon>
          <v-icon v-if="integration.lastImport.status==='ERROR'" color="red">mdi-close-thick</v-icon>
        </div>
      </template>
      
      {{ statusTitle }}
    </v-tooltip>

    <v-spacer />
    <v-btn
      class="figma-btn white--text mr-4"
      color="primary"
      @click="makeImport"
    >{{ $t('integration.card.button.importNow') }}</v-btn>

    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon color="black">mdi-menu</v-icon>
        </v-btn>
      </template>

      <HeaderMenu />
    </v-menu>
  </v-card-title>
</template>
