import Vue from 'vue';
import ProductFormHelper from "@/helpers/ProductFormHelper";
import HashGenerator from "@/helpers/HashGenerator";
import { getField, updateField } from 'vuex-map-fields';

const state = () => ({
    productCreationWizard: {
        dialog: false,
        sku: null,
        model: null,
        brand: null,
        price: null,
        dealerPrice: null,
        margin: null,
        outlets: []
    },
    priceListWizard: {
        dialog: false,
        resetReservations: true,
        leftoversDataset: [],
        importResult: {
            success: false,
            processed: undefined,
            failed: undefined,
            errors: undefined
        }
    },
    productPublishingWizard: {
        dialog: false,
        fileAttachment: null,
        xlsSheet: null,
        xlsProducts: null,
        importResult: {
            success: false,
            loadedItems: 0
        }
    },
    productCompositeWizard: {
        dialog: false,
        productKit: {
            brand: { v: "", lock: false },
            model: { v: "", lock: true },
            dealerPrice: { v: 0, lock: true },
            price: { v: 0, lock: true },
            sku: { v: "", lock: true },
            inventory: []
        }
    },
    productDuplicateWizard: {
        dialog: false,
        data: [],
        syncFields: {
            prices: true,
            outlets: true
        }
    }
})

const actions = {
    openProductCompositeWizard({ commit, rootGetters }, selectedSKUs = []) {
        const bucketProducts = rootGetters["datasources/bucketProducts"];
        const recentlyCreatedProducts = rootGetters["eventBus/recentlyCreatedProducts"];
        const mergedProductSet = [
            ...recentlyCreatedProducts,
            ...bucketProducts
        ];

        for (const sku of selectedSKUs) {
            let cursor = mergedProductSet.find(item => item.sku === sku);
            if (cursor) {
                const {
                    sku,
                    quantity,
                    model,
                    brand,
                    images,
                    exportPrice,
                    exportPriceKaspi,
                    dealerPrice,
                    outlets
                } = cursor;
                commit('PUSH_ACCESSOR_TO_COMPOSITE_INVENTORY', {
                    sku,
                    quantity,
                    model,
                    brand,
                    avatar: (Array.isArray(images) && images[0] && images[0].small),
                    cover: (Array.isArray(images) && images[0] && images[0].big),
                    exportPrice,
                    exportPriceKaspi,
                    dealerPrice,
                    outlets
                })
            }
        }
        commit('DISPLAY_COMPOSITE_WIZARD');
    },
    openProductDuplicateWizard({ commit, rootGetters }, selectedSKUs = []) {
        const bucketProducts = rootGetters["datasources/bucketProducts"];
        const recentlyCreatedProducts = rootGetters["eventBus/recentlyCreatedProducts"];
        const mergedProductSet = [
            ...recentlyCreatedProducts,
            ...bucketProducts
        ];



        for (const sku of selectedSKUs) {
            let cursor = mergedProductSet.find(item => item.sku === sku);
            if (cursor) {
                const {
                    sku,
                    quantity,
                    model,
                    brand,
                    images,
                    exportPrice,
                    exportPriceKaspi,
                    dealerPrice,
                    outlets
                } = cursor;
                commit('PUSH_ACCESSOR_TO_DUPLICATE_INVENTORY', {
                    sku,
                    parentSku: sku,
                    quantity,
                    model,
                    brand,
                    avatar: (Array.isArray(images) && images[0] && images[0].small),
                    cover: (Array.isArray(images) && images[0] && images[0].big),
                    exportPrice,
                    exportPriceKaspi,
                    dealerPrice,
                    outlets
                })
            }
        }
        commit('DISPLAY_DUPLICATE_WIZARD');
    },
    openProductCreationWizard({ commit, rootGetters }) {
        commit('MUTATE_PRODUCT_CREATION_ELEMENT', {
            model: 'sku',
            value: HashGenerator.genSixSymbolCode()
        });
        commit('SET_OUTLETS', ProductFormHelper
            .generateOutlets(
                rootGetters["profile/company"].outlets
            )
        );
        commit('DISPLAY_PRODUCT_CREATION_WIZARD');
    },
    closeProductCreationWizard({ commit }) {
        commit('CLOSE_PRODUCT_CREATION_WIZARD');
    },
    openPriceListWizard({ commit }) {
        commit('TOGGLE_PRICELIST_WIZARD', true);
    },
    closePriceListWizard({ commit }) {
        commit('TOGGLE_PRICELIST_WIZARD', false);
        commit('RESET_PRICELIST_WIZARD', {
            dialog: false,
            resetReservations: true,
            leftoversDataset: [],
            importResult: {
                success: false,
                processed: undefined,
                failed: undefined,
                errors: undefined
            }
        });
    },
    openProductPublishingWizard({ commit }) {
        commit('TOGGLE_PRODUCT_PUBLISHING_WIZARD', true);
    },
    closeProductPublishingWizard({ commit, state }) {
        commit('TOGGLE_PRODUCT_PUBLISHING_WIZARD', false);
        commit('RESET_PRODUCT_PUBLISHING_WIZARD', {
            dialog: state.productPublishingWizard.dialog,
            fileAttachment: null,
            xlsSheet: null,
            xlsProducts: null,
            importResult: {
                success: false,
                loadedItems: 0
            }
        });
    }
}




// mutations
const mutations = {
    updateField,
    /****          PRODUCT COMPOSITE WIZARD          ****/
    DISPLAY_COMPOSITE_WIZARD(state) {
        state.productCompositeWizard.dialog = true;
    },
    PUSH_ACCESSOR_TO_COMPOSITE_INVENTORY(state, item) {
        state.productCompositeWizard.productKit.inventory.push(item);
    },

    /****          PRODUCT DUPLICATE WIZARD          ****/
    DISPLAY_DUPLICATE_WIZARD(state) {
        state.productDuplicateWizard.dialog = true;
    },
    PUSH_ACCESSOR_TO_DUPLICATE_INVENTORY(state, item) {
        state.productDuplicateWizard.data.push(item)
    },

    /****          PRODUCT CREATION WIZARD          ****/
    DISPLAY_PRODUCT_CREATION_WIZARD(state) {
        state.productCreationWizard.dialog = true;
    },
    CLOSE_PRODUCT_CREATION_WIZARD(state) {
        state.productCreationWizard = {
            dialog: false,
            sku: null,
            model: null,
            brand: null,
            price: null,
            dealerPrice: null,
            margin: null,
            outlets: []
        }
    },
    MUTATE_PRODUCT_CREATION_ELEMENT(state, data) {
        const { model, value } = data;
        if (model === 'outlets') {
            const { id, k, v } = value;
            const mutatedOutlets = { ...state.productCreationWizard.outlets[id] };
            mutatedOutlets[k] = v;
            Vue.set(state.productCreationWizard.outlets, id, mutatedOutlets);
        } else {
            state.productCreationWizard[model] = value;
        }
    },
    SET_OUTLETS(state, struct) {
        state.productCreationWizard.outlets = { ...struct };
    },
    /****          PRICELIST WIZARD          ****/
    TOGGLE_PRICELIST_WIZARD(state, dialog) {
        state.priceListWizard.dialog = dialog;
    },
    RESET_PRICELIST_WIZARD(state, form) {
        state.priceListWizard = { ...form };
    },
    RESET_RESERVATIONS_CHECKBOX(state, flag) {
        state.priceListWizard.resetReservations = flag;
    },
    MUTATE_LEFTOVERS_STRUCTURE(state, leftoversDataset) {
        state.priceListWizard.leftoversDataset = [...leftoversDataset];
    },
    SET_PRICELIST_IMPORT_RESULT(state, result) {
        state.priceListWizard.importResult = result;
    },
    /****          PRODUCT PUBLISHING WIZARD          ****/
    TOGGLE_PRODUCT_PUBLISHING_WIZARD(state, dialog) {
        state.productPublishingWizard.dialog = dialog;
    },
    RESET_PRODUCT_PUBLISHING_WIZARD(state, form) {
        state.productPublishingWizard = { ...form };
    },
    SET_FILE_ATTACHMENT(state, attachment) {
        state.productPublishingWizard.fileAttachment = attachment;
    },
    SET_XLS_SHEET(state, sheet) {
        state.productPublishingWizard.xlsSheet = sheet;
    },
    SET_PRODUCTS(state, products) {
        state.productPublishingWizard.xlsProducts = products;
    },
    SET_XLS_PUBLISHING_RESULT(state, result) {
        state.productPublishingWizard.importResult = result;
    }
}

// getters
const getters = {
    productCompositeWizard: (state) => state.productCompositeWizard,
    productDuplicateWizard: (state) => state.productDuplicateWizard,
    productCreationWizard: (state) => state.productCreationWizard,
    priceListWizard: (state) => state.priceListWizard,
    productPublishingWizard: (state) => state.productPublishingWizard,
    allStepWizardsAreClosed: (state) => {
        return !state.productCreationWizard.dialog &&
            !state.priceListWizard.dialog &&
            !state.productPublishingWizard.dialog &&
            !state.productCompositeWizard.dialog &&
            !state.productDuplicateWizard.dialog
    },
    getField
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}