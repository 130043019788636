<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    offset-y
    min-width="325"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="slotActivator == 'icon'"
        icon
        v-bind="attrs"
        v-on="on"
        small
        :style="!display && 'display: none'"
      >
        <v-icon size="20">mdi-tag-multiple-outline</v-icon>
      </v-btn>
      <v-btn
        v-else
        v-bind="attrs"
        v-on="on"
        height="48"
        :color="colors.fauxCobalt"
        text
        class="v-custom-btn"
      >
        <v-icon left>mdi-tag-multiple-outline</v-icon>
        <span class="tags-button-label">Метки</span>
      </v-btn>
    </template>

    <v-card
      v-if="!reveal"
      elevation="3"
      :height="calculateListHeight"
      id="tag-list__card"
      max-width="325"
    >
      <div class="d-flex pr-3 py-2">
        <v-list-item-title class="text-center">Метки</v-list-item-title>
        <v-btn
          class="ml-auto"
          icon
          @click="menu = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <v-card-text id="tag-list__card__content">
        <template>
          <!-- MY TAGS -->
          <template v-if="activeTags.length && !multiselect">
            <v-list-item class="void">
              <v-list-item-content>
                <v-list-item-subtitle>Мои метки</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list>
              <v-list-item
                v-for="(tag, index) in activeTags"
                :key="index"
                class="void"
              >
                <v-card
                  :color="tag.color"
                  height="36"
                  width="97%"
                  flat
                  hover
                  rounded
                  ripple
                >
                  <v-card-text class="tag-label px-4 py-2">
                  {{ tag.name }}</v-card-text>
                </v-card>
                <v-btn
                  icon
                  small
                  class="tag-editor"
                  @click="switchToTagForm(modes.UPDATE, tag)"
                  :disabled="!swatches.some((sw) => sw == tag.color)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-list-item>
            </v-list>
          </template>

          <!-- RECOMMENDATIONS -->
          <div class="d-flex py-2">
            <v-list-item-subtitle >{{
                recommendedTags.length ? "Рекомендуемые" : "Нет доступных меток"
              }}</v-list-item-subtitle>
            <v-btn
                v-if="withConfirmation"
                class="ml-auto"
                icon
                small
                @click="switchToTagForm(modes.CREATE)"
              >
            <v-icon>mdi-tag-plus-outline</v-icon>
          </v-btn>
          </div>
          

          <v-list>
            <v-list-item
              v-for="(tag, index) in recommendedTags"
              :key="index"
              class="void"
            >
              
              <v-card
                :color="tag.color"
                :hover="!multiselect"
                height="36"
                width="97%"
                flat
                rounded
                ripple
                @click="!multiselect && connectProductTag(tag.name)"
                class="d-flex px-2 "
              >
                <v-checkbox
                v-if="multiselect"
                :value="tag"
                v-model="tagPopoverModal.newTags"
                hide-details
                dark
                class="ma-0"
                dense
              ></v-checkbox>
                <v-card-text class="tag-label py-2">
                {{ tag.name }}</v-card-text>
              </v-card>
              <v-btn
                icon
                small
                class="tag-editor"
                @click="switchToTagForm(modes.UPDATE, tag)"
                :disabled="!swatches.some((sw) => sw == tag.color)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
        </template>
      </v-card-text>

      <v-card-actions style="height: auto" class="py-2">
        <v-flex class="void">
          <template v-if="withConfirmation">
            <v-row justify="center">
              <v-checkbox
                v-model="tagPopoverModal.rewriteTags"
                label="Перезаписать текущие метки"
                hide-details
                color="primary"
                class="mb-2"
              ></v-checkbox>
            </v-row>
            <v-row justify="center">
              <v-btn class="text-center" color="primary" @click="connectMultiTags"> Применить </v-btn>
            </v-row>
          </template>
          <v-btn
            v-if="!withConfirmation"
            class="mt-2"
            text
            width="100%"
            @click="switchToTagForm(modes.CREATE)"
            >Создать новую метку</v-btn
          >
        </v-flex>
      </v-card-actions>
    </v-card>

    <!-- FORM UI -->
    <v-expand-transition>
      <v-card
        v-if="reveal"
        class="transition-fast-in-fast-out v-card--reveal"
        style="height: 100%"
        max-width="325"
      >
        <v-list>
          <v-list-item>
            <v-list-item-content class="text-center">
              <v-list-item-title>Создание метки</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-card-text class="pb-0">
          <v-text-field
            v-model="tag.name"
            label="Наименование"
            outlined
            dense
          ></v-text-field>
          <span class="mt-6">Цвет</span>
          <v-row class="void mt-2 mb-4">
            <v-card
              v-for="c in swatches"
              :key="c"
              width="48"
              height="32"
              class="mr-2 mt-2"
              :color="c"
              hover
              ripple
              @click="setTagColor(c)"
            >
              <span v-if="tag.color == c" class="card-label-color-select-icon">
                <v-icon color="white" size="16">mdi-check-bold</v-icon>
              </span>
            </v-card>
          </v-row>
        </v-card-text>

        <v-card-actions class="pt-3">
          <v-btn
            text
            color="teal accent-4"
            @click="reveal = false"
            v-show="!loading"
          >
            Назад
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="showSaveButton"
            text
            color="teal accent-4"
            @click="saveProductTag"
            :loading="loading"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
  </v-menu>
</template>

<script>
import ColorPaletteMixin from "@/mixins/ColorPalette";
import { mapFields } from "vuex-map-fields";

export default {
  name: "TagPopoverMenu",
  mixins: [ColorPaletteMixin],
  props: {
    activeTags: Array,
    recommendedTags: Array,
    createProductTagFunc: Function,
    updateProductTagFunc: Function,
    slotActivator: {
      type: String,
      default: "icon",
    },
    display: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    withConfirmation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      reveal: false,
      tag: {
        name: "",
        color: "#75140C",
      },
      tempTag: {
        name: "",
        color: "",
      },
      swatches: [
        "#75140C",
        "#D22D1F",
        "#EB5281",
        "#E439E3",
        "#925EB1",
        "#0737DF",
        "#4781B2",
        "#4AA7EE",
        "#57B99D",
        "#A87F3D",
        "#EE7F31",
        "#697683",
      ],
      modes: {
        REVIEW: 0x00,
        CREATE: 0xaa,
        UPDATE: 0xbb,
      },
      currentMode: null,
      loading: false,
    };
  },
  computed: {
    ...mapFields("serviceModals", ["tagPopoverModal"]),
    showSaveButton() {
      if (this.currentMode == this.modes.CREATE) {
        return this.tag.name !== "";
      }
      if (this.currentMode == this.modes.UPDATE) {
        return (
          this.tag.name !== this.tempTag.name ||
          this.tag.color !== this.tempTag.color
        );
      }
      return false;
    },
    calculateListHeight() {
      if (this.withConfirmation) {
        return 650;
      }
      const itemHeight = 150;
      const listFooterHeight = 250;
      let height = this.activeTags.length * itemHeight;
      return Math.min(Math.max(height, itemHeight), 400) + listFooterHeight;
    },
  },
  watch: {
    menu(display) {
      if (display) {
        if (this.tagPopoverModal.newTags.length > 0) {
          this.tagPopoverModal.newTags = [];
        }
        if (this.tagPopoverModal.rewriteTags) {
          this.tagPopoverModal.rewriteTags = !this.tagPopoverModal.rewriteTags;
        }
      }
    },
    reveal(isReveal) {
      if (!isReveal) {
        this.currentMode = this.modes.REVIEW;
      }
    },
  },
  methods: {
    switchToTagForm(mode, tagModel = null) {
      if (mode == this.modes.CREATE) {
        this.setTagName("");
        this.setTagColor("#75140C");
      }

      if (mode == this.modes.UPDATE) {
        if (typeof tagModel !== "object") {
          return false;
        }
        const { name, color } = tagModel;
        this.setTagName(name);
        this.setTagColor(color);
        this.tempTag = { ...tagModel };
      }

      this.currentMode = mode;
      this.reveal = true;
    },
    setTagName(name) {
      this.tag.name = name;
    },
    setTagColor(color) {
      this.tag.color = color;
    },
    connectProductTag(tag) {
      this.$emit("connectProductTag", tag);
      this.menu = false;
    },
    connectMultiTags() {
      this.menu = false;
      this.$emit("connectMultiTags", this.tagPopoverModal.newTags.length);
    },
    async saveProductTag() {
      const { name, color } = this.tag;
      if (name && color) {
        this.loading = !this.loading;
        if (this.currentMode == this.modes.CREATE) {
          await this.createProductTagFunc({ name, color });
        }
        if (this.currentMode == this.modes.UPDATE) {
          await this.updateProductTagFunc({
            newTag: { name, color },
            oldTag: { ...this.tempTag },
          });
          this.tempTag = {
            name: "",
            color: "",
          };
        }
        this.loading = !this.loading;
        this.reveal = !this.reveal;
      }
    },
  },
  mounted() {
    return (this.currentMode = this.modes.REVIEW);
  },
};
</script>

<style scoped>
#tag-list__card {
  display: flex !important;
  flex-direction: column;
}
#tag-list__card > #tag-list__card__content {
  flex-grow: 1;
  overflow: auto;
}
.tag-label {
  font-style: normal !important;
  font-size: 15px !important;
  font-weight: 600;
  color: #ffffff !important;
}
.tag-editor {
  margin-left: 7px;
}
.card-label-color-select-icon {
  left: 16px;
  position: absolute;
  top: 6px;
}
.v-input--selection-controls__ripple {
  margin: 0 7px 7px 7px;
}
.tags-button-label {
  text-transform: none;
}
</style>