import { render, staticRenderFns } from "./DataParams.form.vue?vue&type=template&id=62df5918"
import script from "./DataParams.form.vue?vue&type=script&lang=js"
export * from "./DataParams.form.vue?vue&type=script&lang=js"
import style0 from "./DataParams.form.vue?vue&type=style&index=0&id=62df5918&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports