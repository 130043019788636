import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import i18n from "./plugins/i18n";
import { alarmTypes } from "./enumerations";
import TimeoutGenerator from "./helpers/TimeoutGenerator";
import SnapshotCache from "./models/SnapshotCache";
import FirebaseAuth from "./interfaces/FirebaseAuth";
import ServiceFacade from "./services/ServiceFacade";
import BasicLayout from "./layouts/BasicLayout.vue";
import AuthenticationLayout from './layouts/AuthenticationLayout.vue'
import EasyLayout from "./layouts/EasyLayout.vue";
import DashboardLayout from './layouts/DashboardLayout.vue';
import VueClipboard from 'vue-clipboard2'
import LoadScript from 'vue-plugin-load-script';
import YmapPlugin from 'vue-yandex-maps'
import VueConfetti from 'vue-confetti'

Vue.use(LoadScript);
Vue.loadScript("//widget.cloudpayments.ru/bundles/cloudpayments.js");

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(YmapPlugin)
Vue.use(VueConfetti)

let app;

const bin = {
  /*** Application build ***/
  createApplication() {
    Vue.component('easy-layout', EasyLayout)
    Vue.component('dashboard-layout', DashboardLayout)
    Vue.component('authentication-layout', AuthenticationLayout)
    Vue.component('basic-layout', BasicLayout)
    Vue.config.productionTip = false

    app = new Vue({
      store,
      router,
      vuetify,
      i18n,
      render: h => h(App)
    })
      .$mount('#app')
  },
  login(expirationDate) {
    return store.dispatch("profile/loginFirebase", expirationDate);
  },
  logout() {
    return Promise.all([
      store.dispatch("profile/destroyProfile"),
      store.dispatch("datasources/forgetDatasourceExportSettings"),
      store.dispatch("orders/resetSandbox"),
      store.dispatch("eventBus/resetSandbox")
    ]);
  },
  prepareProfileSetup() {
    return store.dispatch("profile/profileSetupTrigger");
  },
  orderSync(background) {
    const { loggedIn } = store.state.profile.firebaseAuth;
    const ordersLocked = store.state.orders.locked;

    if (loggedIn && !ordersLocked && navigator.onLine) {
      store.dispatch("orders/fetchOrders", background);
      ServiceFacade.activateAlarm(alarmTypes.ORDERS_SYNC);
    }
  },
  clearLocalStorageSettings() {
    setTimeout(() => {
      localStorage.getItem("last_saved_filters") && localStorage.removeItem('last_saved_filters');
      localStorage.getItem("notify_last_shutdown_time") && localStorage.removeItem('notify_last_shutdown_time');
      SnapshotCache.removeAllSnapshots();
    }, 500);
  },
  async onNetworkChange(event) {
    console.log(event.detail)
    if (event.detail && event.detail.online) {
      const { loggedIn } = store.state.profile.firebaseAuth;
      const lastOrdersSyncDate = store.state.orders.lastFetchDate;

      if (loggedIn && lastOrdersSyncDate) {
        if (Date.now() - lastOrdersSyncDate.getTime() >= 5 * 60 * 1000) {
          this.orderSync(true);
        }
      }
    }
  },
  onSessionDrop() {
    const { loggedIn } = store.state.profile.firebaseAuth;
    if (loggedIn) {
      store.dispatch("core/setAlertNotification",
        "Сеанс потерян! Вы перенаправляетесь на страницу Входа!",
        { root: true });
      TimeoutGenerator.timeWait(2000).then(() => { FirebaseAuth.signOut() });
    }
  },
  onSessionRecovery() {
    const { loggedIn } = store.state.profile.firebaseAuth;
    if (loggedIn) {
      store.dispatch("core/setWarnNotification",
        "Ваша сессия автоматически восстановлена!",
        { root: true });
    }
  }
}

FirebaseAuth.subscribe({
  async authChanged(
    loggedInFirebase = false,
    expirationDate = null,
    IsNewProfile = false,
    isNewSession = false
  ) {
    const { loggedIn } = store.state.profile.firebaseAuth;

    if (loggedInFirebase && !loggedIn) {
      await bin.login(expirationDate);

      if (IsNewProfile) {    // On register a new profile
        await bin.prepareProfileSetup();
        router.replace("/company/setup");
      }
      else if (isNewSession) {
        router.replace("/products");
      }
    }
    else if (!loggedInFirebase) {
      await bin.logout();
      bin.clearLocalStorageSettings();
      app && await router.push("/auth");
    }
    if (!app) {
      bin.createApplication();
    }
  }
});

FirebaseAuth.setup();

window.addEventListener('network_change', bin.onNetworkChange.bind(bin));
window.addEventListener('unauthorized@recovery', bin.onSessionRecovery.bind(bin));
window.addEventListener('unauthorized@drop', bin.onSessionDrop.bind(bin));
window.addEventListener('news@sync', () => {
  store.dispatch("profile/fetchLastNotifications");
})
window.addEventListener('order@sync', bin.orderSync.bind(bin, false));
window.addEventListener('order@backgroundsync', bin.orderSync.bind(bin, true));
