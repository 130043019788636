import axios from 'axios';
import store from '@/store';
import FirebaseAuth from "@/interfaces/FirebaseAuth";
import TimeoutGenerator from "@/helpers/TimeoutGenerator";

const config = {
	router: {
		backend: [
			'/platforms/kaspi',
			'/analytics',
			'/dashboard',
			'/orders',
			'/company',
			'/products/get',
			'/products/search',
			'/products/categories/get',
			'/products/history/',
			'/products/upload/images',
			'/test/fetchKaspiOrdersFromStore'
		],
		exclusionsMask: [
			'/connect',
			'/disconnect',
			// '/products/page/',
			'/dataexpert',
			'/platforms/reports',
			'/settings/orders'
		]
	},
	vendorResources: [
		/script.google.com/m
	]
}

function getBaseUrl(path) {
	if (config.router.exclusionsMask.every(r => path.indexOf(r) === -1)) {
		if (config.router.backend.some(r => path.indexOf(r) >= 0)) {
			return process.env.VUE_APP_BACKEND_URL.concat(process.env.VUE_APP_BACKEND_ENTRY_POINT);
		}
	}
	return process.env.VUE_APP_BASE_URL.concat(process.env.VUE_APP_API_ENTRY_POINT);
}

axios.interceptors.request.use(
	async (_config) => {
		if (!config.vendorResources.every(res => res.test(_config.url))) {
			const { firebaseAuth, auth } = store.state.profile;
			const { loggedIn, expirationDate } = firebaseAuth;
			const wololoAuth = auth.accessToken;

			_config.baseURL = getBaseUrl(_config.url);
			_config.headers["Authorization"] = `Bearer ${wololoAuth}`;
			_config.headers["Access-Control-Allow-Origin"] = '*';
			_config.headers["Access-Control-Allow-Headers"] = '*';
			_config.headers["Access-Control-Allow-Credentials"] = 'true';

			if (loggedIn) {
				let millsUntilExpiration = new Date(expirationDate).getTime() - Date.now();
				if (millsUntilExpiration <= 0) {
					await TimeoutGenerator.timeWait(375);
					store.dispatch("profile/refreshExpireTime", await FirebaseAuth.getTokenExpirationDate());
					const sessionRecoveryEvent = new Event("unauthorized@recovery");
					window.dispatchEvent(sessionRecoveryEvent);
				}
				_config.headers["Authorization_local"] = `Bearer ${await FirebaseAuth.getToken()}`;
			}
		}

		return _config;
	}
)

axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		console.error(error);
		if (error.response) {
			if (error.response.status == 401) {
				const sessionDropEvent = new Event("unauthorized@drop");
				window.dispatchEvent(sessionDropEvent);
				return false;
			}
		}
		else if (error.message) {
			if (error.message === "Network Error")
				throw new Error("Нет доступа к серверу. Проверьте настройки соединения или обратитесь к технической поддержке!")
		}
		return Promise.reject(error);
	}
)

/** 
 * @returns {import('axios').AxiosInstance}
 */
export default () => { return axios };
