import CustomerManagementService from '../../../../services/CustomerManagementService';
import store from '@/store'

/**
 * 
 * @param {string} id
 * @param {{
 *  url: String,
 *  headerName: String,
 *  downloadLogin: String,
 *  downloadPassword: String,
 *  automaticImport: "NEVER" | EVERY_1_HOUR" | "EVERY_30_MIN" | "EVERY_15_MIN" | "EVERY_1_DAY"
 * }} inputDTO 
 */
export async function editSourceParameters(id, inputDTO) {
  try {
    const integration = store
      .getters['serviceModals/platformsIntegrationServiceModal']
      .sources.find(el => el.id === id);
      
    const res = await CustomerManagementService.editIntegration({
      id,
      sourceParameters: inputDTO,
      syncParameters: integration.syncParameters
    });

    integration.sourceParameters = {
      ...integration.sourceParameters,
      ...res.data.sourceParameters
    }
  } catch(error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else error;
  }
}
