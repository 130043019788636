<template>
  <div class="info-board">
    <v-row v-if="displayIcon" justify="center" align="center">
      <v-icon :size="dense ? 24 : 48">{{ displayIcon }}</v-icon>
      <span :class="['pa-2', 'info-message', dense ? 'dense' : '']">{{ message }}</span>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "VInfoBoard",
  props: {
    message: {
      type: String,
    },
    displayIcon: {
      type: String,
      default: "mdi-cancel",
    },
    dense: {
      type: Boolean
    }
  },
};
</script>

<style>
.info-board {
  margin: 5%;
  width: 90%;
  min-height: 50%;
}
</style>