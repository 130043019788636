import { glyphs } from "@/enumerations";

class HintGenerator {
    toPercent(value) {
        return value ? value.toString().concat('%') : '0%';
    }
    toKZT(money) {
        return money ? money.toString().concat(' ' + glyphs.KZT) : '0'.concat(' ' + glyphs.KZT);
    }
    forTradePrice({ brand, isCustomPrice, exportPrice, rrPrice, marginAmount, marginPercent }) {
        const overheadCostTip = isCustomPrice
            ? "Цена выставлена вручную"
            : (exportPrice !== rrPrice
                && `Накрутка: ${this.toPercent(marginPercent)}, ${this.toKZT(marginAmount)}`);

        const rrcTip = !isCustomPrice
            && exportPrice !== rrPrice
            ? `РРЦ: ${this.toKZT(rrPrice)}`
            : marginPercent === 0
                ? "РРЦ без накрутки | Накрутка выставлена 0%"
                : `РРЦ без накрутки | цена фиксирована для бренда ${brand}`;

        const totalTip = !isCustomPrice
            && exportPrice !== rrPrice
            && `${this.toKZT(rrPrice)} + ${this.toKZT(marginAmount)} = ${this.toKZT(exportPrice)}`;

        return {
            overheadCostTip,
            rrcTip,
            totalTip
        }
    }
    forProduct(product) {
        const {
            available, brand,
            isCustomPrice, exportPrice, rrPrice, marginAmount, marginPercent,
            exportPriceKaspiDetails, exportPriceForteDetails
        } = product;
        let hints = {
            availableDetail: '',
            kzDeliveryDetail: '',
            weekendDeliveryDetail: '',
            exportPriceKaspiDetails: '',
            exportPriceForteDetails: '',
            competitorDetail: '',
            priceDetails: {
                overheadCostTip: '',
                rrcTip: '',
                totalTip: ''
            }
        }
        hints.availableDetail = available ? 'В наличии' : 'Нет в наличии';
        hints.kzDeliveryDetail = 'Доставка по Казахстану / PP3';
        hints.weekendDeliveryDetail = 'Доставка в выходные';
        hints.exportPriceKaspiDetails = exportPriceKaspiDetails
            ? exportPriceKaspiDetails.replace('<br/>', ' | ') : '';
        hints.exportPriceForteDetails = exportPriceForteDetails
            ? exportPriceForteDetails.replace('<br/>', ' | ') : '';
        hints.competitorDetail = 'Текущая позиция и количество продавцов';
        hints.priceDetails = {
            ...this.forTradePrice({
                brand, isCustomPrice, exportPrice, rrPrice, marginAmount, marginPercent
            })
        };
        return hints;
    }
}

export default new HintGenerator();