var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{attrs:{"id":"drawer","app":"","absolute":"","left":"","width":"244","touchless":_vm.touchless,"temporary":_vm.temporary,"mini-variant-width":_vm.navBar.miniWidth,"mini-variant":_vm.mini},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [(_vm.mini)?_c('v-img',{staticClass:"ml-3 mt-6",attrs:{"src":require("@/assets/img/marketradar/marketradar_32x32.png"),"contain":"","width":"32","height":"32"}}):_c('v-img',{staticClass:"ml-5 mt-6",attrs:{"src":require("@/assets/img/brands/marketradar.png"),"contain":"","width":"140","height":"54"}}),(_vm.isMobile)?[_c('v-list-item',{staticClass:"void mt-4 py-5 pl-5",attrs:{"two-line":""},on:{"click":function($event){return _vm.navigate('/settings')}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"color__white-span"},[_vm._v(_vm._s(_vm.account))]),_c('span',{staticClass:"color__suvagrey-span"},[_vm._v("Настройки")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"size":"24","color":_vm.colors.suvaGrey}},[_vm._v(" mdi-arrow-right-thick ")])],1)],1)],1)]:_vm._e()]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-divider'),[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.menuBasicGroup),function(item){return _c('div',{key:item.title},[(!item.sublinks)?_c('v-list-item',{class:[
            'nav-link',
            'py-5',
            'px-5',
            _vm.navBar.active === item.name && _vm.activeNavClass,
          ],attrs:{"link":""},on:{"click":function($event){return _vm.navigate(item.href)}}},[_c('v-list-item-icon',{class:['mr-5', _vm.mini && 'ml-4']},[_c('v-icon',{attrs:{"color":_vm.navBar.active === item.name ? _vm.colors.white : _vm.colors.suvaGrey,"size":"22"}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('span',{class:_vm.navBar.active === item.name
                  ? 'color__white-span'
                  : 'color__suvagrey-span'},[_vm._v(" "+_vm._s(item.title)+" "),(item.badge && item.badge.content)?_c('counter',{staticClass:"ml-2",attrs:{"color":"red","value":item.badge.content}}):_vm._e()],1)])],1):_c('v-list-group',{attrs:{"no-action":"","sub-group":"","prepend-icon":"mdi-menu-down"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"group-link px-0"},[_c('v-list-item-content',[_c('span',{staticClass:"color__suvagrey-span"},[_vm._v(_vm._s(item.title))])])],1)]},proxy:true}],null,true)},_vm._l((item.sublinks),function(sublink){return _c('v-list-item',{key:sublink.title,class:[
              'nav-link',
              'py-5',
              'pl-10',
              _vm.navBar.active === sublink.name && _vm.activeNavClass,
            ],attrs:{"link":""},on:{"click":function($event){return _vm.navigate(sublink.href)}}},[_c('v-list-item-icon',{staticClass:"mr-5"},[_c('v-icon',{attrs:{"color":_vm.navBar.active === sublink.name
                    ? _vm.colors.white
                    : _vm.colors.suvaGrey,"size":"22"}},[_vm._v(_vm._s(sublink.icon))])],1),_c('v-list-item-content',[_c('span',{class:_vm.navBar.active === sublink.name
                    ? 'color__white-span'
                    : 'color__suvagrey-span'},[_vm._v(_vm._s(sublink.title))])])],1)}),1)],1)}),_c('v-divider')],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }