import OpenServerApi from "@/interfaces/OpenServerApi";

class DataExpertService {
    fetchAll() {
        return OpenServerApi().get('/dataexpert/dashboard');
    }

    fetchItems() {
        return OpenServerApi().get('/dataexpert/items');
    }

    startJob() {
        return OpenServerApi().post('/dataexpert/start/job');
    }

    stopJob() {
        return OpenServerApi().post('/dataexpert/stop/job');
    }    
}

export default new DataExpertService();