<script>
import { editSyncParameters } from '../model';

export default {
  props: {
    id: String,
    syncParams: Object
  },
  data() {
    return {
      loading: false,
      error: null,
      params: {
        notFoundProductsAction: "ARCHIVE",
        syncDescription: "ALWAYS",
        syncImages: "ALWAYS",
        syncOutlets: "ALWAYS",
        syncParams: "ALWAYS",
        syncPrice: "ALWAYS",
        syncSourceProductsOnly: true,
      },
    }
  },
  created() {
    this.params = { ...this.syncParams };
  },
  methods: {
    async save() {
      this.loading = true;
      
      try {
        await editSyncParameters(this.id, this.params);
        this.$emit('saved');
      } catch(error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      this.$emit('canceled');
    }
  },
  computed: {
    syncVariants() {
        return [
          { value: "ALWAYS", text: this.$t('integration.syncVariants.ALWAYS') },
          { value: "NEW_ONLY", text: this.$t('integration.syncVariants.NEW_ONLY') },
          { value: "NEVER", text: this.$t('integration.syncVariants.NEVER') }
        ]
      },
      actionVariants() {
        return [
          { value: "ARCHIVE", text: this.$t('integration.actionVariants.ARCHIVE') },
          { value: "DELETE", text: this.$t('integration.actionVariants.DELETE') },
          { value: "NOTHING", text: this.$t('integration.actionVariants.NOTHING') }
        ]
      }
  }
}
</script>

<template>
  <v-form :disabled="this.loading" :value="true" @submit.prevent="save" class="d-flex flex-column edit-sync-parameters-form">
    <v-alert v-if="error" type="error">{{ error.message || error }}</v-alert>

    <v-card>
      <v-card-text>
        <v-select
          :label="$t('integration.card.syncParams.price')"
          v-model="params.syncPrice"
          :items="syncVariants"
        />
    
        <v-select
          :label="$t('integration.card.syncParams.outlets')"
          v-model="params.syncOutlets"
          :items="syncVariants"
        />
    
        <v-select
          :label="$t('integration.card.syncParams.images')"
          v-model="params.syncImages"
          :items="syncVariants"
        />
    
        <v-select
          :label="$t('integration.card.syncParams.description')"
          v-model="params.syncDescription"
          :items="syncVariants"
        />
    
        <v-select
          :label="$t('integration.card.syncParams.params')"
          v-model="params.syncParams"
          :items="syncVariants"
        />
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-text>
        <v-select
          :label="$t('integration.card.syncParams.notFoundProductsAction')"
          v-model="params.notFoundProductsAction"
          :items="actionVariants"
        />
    
        <v-checkbox
          :label="$t('integration.card.syncParams.sourceProductsOnly')"
          v-model="params.syncSourceProductsOnly"
        />
      </v-card-text>
    </v-card>

    <div class="d-flex justify-space-between">
      <v-btn
        color="primary"
        class="figma-btn "
        outlined
        type="button"
        @click="cancel"
        :disabled="this.loading"
      >{{ $t('button.cancel') }}</v-btn>
      
      <v-btn
        color="primary"
        class="figma-btn "
        type="submit"
        :loading="this.loading"
      >{{ $t('button.save') }}</v-btn>
    </div>
  </v-form>
</template>

<style>
.edit-sync-parameters-form {
  gap: 1rem;
}
</style>
