import {
    bucketNamespace,
    notificatonStatuses,
    xlsImportOperationTypes,
} from "@/enumerations";
import ServiceFacade from "@/services/ServiceFacade";

const state = () => ({
    imageUploaderForm: {
        dialog: false,
        files: [],
        loading: false
    },
});

const actions = {
    async uploadImages({ state, commit, rootGetters }) {
        const files = state.imageUploaderForm.files;
        if (!files.length) return;

        const formData = new FormData();
        commit('CHANGE_IMAGE_LOADING_PROGRESS', true);

        const sku = rootGetters["productUIForm/sku"];
        const datasource = rootGetters[`${bucketNamespace.DATASOURCES}/bucketID`];

        formData.append('sku', sku);

        // append the files to FormData
        Array
            .from(Array(files.length).keys())
            .map(x => {
                formData.append(
                    `files[${x}]`,
                    files[x],
                    files[x].name
                );
            });

        try {
            // save it
            const { status, data } = await ServiceFacade.uploadPhotos(datasource, formData);

            if (status == 200 && data?.images) {
                let notificationMessage = 'Фотографии успешно загружены!';

                commit('productUIForm/ADD_PRODUCT_IMAGES', data.images, { root: true });
                commit('core/SET_NOTIFICATION', {
                    status: notificatonStatuses.success,
                    message: notificationMessage
                }, { root: true })
            }

            commit('CHANGE_IMAGE_LOADING_PROGRESS', false);
            commit('CLOSE_IMAGE_UPLOADER_FORM');
        } catch (error) {
            let message;

            if (error.response && error.response.status === 400 && error.response.data) {
                switch (error.response.data.code) {
                    case "FILE_IS_NOT_AN_IMAGE":
                        message = 'Загружаемый файл не соответствует изображению!'
                        break;


                    case "DIMENSION_IS_OUT_OF_RANGE":
                        message = 'Минимальный/Максимальный размер одной из сторон загружаемой картинки не должен быть меньше 800 пикселей и не больше 2000 пикселей'
                        break;

                    default:
                        break;
                }
            }

            commit('CHANGE_IMAGE_LOADING_PROGRESS', false);
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: message || error.message || "Произошла ошибка при загрузке фотографий"
            }, { root: true });
        } finally {
            // reset
            for (const iterator of formData.keys()) {
                formData.delete(iterator);
            }
        }
    },
    async uploadXLS({ commit, dispatch, rootGetters }, operation) {
        const { id } = rootGetters["datasources/bucketInfo"];
        const productPublishingWizard = rootGetters["stepWizard/productPublishingWizard"];
        const priceListWizard = rootGetters["stepWizard/priceListWizard"];

        let body;

        switch (operation) {
            case xlsImportOperationTypes.DEFAULT:
                body = productPublishingWizard.xlsProducts;
                break;

            case xlsImportOperationTypes.BATCH:
                body = {
                    resetReservations: priceListWizard.resetReservations,
                    products: [...priceListWizard.leftoversDataset]
                };
                break;
            default:
                break;
        }

        try {
            const { status, data } = await ServiceFacade.importProducts(operation, id, body);

            if (status == 200) {
                switch (operation) {
                    case xlsImportOperationTypes.DEFAULT:
                        commit('stepWizard/SET_XLS_PUBLISHING_RESULT', {
                            success: data.success,
                            loadedItems: data.products ? data.products.length : 0
                        }, { root: true })
                        dispatch('eventBus/modifyNonInteractiveProducts',
                            body.map(row => {
                                return { sku: Array.isArray(row) && row[0]}
                            }),
                            { root: true });

                        break;

                    case xlsImportOperationTypes.BATCH:
                        commit('stepWizard/SET_PRICELIST_IMPORT_RESULT', {
                            success: data.success,
                            processed: data.processed,
                            failed: data.failed,
                            errors: data.errors
                        }, { root: true });
                        dispatch('eventBus/modifyNonInteractiveProducts',
                            body.products,
                            { root: true });

                        break;
                    default:
                        break;
                }

                dispatch('eventBus/registerSystemEvent', 0xbc, { root: true });
            }
        } catch (error) {
            let message;

            if (error.response) {
                message = error.response.data && error.response.data.error;
            }
            switch (operation) {
                case xlsImportOperationTypes.DEFAULT:
                    commit('stepWizard/SET_XLS_PUBLISHING_RESULT', {
                        success: false,
                        loadedItems: 0
                    }, { root: true });
                    break;

                case xlsImportOperationTypes.BATCH:
                    commit('stepWizard/SET_PRICELIST_IMPORT_RESULT', {
                        success: false,
                        processed: undefined,
                        failed: undefined,
                        errors: undefined
                    }, { root: true });
                    break;
                default:
                    break;
            }

            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: message ? message : "500: Внутренняя ошибка сервера"
            }, { root: true });
        }
    },
}

// mutations
const mutations = {
    MOUNT_IMAGE_FILES(state, files) {
        state.imageUploaderForm.files = files;
    },
    CHANGE_IMAGE_LOADING_PROGRESS(state, loading) {
        state.imageUploaderForm.loading = loading;
    },
    DISPLAY_IMAGE_UPLOADER_FORM(state, dialog = true) {
        state.imageUploaderForm.dialog = dialog;
    },
    CLOSE_IMAGE_UPLOADER_FORM(state, dialog = false) {
        state.imageUploaderForm.dialog = dialog;
        state.imageUploaderForm.files = [];
    },
}

// getters
const getters = {
    imageUploaderForm: (state) => state.imageUploaderForm,
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}