<template>
  <div>
    <v-app-bar height="64" :color="colors.white">
      <v-btn icon @click="onToggleDrawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <template v-if="company.sellerId">

         <v-tooltip bottom v-if="!isMobile">
          <template v-slot:activator="{ on, attrs }">
            <div class="mr-10" v-bind="attrs" v-on="on">
              <span class="text-subtitle-2"
                >{{ tradingBotsCount }} / {{ tradingBotsLimit }} ботов</span
              >
              <v-progress-linear
                :value="usagePercent(tradingBotsLimit, tradingBotsCount)"
                :color="usageColor(tradingBotsLimit, tradingBotsCount)"
              ></v-progress-linear>
            </div>
          </template>
          <span> Количество торговых ботов доступное для вашего аккаунта </span>
        </v-tooltip>

        <v-tooltip bottom v-if="!isMobile">
          <template v-slot:activator="{ on, attrs }">
            <div class="mr-10" v-bind="attrs" v-on="on">
              <span class="text-subtitle-2"
                >{{ productCount }} / {{ productLimit }} товаров</span
              >
              <v-progress-linear
                :value="usagePercent(productLimit, productCount)"
                :color="usageColor(productLimit, productCount)"
              ></v-progress-linear>
            </div>
          </template>
          <span> Количество товара доступное для вашего аккаунта </span>
        </v-tooltip>

        <v-tooltip bottom :disabled="isMobile">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="mr-3">
              <v-icon
                :color="
                  platformIntegrationSyncActive ? colors.aqua : colors.black
                "
                size="25"
                @click="onDisplayPlatformsIntegrationModal"
              >
                mdi-swap-vertical
              </v-icon>
            </v-btn>
          </template>
          <span> Откройте окно с интеграциями </span>
        </v-tooltip>

        <v-menu
          v-model="notificationListMenu"
          :close-on-content-click="false"
          offset-y
          class="notifications-popup"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              :content="newNotificationsCount"
              :value="newNotificationsCount"
              :color="colors.red"
              overlap
              offset-y="20"
              offset-x="15"
              class="mr-5"
            >
              <div>
                <v-img
                    :src="bellImage"
                    contain
                    class="bell-image"
                  >
                  </v-img>
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="autoSetDateLastNotificationCheck"
                >
                </v-btn>
              </div>
            </v-badge>
          </template>

          <v-card max-width="500" min-height="150" max-height="600">
            <v-card-text class="void">
              <v-list
                three-line
                elevation="2"
                v-if="notifications.length"
              >
                <template v-for="(notify, index) in notifications">
                  <v-list-item :key="notify.title">
                    <v-list-item-icon>
                      <v-icon :color="notificationIconTypes[notify.type].color">
                        {{ notificationIconTypes[notify.type].icon }}
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title
                      >{{ notify.title }}</v-list-item-title>

                      <v-list-item-subtitle
                        class="mt-2"
                      >{{ notify.description }}</v-list-item-subtitle>

                      <v-btn
                        v-show="notify.url"
                        text
                        class="mt-2"
                        :color="colors.aqua"
                        style="max-width: 114px"
                        left
                        @click="navigateTo(notify.url)"
                      >
                        Открыть
                        <v-icon small class="ml-1">mdi-open-in-new</v-icon>
                      </v-btn>
                    </v-list-item-content>

                    <v-list-item-action class="mt-2">
                      <v-icon
                        v-if="notify.unreadNotification"
                        :color="colors.spanishOrange"
                        >mdi-circle</v-icon
                      >
                      <v-list-item-subtitle
                        class="mt-2"
                      >{{ toDateFormat(notify.dateCreated) }}
                      </v-list-item-subtitle>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider
                    v-if="index < notifications.length - 1"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list>
              <v-info-board
                v-else
                class="pa-2"
                message="Новых уведомлений нет."
                displayIcon="mdi-bell"
                dense
              ></v-info-board>
            </v-card-text>
          </v-card>
        </v-menu>

        <shortcuts-guide-dropdown-menu class="mr-5">
        </shortcuts-guide-dropdown-menu>
      </template>

      <v-menu left bottom offset-y v-if="!isMobile">
        <template v-slot:activator="{ on, attrs }">
          <v-card
            v-bind="attrs"
            v-on="on"
            class="d-flex elevation-0"
            height="64"
          >
            <v-row class="void" align="center">
              <v-avatar width="36" height="36">
                <v-icon>mdi-account</v-icon>
              </v-avatar>
              <h4 class="profile__name">{{ account }}</h4>
              <v-icon :color="colors.black">mdi-menu-down</v-icon>
            </v-row>
          </v-card>
        </template>

        <v-list>
          <template v-for="(slot, i) in menuSlots">
            <v-list-item
              :key="i"
              @click="onNavigate(slot.event)"
              v-if="onDisplayMenuSlotItem(slot)"
            >
              <v-list-item-icon>
                <v-icon color="#78909c">{{ slot.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ slot.title }}</v-list-item-title>
            </v-list-item>
            <v-divider
              :key="i + 0x10"
              v-if="onDisplayMenuSlotItem(slot) && i < menuSlots.length - 1"
            ></v-divider>
          </template>
        </v-list>
      </v-menu>
    </v-app-bar>

    <template v-if="alertsDisplayPermission">
      <v-alert
        v-for="(alert, i) in alerts"
        :key="i"
        text
        prominent
        dismissible
        dense
        :type="alert.type"
        :icon="alertIcon(alert.type)"
        @input="onAlertToggle"
      >
        <v-row align="center">
          <v-col class="grow">
            {{ alertText(alert) }}
          </v-col>
          <v-col class="shrink" v-if="hasAction(alert)">
            <v-btn @click="alertAction(alert)">Оплатить</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </template>

    <thank-you-dialog></thank-you-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ScreenResolution from "@/mixins/ScreenResolution";
import ColorPalette from "@/mixins/ColorPalette";
import FormatMixin from "@/mixins/Format";
import FirebaseAuth from "@/interfaces/FirebaseAuth";
import "./systembar.css";
import { alerts, notificatonStatuses } from "@/enumerations";
import TimeoutGenerator from "@/helpers/TimeoutGenerator";
import VInfoBoard from "@/components/info-board/VInfoBoard";
import ThankYouDialog from "@/components/dialogs/ThankYouDialog";
import ShortcutsGuideDropdownMenu from "@/components/dropdown-menu/ShortcutsGuideDropdownMenu";

export default {
  name: "Systembar",
  components: {
    ThankYouDialog,
    ShortcutsGuideDropdownMenu,
    VInfoBoard,
  },
  mixins: [ScreenResolution, ColorPalette, FormatMixin],
  data() {
    return {
      menuSlots: [
        {
          title: "Настройки",
          icon: "mdi-cog",
          event: "settings",
        },
        {
          title: "Профиль компании",
          icon: "mdi-wallet",
          event: "about",
        },
        {
          title: "Выход",
          icon: "mdi-logout-variant",
          event: "logout",
        },
      ],
      notificationListMenu: false,
    };
  },
  computed: {
    ...mapGetters("profile", {
      company: "company",
      notifications: "notifications",
    }),
    ...mapGetters("core", {
      navBar: "navBar",
    }),
    ...mapGetters("serviceModals", {
      platformsIntegrationServiceModal: "platformsIntegrationServiceModal",
    }),
    account() {
      return this.resolution.width > 412 ? this.company.name : "";
    },
    notificationIconTypes() {
      return {
        ["INFO"]: {
          color: this.colors.aqua,
          icon: "mdi-information",
        },
        ["WARNING"]: {
          color: this.colors.cumulus,
          icon: "mdi-alert",
        },
        ["ERROR"]: {
          color: this.colors.red,
          icon: "mdi-close-thick",
        },
      };
    },
    newNotificationsCount() {
      return this.notifications.filter((n) => n.unreadNotification).length;
    },
    alerts() {
      return this.company.alerts;
    },
    platformIntegrationSyncActive() {
      return this.platformsIntegrationServiceModal.syncActive;
    },
    productCount() {
      return this.company.productCount;
    },
    productLimit() {
      return this.company.productLimit;
    },
    tradingBotsCount() {
      return this.company.tradingBotsCount;
    },
    tradingBotsLimit() {
      return this.company.tradingBotsLimit;
    },
    alertsDisplayPermission() {
      const lastShutdownTime = localStorage.getItem(
        "notify_last_shutdown_time"
      );
      if (lastShutdownTime) {
        const now = new Date().getTime();
        const MAX_IDLE_TIME_IN_MILLS =
          process.env.VUE_APP_NOTIFICATION_IDLE_TIME_IN_SECONDS * 1000;

        if (now < parseInt(lastShutdownTime) + MAX_IDLE_TIME_IN_MILLS) {
          return false;
        }
      }
      return true;
    },
    bellImage() {
      return this.newNotificationsCount > 0 ?
          require('@/assets/img/icons/bell_animated.gif') :
          require('@/assets/img/icons/bell_static.png')
    }
  },
  watch: {
    notificationListMenu(isOpen) {
      if (!isOpen && this.newNotificationsCount > 0) {
        this.$store.dispatch("profile/autoCloseLastNotifications");
      }
    },
  },
  methods: {
    usagePercent(limit, count) {
      return Math.round(
        100 / (limit / count)
      );
    },
    usageColor(limit, count) {
      return this.usagePercent(limit, count) > 90 ? "red" : "primary";
    },
    alertIcon(type) {
      switch (type) {
        case "warning":
          return "mdi-alert-circle-outline";
        case "error":
          return "mdi-close-circle-outline";
        default:
          return "mdi-alert-circle-outline";
      }
    },
    navigateTo(url) {
      window.open(url, "_blank");
    },
    autoSetDateLastNotificationCheck() {
      this.newNotificationsCount > 0 &&
        localStorage.setItem(
          "date_last_notification_check",
          new Date().getTime()
        );
    },
    onAlertToggle(isActive) {
      if (!isActive) {
        localStorage.setItem(
          "notify_last_shutdown_time",
          new Date().getTime().toString()
        );
      }
    },
    onToggleDrawer() {
      if (this.windowSize.width >= 1080) {
        this.$store.dispatch("core/autoMinimizeDrawer", !this.navBar.mini);
      } else {
        this.$store.dispatch("core/toggleDrawer", !this.navBar.drawer);
      }
    },
    onDisplayMenuSlotItem(slot) {
      if (slot.event === "logout") {
        return true;
      }
      return this.company?.sellerId;
    },
    onNavigate(event) {
      switch (event) {
        case "settings":
          this.$router.push("/settings");
          break;
        case "about":
          this.$router.push("/company/about");
          break;
        case "logout":
          this.logout();
          break;

        default:
          break;
      }
    },
    onDisplayPlatformsIntegrationModal() {
      this.$store.commit("serviceModals/DISPLAY_PLATFORMS_INTEGRATION_MODAL");
    },
    async logout() {
      await FirebaseAuth.signOut();
    },
    startPayment() {
      let widget = new window.cp.CloudPayments();
      let amount = this.company.subscription.monthlyFee;
      let planName = this.company.subscription.planName;
      let paymentId = this.company.paymentId;
      let product = "start";
      let quantity = 1;
      var self = this;
      widget.pay(
        "charge",
        {
          publicId: process.env.VUE_APP_CLOUD_PAYMENTS_PID,
          description: `${planName}, ${quantity} мес`,
          amount: amount,
          currency: "KZT",
          accountId: this.company.email,
          email: this.company.email,
          skin: "modern",
          data: {
            paymentId,
            product,
            quantity,
          },
        },
        {
          onSuccess: function () {
            self.$store.dispatch("profile/removeSubscriptionAlerts");
            self.showThankYouPopup();
          },
          onFail: function (reason, options) {
            if (reason !== "User has cancelled") {
              self.showPaymentError(reason, options);
            }
          },
        }
      );
    },
    alertAction(alert) {
      if (
        [alerts.SUBSCRIPTION_EXPIRING, alerts.ACCOUNT_SUSPENDED].includes(
          alert.id
        )
      ) {
        return this.startPayment();
      }
      return function () {};
    },
    alertText(alert) {
      let daysLeft = this.company.subscription.daysLeft;
      switch (alert.id) {
        case alerts.PRODUCT_LIMIT_EXCEED:
          return `Внимание: Использовано максимальное количество товаров. Удалите ненужные товары или перейдите на новый тариф.`;
        case alerts.PRODUCT_LIMIT_ABOUT_EXCEED:
          return `Напоминание: Свободное место заканчивается. Использовано более 90% максимального количества товаров. Удалите ненужные товары или перейдите на новый тариф.`;
        case alerts.SUBSCRIPTION_EXPIRING:
          return `Напоминание: Срок подписки истекает через ${daysLeft} дней. Необходимо провести оплату за следующий месяц.`;
        case alerts.ACCOUNT_SUSPENDED:
          return `Внимание: Подписка не продлена, аккаунт заблокирован. Необходимо провести оплату за следующий месяц.`;
      }
    },
    hasAction(alert) {
      return [alerts.SUBSCRIPTION_EXPIRING, alerts.ACCOUNT_SUSPENDED].includes(
        alert.id
      );
    },
    showPaymentError(reason, options) {
      this.$store.commit("core/SET_NOTIFICATION", {
        status: notificatonStatuses.error,
        message: `Ошибка оплаты за ${options.description}. Причина: ${
          reason || "неизвестно"
        }`,
      });
    },
    showThankYouPopup() {
      this.$store.dispatch(
        "core/setThankYouDialog",
        `Подписка успешно продлена`
      );
      this.$confetti.start();
      TimeoutGenerator.timeWait(1000).then(() => {
        this.$confetti.stop();
      });
    },
  },
};
</script>

<style scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.bell-image {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 8px;
  top: 8px;
}

.notifications-popup {
  right: 10px;
}
</style>
