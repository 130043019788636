<template>
  <v-dialog id="modal" v-model="dialog" max-width="500">
    <v-card>
      <v-row class="void" justify="center">
      <v-card-title class="headline" >
        Спасибо!
      </v-card-title>
      </v-row>
      <v-row class="void mb-5" justify="center">
        <v-icon size="72" color="amber">mdi-star-shooting</v-icon>
      </v-row>
      <v-card-text class="subtitle-1 text-center">{{ thankYouDialog.dialogText }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="branded-btn" color="grey darken-1" text @click="onClose">
          {{ thankYouDialog.cancelBtnLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import TimeoutGenerator from "@/helpers/TimeoutGenerator";

export default {
  name: "ThankYouDialog",
  computed: {
    ...mapGetters("core", {
      thankYouDialog: "thankYouDialog",
    }),
    dialog: {
      get() {
        return this.thankYouDialog.dialog;
      },
      set() {
        this.$store.dispatch("core/toggleThankYouDialog");
      },
    }
  },
  watch: {
    dialog(value) {
      if (value) {
        TimeoutGenerator.timeWait(40).then(() => {
          let dialog = document.getElementsByClassName("v-dialog--active")[0];
          if (dialog) dialog.style["overflow-y"] = "visible";
        });
      }
    }
  },
  methods: {
    onClose() {
      this.dialog = !this.dialog;
    },
  },
};
</script>