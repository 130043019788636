import { notificatonStatuses } from "@/enumerations";
import ServiceFacade from "@/services/ServiceFacade";
import ErrorGenerator from "@/helpers/ErrorGenerator";

const state = () => ({
    dataExpertDashboard: {},
    dataExpertItems: [],
    isFetching: false,
    isFetchingItems: false,
})

const actions = {
    async fetchDataExpertDashboard({ commit }) {
        try {
            commit('SET_DATAEXPERT_FETCHING', true);
            const { status, data } = await ServiceFacade.fetchDataExpert();
            commit('SET_DATAEXPERT_FETCHING', false);
            if (status == 200) {
                commit('SET_DATAEXPERT_DASHBOARD', data);
            }
        } catch (error) {
            commit('SET_DATAEXPERT_FETCHING', false);
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: error.message || "Error"
            }, { root: true })
        }
    },
    async fetchDataExpertItems({ commit }) {
        try {
            commit('SET_DATAEXPERT_FETCHING_ITEMS', true);
            const { status, data } = await ServiceFacade.fetchDataExpertItems();
            commit('SET_DATAEXPERT_FETCHING_ITEMS', false);
            if (status == 200) {
                commit('SET_DATAEXPERT_ITEMS', data.items);
            }
        } catch (error) {
            commit('SET_DATAEXPERT_FETCHING_ITEMS', false);
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: error.message || "Error"
            }, { root: true })
        }
    },
    increaseBalance({ commit, state }, addedChecks) {
        let newBalanceData = {
            ...state.dataExpertDashboard.balance || {},
            checksLeft: (state.dataExpertDashboard.balance?.checksLeft || 0) + addedChecks
        }
        commit('UPDATE_DATAEXPERT_DASHBOARD', {
            balance: newBalanceData
        });
    },
    async startDataExpertJob({ commit, dispatch }) {
        try {
            commit('SET_DATAEXPERT_FETCHING', true);
            const { status } = await ServiceFacade.startDataExpertJob();
            commit('SET_DATAEXPERT_FETCHING', false);
            if (status == 200) {
                dispatch("fetchDataExpertDashboard");
                commit('core/SET_NOTIFICATION', {
                    status: notificatonStatuses.success,
                    message: "Задача начата"
                }, { root: true })
            }
        } catch (error) {
            let localizedResponse = ErrorGenerator.fromBackendErrorCode(error?.response?.data?.error || `Error ${error?.response?.status}`);
            commit('SET_DATAEXPERT_FETCHING', false);
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: localizedResponse
            }, { root: true })
        }
    },
    async stopDataExpertJob({ commit, dispatch }) {
        try {
            commit('SET_DATAEXPERT_FETCHING', true);
            const { status } = await ServiceFacade.stopDataExpertJob();
            commit('SET_DATAEXPERT_FETCHING', false);
            if (status == 200) {
                dispatch("fetchDataExpertDashboard");
                commit('core/SET_NOTIFICATION', {
                    status: notificatonStatuses.success,
                    message: "Задача остановлена"
                }, { root: true })
            }
        } catch (error) {
            commit('SET_DATAEXPERT_FETCHING', false);
            commit('core/SET_NOTIFICATION', {
                status: notificatonStatuses.error,
                message: error.message || "Error"
            }, { root: true })
        }
    }
    
}

// mutations
const mutations = {
    SET_DATAEXPERT_FETCHING(state, fetching) {
        state.isFetching = fetching
    },
    SET_DATAEXPERT_FETCHING_ITEMS(state, fetching) {
        state.isFetchingItems = fetching
    },
    SET_DATAEXPERT_DASHBOARD(state, data) {
        state.dataExpertDashboard = { ...data }
    },
    SET_DATAEXPERT_ITEMS(state, data) {
        state.dataExpertItems = [...data]
    },
    UPDATE_DATAEXPERT_DASHBOARD(state, data) {
        state.dataExpertDashboard = { ...state.dataExpertDashboard, ...data }
    },
}

// getters
const getters = {
    dataExpertDashboard: (state) => state.dataExpertDashboard,
    dataExpertItems: (state) => state.dataExpertItems, 
    isFetching: (state) => state.isFetching,
    isFetchingItems: (state) => state.isFetchingItems,
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}