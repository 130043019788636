<script>
import { BaseStage } from '../shared';
import PriceField from './PriceField.vue';
import { INPUTS_KEY } from './constants';

export default {
  name: 'IntegrationPriceType',
  extends: BaseStage,
  components: {
    PriceField
  },
  data() {
    return {
      error: null,
      prices: [],
    }
  },
  provide() {
    return {
      [INPUTS_KEY]: () => {
        return this.$refs.inputs;
      },
    }
  },
  methods: {
    verify() {
      const isValid = this.$refs.form.validate();
      if (!isValid) return false;

      this.builder.prices = this.$refs.inputs.map((input) => {
        return {
          id: input.price.id,
          name: input.price.name,
          mappingTo: input.type
        }
      })

      return true;
    }
  }
}
</script>

<template>
  <div class="integration-price-type pa-2">
    <p class="subtitle-1 text-left">
      {{ $t('integration.addIntegration.stepper.step.priceType.content.description') }}
    </p>
    <v-form
    v-if="builder.integrationData"
      class="integration-price-type_form d-flex flex-column"
      ref="form"
      style="width: 100%;"
    >
      <PriceField
        ref="inputs"
        v-for="price in builder.integrationData.prices"
        :key="price.id"
        :price="price"
      />
    </v-form>

  </div>
</template>

<style>
.integration-price-type_form {
  gap: 1rem;
}
</style>
