<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-img
        :aspect-ratio="16 / 9"
        src="@/assets/img/marketradar/marketradar_aqua.png"
        contain
        width="330"
        height="126"
      />
    </v-row>
  </v-container>
</template>

<script>
export default {
    name: "AppGreetingsScreen"
};
</script>

<style>
</style>