import { alarmTypes } from "@/enumerations";
import TimeoutGenerator from "@/helpers/TimeoutGenerator";


class AlarmClockService {
    constructor() {
        this.activeTimersCodes = new Set();
    }
    async ring(time, event, code) {
        await TimeoutGenerator.timeWait(time);
        window.dispatchEvent(new CustomEvent(event));
        this.activeTimersCodes.delete(code);
    }
    activateAlarm(code) {
        switch (code) {
            case alarmTypes.ORDERS_SYNC: {
                let minutes = process.env.VUE_APP_AUTOMATIC_ORDERS_FETCHING_FREQUENCY || 5;
                this.ring(minutes * 60 * 1000, "order@backgroundsync", code);
                break;
            }
            default: throw new Error("Unknown alarm code");
        }
        this.activeTimersCodes.add(code);
    }
    checkTimerCode(code) {
        return this.activeTimersCodes.has(code);
    }
}

export default new AlarmClockService();