export const ADD_CITY_PATH = '/settings/kaspicities/set';

export const VERIFY_INTEGRATION_PATH = '/datasources/marketradar/sources/verify'

export const ADD_INTEGRATION_PATH = '/datasources/marketradar/sources/create';

export const DELETE_INTEGRATION_PATH = '/datasources/marketradar/sources/delete';

export const EDIT_INTEGRATION_PATH = '/datasources/marketradar/sources/update';

export const GET_ALL_OUTLETS_PATH = '/settings/outlets/all';

export const ADD_OUTLETS_PATH = '/settings/outlets/create';

export const DELETE_OUTLETS_PATH = '/settings/outlets/delete';

export const ADD_PLATFORM_PATH = '/settings/platforms/add';

export const DELETE_PLATFORM_PATH = '/settings/platforms/delete';

export const ADD_ORDERS_OPTIONS_VERIFY_PATH = '/settings/orders/apikey/verify';

export const ADD_ORDERS_OPTIONS_PATH = '/settings/orders/apikey/set';
