<script>
import { INTEGRATION_KEY } from './constants';
import ScreenResolutionMixin from "@/mixins/ScreenResolution";
import SectionField from './SectionField';
import { EditSourceParameters } from '@/features/integration/editSourceParameters';

export default {
  inject: [INTEGRATION_KEY],
  mixins: [ScreenResolutionMixin],
  components: {
    SectionField,
    EditSourceParameters,
  },
  data() {
    return {
      editMode: false,
      automaticImportVariants: [
        {
          text: this.$t('integration.automaticImportVariants.NEVER'),
          value: "NEVER",
        },
        {
          text: this.$t('integration.automaticImportVariants.EVERY_1_HOUR'),
          value: "EVERY_1_HOUR",
        },
        {
          text: this.$t('integration.automaticImportVariants.EVERY_30_MIN'),
          value: "EVERY_30_MIN",
        },
        {
          text: this.$t('integration.automaticImportVariants.EVERY_15_MIN'),
          value: "EVERY_15_MIN",
        },
        {
          text: this.$t('integration.automaticImportVariants.EVERY_1_DAY'),
          value: "EVERY_1_DAY",
        },
      ]
    }
  },
  computed: {
    integration() {
      return this[INTEGRATION_KEY];
    },
  },
  methods: {
    makeImport() {
      this.$store.dispatch(
        "serviceModals/callPlatformIntegrationWebhook",
        this.integration.id
      );
    },
  }
}
</script>

<template>
  <v-flex xs12 md12 lg4 class="void">
    <v-card elevation="4" :class="['void py-5 px-7', !isMobile && 'mr-4']" tile flat height="100%">
      
      <v-card-title width="100%" class="font-weight-regular text-h6 void d-flex justify-space-between mb-8">
        {{ $t('integration.card.dataParams.title') }}

        <v-btn v-if="!editMode" icon class="text-right" @click="editMode=!editMode">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-card-title>

    <v-expand-transition>
      <EditSourceParameters
        v-if="editMode"
        @canceled="editMode=false"
        @saved="editMode=false"
        :id="integration.id"
        :sourceParams="integration.sourceParameters"
        class="void "
      />
    </v-expand-transition>

      <v-card-text v-if="!editMode" class="void">
        <SectionField
          :prop="$t('integration.card.dataParams.url')"
          :value="integration.sourceParameters.url"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <a
                v-bind="attrs"
                v-on="on"
                target="_blank"
                :href="integration.sourceParameters.url"
              >{{ $t('label.link') }}</a>
            </template>
            
            {{ integration.sourceParameters.url }}
          </v-tooltip>
        </SectionField>

        <SectionField
          :prop="$t('integration.card.dataParams.headerName')"
          :value="integration.sourceParameters.headerName"
        />
        
        <v-divider class="pb-5"/>

        <SectionField
          :prop="$t('integration.card.dataParams.automaticImport')"
          :value="$t(`integration.automaticImportVariants.${integration.sourceParameters.automaticImport}`)"
        />
      </v-card-text>
    </v-card>
  </v-flex>
</template>
