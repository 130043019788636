<script>
import { INTEGRATION_KEY } from './constants';
import ScreenResolutionMixin from "@/mixins/ScreenResolution";
import SectionField from './SectionField.vue';
import FormatMixin from "@/mixins/Format";
import { platfotmIntegrationStates } from "@/enumerations";
import { EditPriceParameters } from '@/features/integration/editPriceParameters';

export default {
  inject: [INTEGRATION_KEY],
  mixins: [ScreenResolutionMixin, FormatMixin],
  components: {
    SectionField,
    EditPriceParameters
  },
  data() {
    return {
      editMode: false
    }
  },
  computed: {
    integration() {
      return this[INTEGRATION_KEY];
    },
    dateUpdated() {
      return this.integration.lastImport?.dateUpdated
        ? this.toDateFormat(
            this.integration.lastImport?.dateUpdated
          )
        : this.$t('label.miss');
    },
    dateOfCatalog() {
      return this.integration.lastImport?.dateOfCatalog
          ? this.toDateFormat(
              this.integration.lastImport?.dateOfCatalog
            )
          : this.$t('label.miss');
    },
    status() {
      return (
        this.integration.lastImport?.status in platfotmIntegrationStates &&
        platfotmIntegrationStates[this.integration.lastImport?.status]
      );
    },
  },
}
</script>

<template>
  <v-flex xs12 md12 lg4 class="void">
    <v-card
      class="void py-5 px-7 d-flex align-stretch flex-column"
      elevation="4" tile flat height="100%"
    >
      <v-card-title class="font-weight-regular text-h6 void d-flex justify-space-between mb-8">
        {{ $t('integration.card.prices.title') }}

        <v-btn v-if="integration.prices?.length && !editMode" icon class="text-right" @click="editMode=!editMode">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-card-title>

      <v-expand-transition>
        <EditPriceParameters
          v-if="editMode"
          @canceled="editMode=false"
          @saved="editMode=false"
          :id="integration.id"
          :priceParams="integration.prices"
          class="void"
        />
      </v-expand-transition>

      <v-card-text v-if="!editMode" class="void">
        <template v-if="integration.prices?.length">
          <SectionField
            v-for="price in integration.prices"
            :key="price.id"
            :prop="price.name"
            :value="$t(`integration.mappingPrice.${price.mappingTo}`)"
          />
        </template>
        <v-card-subtitle v-else> {{ $t('integration.card.prices.noPricesAvailableMessage') }}</v-card-subtitle>
      </v-card-text>
    
    </v-card>
  </v-flex>
</template>
