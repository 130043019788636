import { loadingStates } from "@/enumerations";
import ServiceFacade from "@/services/ServiceFacade";
import ErrorGenerator from "@/helpers/ErrorGenerator";

const state = () => ({
    tradingDashboard: {},
    cognitiveFacts: [
        "<h5>Слово «статистика» происходит от латинского status — состояние дел. <br/> В науку термин «статистика» ввёл немецкий учёный Готфрид Ахенвалль в 1746 году, предложив заменить название курса «Государствоведение», преподававшегося в университетах Германии, на «Статистику», положив тем самым начало развитию статистики как науки и учебной дисциплины.</h5>",
        "<h5>Бытует мнение, что данные статистических исследований всё чаще намеренно <br/> искажают или неправильно интерпретируют, выбирая только те данные, которые являются благоприятными для ведущего конкретное исследование[. Неправильное использование статистических данных может быть как случайным, так и преднамеренным.</h5>",
        "<h5>В книге Даррелла Хаффа (1954) «Как лгать при помощи статистики» излагается <br/>  ряд соображений по поводу использования и неправильного применения статистических данных. Некоторые авторы также проводят обзор статистических методов, используемых в определённых областях (например, Варн, Лазо, Рамос, и Риттер (2012)). Способы, позволяющие избежать неправильного толкования статистических данных включают в себя использование надлежащей схемы и исключение предвзятости при проведении исследований[12]. Злоупотребление происходит тогда, когда такие выводы «заказываются» определёнными структурами, которые намеренно или бессознательно выводят на отбор предвзятых данных или проб.</h5>",
        "<h5>В жизни, как правило, преуспевает больше других тот, кто располагает лучшей информацией.</h5>",
        "<h5>Статистика может доказать что угодно, даже правду. <br/> Ноэл Мойнихан</h5>",
        "<h5>«Мы живем в век статистики. Едва ли не в каждом своем аспекте явления природы, <br/> а также человеческая и прочая деятельность поддаются сейчас измерению при помощи статистических показателей.» <br/> Рейхман У. Дж </h5>",
        "<h5>«А между тем только ведь в этих-то толстых скучных книгах и сказана цифрами та «сущая» правда нашей жизни, о которой мы совершенно отвыкли говорить человеческим языком, и нужно только раз получить интерес к этим дробям, нулям, нуликам, к этой вообще цифровой крупе, которою усеяны статистические книги и таблицы, так все они, вся эта крупа цифр начнет принимать человеческие образы и облекаться в картины ежедневной жизни, т.е. начнет получать значение не мертвых и скучных знаков, а, напротив, значение самого разностороннейшего изображения жизни».  <br/> Г.Успенский, «Четверть лошади»</h5>"
    ],
})

const actions = {
    async fetchTradingDashboard({ commit }, samplePeriod) {
        try {
            commit('eventBus/SET_TCP_FETCHING_STATUS', loadingStates.BUSY, { root: true })
            const { status, data } = await ServiceFacade.fetchDashboard(samplePeriod);
            if (status == 200) {
                commit('SET_TRADING_DASHBOARD', data);
                commit('eventBus/SET_TCP_FETCHING_STATUS', loadingStates.SUCCESS, { root: true })
            }
        } catch (error) {
            commit(
                'eventBus/SET_TCP_FETCHING_STATUS',
                ErrorGenerator.tcpConnectionErrorCode(error),
                { root: true }
            )
        }
    }
}

// mutations
const mutations = {
    SET_TRADING_DASHBOARD(state, data) {
        state.tradingDashboard = { ...data }
    },
}

// getters
const getters = {
    tradingDashboard: (state) => state.tradingDashboard,
    randomCognitiveFact: (state) => () => {
        return state.cognitiveFacts[Math.floor(Math.random() * state.cognitiveFacts.length)];
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}