import BucketCore from "@/store/generics/bucket.core";
import BucketDashboard from "@/store/generics/bucket.dashboard";
import HashGenerator from "@/helpers/HashGenerator";
import {
    bucketNamespace
} from "@/enumerations";

const filterModel = {
    available: [],
    archived: [],
    mySales: [],
    brand: [],
    category: [],
    outletsAvailability: [],
    platformsAvailability: [],
    hasImages: [],
    hasDescription: [],
    hasParams: [],
    hasErrors: [],
}

const fullFacetCaptureModel = {
    brand: null,
    category: null,
}

const state = () => ({
    ...BucketCore.state,
    ...BucketDashboard.state,
    filters: {
        ...filterModel
    },
    fullFacetsCapture: {
        ...fullFacetCaptureModel
    },
    initialFilterChecksum: HashGenerator.sha256sum(JSON.stringify(filterModel)),
    namespace: bucketNamespace.SUPPLIERS
});

const actions = {
    ...BucketCore.actions,
    ...BucketDashboard.actions
}

// mutations
const mutations = {
    ...BucketCore.mutations,
    ...BucketDashboard.mutations
}

// getters
const getters = {
    ...BucketCore.getters,
    ...BucketDashboard.getters
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}