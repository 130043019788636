<script>
export default {
  props: {
    message: String,
  }
}
</script>

<template>
  <v-row class="void" justify="center">
    <v-card elevation="0" min-height="582" class="void pa-4">
      <v-card-text>

        <div v-if="message" class="text-h6 font-weight-regular text-center">
          {{ message }}
        </div>

        <div class="text-center">
          <v-progress-circular
            class="mt-5"
            :size="60"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>

      </v-card-text>
    </v-card>
  </v-row>
</template>
