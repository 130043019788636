<template>
  <div id="inspire">
    <sidebar />
    <v-main>
      <systembar />
      <notification />
      <product-action-sheet></product-action-sheet>
      <platforms-integration-popup></platforms-integration-popup>
      <service-content-view-popup
        :title="notifyForDisplayContent.title"
        :url="notifyForDisplayContent.modalUrl"
        :modalDescription="notifyForDisplayContent.modalDescription"
        :modalImage="notifyForDisplayContent.modalImage"
      ></service-content-view-popup>
      <v-container
        id="layout-grid"
        fluid
        grid-list-xl
        :class="isMobile ? 'px-2 py-7' : 'pa-7'"
      >
        <h5 class="layout__title mb-7">{{ title }}</h5>
        <router-view />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Systembar from "../components/systembar/Systembar";
import Sidebar from "../components/sidebar/Sidebar";
import ProductActionSheet from "../components/sheets/ProductActionSheet";
import Notification from "../components/notification/Notification";
import PlatformsIntegrationPopup from "@/components/popups/service-modals/PlatformsIntegrationPopup";
import ServiceContentViewPopup from "@/components/popups/viewers/ServiceContentViewPopup";
import ScreenResolutionMixin from "@/mixins/ScreenResolution";

export default {
  name: "DashboardLayout",
  components: {
    Systembar,
    Sidebar,
    Notification,
    ProductActionSheet,
    PlatformsIntegrationPopup,
    ServiceContentViewPopup
  },
  mixins: [ScreenResolutionMixin],
  computed: {
    ...mapGetters("core", {
      layout: "layout",
    }),
    ...mapGetters("profile", {
      company: "company",
      notifications: "notifications",
    }),
    title() {
      return this.layout.title;
    },
    notifyForDisplayContent() {
      const lastModalCheckTime =
        localStorage.getItem("last-modal-check-time") &&
        new Date(new Number(localStorage.getItem("last-modal-check-time")));
      let notifyForDisplay;

      if (!lastModalCheckTime) {
        notifyForDisplay = this.notifications.find(
          (n) => typeof n.modalUrl === "string" || typeof n.modalDescription === "string"
        );
      }
      if (lastModalCheckTime) {
        notifyForDisplay = this.notifications.find(
          (n) =>
            (typeof n.modalUrl === "string" || typeof n.modalDescription === "string") &&
            new Date(n.dateCreated).getTime() > lastModalCheckTime.getTime()
        );
      }

      return notifyForDisplay || { title: "", modalUrl: "", modalDescription: "", modalImage: "" };
    },
  },
  watch: {
    notifyForDisplayContent(newNotify) {
      if (newNotify.modalUrl?.length || newNotify.modalDescription?.length) {
        this.$store.commit("serviceModals/DISPLAY_SERVICE_CONTENT_MODAL");
      }
    },
  },
};
</script>