 <template>
  <v-app>
    <app-greetings-screen v-if="!spaReady"></app-greetings-screen>
    <!-- <router-view /> -->
    <component :is="layout" v-show="spaReady"> </component>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import AppGreetingsScreen from "@/components/screens/AppGreetingsScreen";
import "./styles/root_styles.css";

export default {
  name: "App",
  components: {
    AppGreetingsScreen,
  },
  data() {
    return {
      audioNotify: new Audio(require("@/assets/sounds/sharp-592.ogg")),
    };
  },
  computed: {
    ...mapGetters("profile", {
      company: "company",
      profileIsActive: "profileIsActive",
    }),
    ...mapGetters("datasources", {
      bucketID: "bucketID",
    }),
    layout() {
      if (this.$route.meta.layout) {
        return this.$route.meta.layout;
      }
      return "dashboard-layout";
    },
    profileChecked() {
      return this.$store.getters["profile/profileChecked"];
    },
    spaReady() {
      if (this.layout !== "dashboard-layout") return true;
      return this.profileChecked && this.layout === "dashboard-layout";
    },
  },
  methods: {
    updateOnlineStatus() {
      const info = navigator.onLine ? "В сети" : "Нет сети";
      const networkEvent = new CustomEvent("network_change", {
        detail: {
          online: navigator.onLine,
        },
      });
      this.$store.dispatch("core/setInfoNotification", info, { root: true });
      window.dispatchEvent(networkEvent);
    },
    playSoundNotification() {
      this.audioNotify.play();
    },
  },
  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    window.addEventListener("playSound", this.playSoundNotification);
  },
};
</script>