<template>
  <v-btn
    :class="['v-custom-btn', 'pa2', dense && 'dense']"
    text
    :color="color || colors.fauxCobalt"
    :height="height || 24"
    :disabled="disabled"
    @click="handler()"
  >
    <v-icon size="18">{{ icon }}</v-icon>
    <span class="ml-2">{{ label }}</span>
  </v-btn>
</template>

<script>
import ColorPaletteMixin from "@/mixins/ColorPalette";
export default {
  mixins: [ColorPaletteMixin],
  props: {
    label: String,
    icon: String,
    height: Number,
    color: String,
    dense: Boolean,
    handler: Function,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.v-custom-btn {
  /* main */
  border-radius: 8px;
  vertical-align: bottom !important;
}

.v-custom-btn > .v-btn__content > span {
  /* 16 med */
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 140% !important;
  /* or 22px */

  text-align: center;
  text-transform: initial;

  /* white */
}
.v-custom-btn.dense > .v-btn__content > span {
  font-size: 13px !important;
}
</style>