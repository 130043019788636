import '../../../../services/types';
import CustomerManagementService from '../../../../services/CustomerManagementService';

/**
 * 
 * @param {AddIntegrationDTO} inputDTO 
 */
export async function addIntegrationFeature(inputDTO) {
  try {
    await CustomerManagementService.addIntegration(inputDTO);
  } catch(error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else throw error;
  } 
}
