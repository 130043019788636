<script>
import { INTEGRATION_BUILDER_KEY } from '../../../model';
import DataParamsForm from './DataParams.form.vue';


const builderKey = INTEGRATION_BUILDER_KEY;

export default {
  name: 'OneCParamsForm',
  inject: [builderKey],
  components: {
    DataParamsForm
  },
  data() {
    return {
      params: {
        name: '1c',
        url: '',
        headerName: '',
      },
      automaticImportVariants: [
        {
          text: this.$t('integration.automaticImportVariants.NEVER'),
          value: "NEVER",
        },
        {
          text: this.$t('integration.automaticImportVariants.EVERY_1_HOUR'),
          value: "EVERY_1_HOUR",
        },
        {
          text: this.$t('integration.automaticImportVariants.EVERY_30_MIN'),
          value: "EVERY_30_MIN",
        },
        {
          text: this.$t('integration.automaticImportVariants.EVERY_15_MIN'),
          value: "EVERY_15_MIN",
        },
        {
          text: this.$t('integration.automaticImportVariants.EVERY_1_DAY'),
          value: "EVERY_1_DAY",
        },
      ]
    }
  },
  created() {
    this.builder.dataParams = this.params;
  },
  computed: {
    builder() {
      return this[builderKey];
    },
    rules() {
      return [
        (v) => (v.trim()).length > 0 || 'Required'
      ]
    },
  },
}
</script>

<template>
  <DataParamsForm>
    <v-card class="v-form-card" outlined rounded="lg">
      <v-text-field :rules="rules" :label="$t('integration.addIntegration.stepper.step.dataParams.content.name')" v-model="params.name" />
      <v-text-field :rules="rules" :label="$t('integration.addIntegration.stepper.step.dataParams.content.url')" v-model="params.url" />
      <v-text-field :rules="rules" :label="$t('integration.addIntegration.stepper.step.dataParams.content.headerName')" v-model="params.headerName" />
    </v-card>

    <v-card class="v-form-card" outlined rounded="lg">
      <v-text-field :label="$t('integration.addIntegration.stepper.step.dataParams.content.login')" v-model="params.login" />
      <v-text-field :label="$t('integration.addIntegration.stepper.step.dataParams.content.password')" v-model="params.password" />
    </v-card>

    <v-card class="v-form-card" outlined rounded="lg">
      <v-select
        :label="$t('integration.addIntegration.stepper.step.dataParams.content.automaticImport')"
        :items="automaticImportVariants"
        v-model="builder.automaticImport"
      />
    </v-card>
  </DataParamsForm>
</template>
