import { tcpServerErrorCodes } from "@/enumerations";

class ErrorGenerator {
    fromBackendErrorCode(errorCode) {
        switch (errorCode) {
            // Products
            case "PRICE_LOWER_THAN_RRP": return "Цена ниже РРЦ у товара где строгое соблюдение РРЦ";
            case "NEGATIVE_PROFIT": return "Негативная прибыль";
            case "PROFIT_TOO_LOW": return "Слишком низкая прибыль";
            case "SKU_EMPTY": return "Отсутствует обязательное поле SKU";
            case "BRAND_EMPTY": return "Отсутствует обязательное поле BRAND";
            case "KASPI_PRICE_UNKNOWN": return "Цена неизвестна";
            case "BELOW_MINIMUM_PRICE": return "Цена ниже минимальной";

            // Data Expert
            case "INSUFFICIENT_BALANCE": return "Недостаточно проверок. Пополните баланс";
            case "NO_URLS_FOUND": return "Не найдены URL ссылки у товаров. Укажите или импортируйте сылки";
            case "ALREADY_IN_PROGRESS": return "Проверка уже в процессе";
            default: return errorCode;
        }
    }
    tcpConnectionErrorCode(error) {
        let code;
        console.log(error)
        if (error.response && error.response.status) {
            code = `TCP${error.response.status}` in tcpServerErrorCodes
                ? `TCP${error.response.status}` : "TCP_UNKNOWN";
        }
        return code || "TCP_UNKNOWN";
    }
}

export default new ErrorGenerator();