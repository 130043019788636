import {
    datasources,
} from "@/enumerations";
import HintGenerator from "@/helpers/HintGenerator";
import HashGenerator from "@/helpers/HashGenerator";

class ProductDataFactory {
    produce(records, producer) {
        const productDataList = [];

        if (!Array.isArray(records)) {
            return [];
        }

        for (let indx = 0; indx < records.length; indx++) {
            const currentRecord = records[indx];
            let transformedElement = {
                ...this.refineProduct(currentRecord)
            }
            if (
                producer !== datasources["1C"] &&
                producer !== datasources["Компании"] &&
                producer !== datasources["KASPIXML"] &&
                producer !== datasources["Marketradar"]
            ) {
                const rootNode =
                    producer === datasources["Белая Техника"]
                        ? "brand"
                        : "rootCategory";

                if (
                    indx == 0 ||
                    records[indx - 1][rootNode] !== currentRecord[rootNode] ||
                    records[indx - 1].category !== currentRecord.category
                ) {
                    transformedElement = {
                        ...transformedElement,
                        groupBy: `${currentRecord[rootNode]} • ${currentRecord.category}`,
                        groupHash: HashGenerator.genRandomHash(),
                    }
                }
            }

            productDataList.push({ ...transformedElement });
        }

        return productDataList;
    }
    sort(products) {
        products.sort(function (prevProduct, currProduct) {
            if (prevProduct.rootCategory > currProduct.rootCategory) {
                return 1;
            }
            if (prevProduct.rootCategory < currProduct.rootCategory) {
                return -1;
            }

            if (prevProduct.category > currProduct.category) {
                return 1;
            }
            if (prevProduct.category < currProduct.category) {
                return -1;
            }
        });
    }
    refineProduct(document) {
        return {
            ...document,
            hashKey: HashGenerator.createHash(document.sku),
            hints: HintGenerator.forProduct(document),
            recentlyCreated: document.dateCreated ? new Date(document.dateCreated)?.getTime() >= new Date().getTime() - 1000 * 60 * 60 : false
        }
    }
    diffEntries(productAsPayload, productAsOld) {
        return Object
            .fromEntries(
                Object
                    .entries(productAsPayload)
                    .filter(([key, modernValue]) => {
                        let oldValue = productAsOld[key];
                        if (key in productAsOld) {
                            if (
                                key === 'categories' ||
                                key === 'prices' ||
                                key === 'productParts' ||
                                key === 'images' ||
                                key === 'outlets' ||
                                key === 'kaspiPp' ||
                                key === 'reservations' ||
                                key === 'params' ||
                                key === 'history' ||
                                key === 'kaspiExtraSkus' || 
                                key === 'satuProductVariants'
                            ) {
                                return JSON.stringify(oldValue) !== JSON.stringify(modernValue);
                            }
                            return oldValue !== modernValue;
                        }
                        if (!(key in productAsOld)) {
                            if (key === 'productType') {
                                return false;
                            }
                            if (
                                key === 'prices' ||
                                key === 'productParts' ||
                                key === 'images' ||
                                key === 'reservations' ||
                                key === 'params' ||
                                key === 'kaspiExtraSkus' ||
                                key === 'satuProductVariants'
                            ) {
                                return !!productAsPayload[key]?.length;
                            }
                        }
                        return true;
                    })
                    .map(
                        ([key, modernValue]) => {
                            if (key === "dealerPrice"
                                || key === "rrPrice"
                                || key === "margin"
                                || key === "price"
                                || key === "minPrice"
                                || key === "minProfitPercent"
                                || key === "discountPercent"
                                || key === "marginPercent"
                                || key === "priceOzon"
                                || key === "priceForte"
                                || key === "priceJmart"
                                || key === "priceHalyk"
                                || key === "marginHalyk"
                                || key === "marginForte"
                                || key === "marginJmart"
                                || key === "kaspiFeePercent") {
                                let isDumpedIndicator = productAsOld[key] >= 0 && (!modernValue || modernValue < 0);
                                if (isDumpedIndicator) {
                                    return [key, -1]
                                }
                            }
                            return [key, modernValue]
                        }
                    )
            );
    }
    specificationCast(spec) {
        if (Array.isArray(spec)) {
            return spec.map(item => {
                return {
                    name: item.name,
                    value: item.value.length > 0 && /^[0-9]*$/gmi.test(item.value) ? parseInt(item.value) : item.value
                }
            });
        }
        else if (typeof spec === 'object') {
            return {
                name: spec.name,
                value: spec.value.length > 0 && /^[0-9]*$/gmi.test(spec.value) ? parseInt(spec.value) : spec.value
            }
        }
        return undefined;
    }
    sheetsToProducts(inputSheetsFile, fileTypes) {
        const productsMap = {};

        for (const { filename, rows } of inputSheetsFile) {
            const fileType = fileTypes[filename];
            const filteredRecords = rows.filter(
                (row) => Array.isArray(row)
                    && row.length >= 3
                    && row[0] && row[1] && /\w+/gm.test(row[1])
                    && typeof row[2] === 'number'
            );

            for (const [model, sku, numerical] of filteredRecords) {
                if (!(sku in productsMap)) {
                    productsMap[sku] = {
                        model,
                        rrPrice: 0,
                        dealerPrice: 0,
                        outlets: {}
                    }
                }
                if (!fileType.numericalColumn) {
                    const outletId = fileType["outletId"];
                    productsMap[sku]["outlets"][outletId] = numerical;
                }
                else {
                    const col = fileType.numericalColumn
                    productsMap[sku][col] = numerical;
                }
            }
        }

        return productsMap;
    }
    groupWarehousesByCompositeSuites(inventory, outletsRef) {
        let warehouseNameSet = new Set();
        let warehouseInventoryRef = new Array();

        // 1. Map of warehouses
        for (const cursor of inventory) {
            if (typeof cursor.outlets === 'object') {
                for (const warehouseID in cursor.outlets) {
                    warehouseNameSet.add(warehouseID)
                }
            }
        }
        // 2. Reduce
        for (const warehouseID of warehouseNameSet) {
            const warehouseName = outletsRef.find(pair => pair.id === warehouseID)?.name || warehouseID;
            let completeSuitesCount = 0,
                available = false;

            if (inventory
                .every(
                    cursor => cursor.outlets?.[warehouseID]
                        && cursor.outlets[warehouseID]?.available
                        && cursor.outlets[warehouseID]?.quantity > 0
                )
            ) {
                completeSuitesCount = Math.min(
                    ...inventory.map(cursor => cursor.outlets?.[warehouseID]?.quantity || 0)
                )
                available = true;
            }

            warehouseInventoryRef.push({
                warehouseID,
                warehouseName,
                completeSuitesCount,
                available
            })
        }

        warehouseNameSet.clear();
        return warehouseInventoryRef;
    }
}

export default new ProductDataFactory();