export default {
    state: {
        productActionSheet: {
            selected: [],
            all: false
        }
    },
    actions: {
        clearDashboard({ dispatch }) {
            dispatch('setSelectedProducts', []);
            dispatch('pullOffProducts');
        },
        /*      Product Action sheet    */
        setSelectedProducts({ commit }, selected) {
            commit('SET_SELECTED_PRODUCTS', selected);
        },
        selectAllProducts({ commit }) {
            commit('MARK_ALL_PRODUCTS', true);
        },
        pullOffProducts({ commit }) {
            commit('PULL_ALL_PRODUCTS', false);
        }
    },
    mutations: {
        MARK_ALL_PRODUCTS(state, flag = true) {
            state.productActionSheet.all = flag;
        },
        PULL_ALL_PRODUCTS(state, flag = false) {
            state.productActionSheet.all = flag;
        },
        SET_SELECTED_PRODUCTS(state, selected) {
            state.productActionSheet.selected = selected;
        }
    },
    getters: {
        productActionSheet: (state) => state.productActionSheet
    }
}