<script>
import { INTEGRATION_KEY } from './constants';
import ScreenResolutionMixin from "@/mixins/ScreenResolution";
import SectionField from './SectionField';
import { EditSyncParameters } from '@/features/integration/editSyncParameters';

export default {
  inject: [INTEGRATION_KEY],
  mixins: [ScreenResolutionMixin],
  components: {
    SectionField,
    EditSyncParameters
  },
  data() {
    return {
      editMode: false,
    }
  },
  computed: {
    integration() {
      return this[INTEGRATION_KEY];
    },
  }
}
</script>

<template>
  <v-flex xs12 md12 lg4 class="void">
    <v-card elevation="4" :class="['void py-5 px-7', !isMobile && 'mr-4']" tile flat height="100%">
      
      <v-card-title class="font-weight-regular text-h6 void d-flex justify-space-between mb-8">
        {{ $t('integration.card.syncParams.title') }}

        <v-btn v-if="!editMode" icon class="text-right" @click="editMode=!editMode">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-card-title>

      <v-expand-transition>
        <EditSyncParameters
          v-if="editMode"
          @canceled="editMode=false"
          @saved="editMode=false"
          :id="integration.id"
          :syncParams="integration.syncParameters"
          class="void"
        />
      </v-expand-transition>
      
      <v-card-text v-if="!editMode" class="void">
        <SectionField
          :prop="$t('integration.card.syncParams.price')"
          :value="$t(`integration.syncVariants.${integration.syncParameters.syncPrice}`)"
        />

        <SectionField
          :prop="$t('integration.card.syncParams.outlets')"
          :value="$t(`integration.syncVariants.${integration.syncParameters.syncOutlets}`)"
        />
        
        <SectionField
          :prop="$t('integration.card.syncParams.images')"
          :value="$t(`integration.syncVariants.${integration.syncParameters.syncImages}`)"
        />
        
        <SectionField
          :prop="$t('integration.card.syncParams.description')"
          :value="$t(`integration.syncVariants.${integration.syncParameters.syncDescription}`)"
        />
        
        <SectionField
          :prop="$t('integration.card.syncParams.params')"
          :value="$t(`integration.syncVariants.${integration.syncParameters.syncParams}`)"
        />
        
        <v-divider class="pb-5"/>

        <SectionField
          :prop="$t('integration.card.syncParams.notFoundProductsAction')"
          :value="$t(`integration.actionVariants.${integration.syncParameters.notFoundProductsAction}`)"
        />

        <SectionField
          :prop="$t('integration.card.syncParams.sourceProductsOnly')"
          :value="$t(`label.${integration.syncParameters.syncSourceProductsOnly ? 'yes' : 'no'}`)"
        />
      </v-card-text>
    </v-card>
  </v-flex>
</template>
