<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1380"
    overlay-opacity="0.25"
    scrollable
    :fullscreen="isMobile"
  >
    <v-card>
      <v-toolbar :color="colors.fauxCobalt" flat>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <template v-slot:extension>
          <v-tabs
            v-model="tab"
            dark
            align-with-title
            v-show="!syncActive && !hasErrors"
          >
            <v-tabs-slider :color="colors.white"></v-tabs-slider>

            <v-tab v-for="tab in tabs" :key="tab.id" :href="'#'+tab.id">
              {{ tab.name }}
            </v-tab>
          
            <v-tab  href="#integrationBuilder">добавить интеграцию</v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <Loading v-if="fetching || syncActive" :message="loadingMessage" />
      <Error v-else-if="hasErrors" />
      
      <v-tabs-items class="overflow-auto" v-model="tab" v-else>
        <v-tab-item
          v-for="integration in tabs"
          :key="integration.id"
          :value="integration.id"
        >
          <IntegrationCard :integration="integration" />
        </v-tab-item>

        <v-tab-item value="integrationBuilder">
          <v-row class="void" justify="center">
            <v-card elevation="0" class="void pa-4">
              <v-card-text class="overflow-auto">
                <IntegrationBuilderVue @finished="addIntegration" />
              </v-card-text>
            </v-card>
          </v-row>
        </v-tab-item>

      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import ColorPaletteMixin from "@/mixins/ColorPalette";
import ScreenResolutionMixin from "@/mixins/ScreenResolution";
import { IntegrationBuilderVue } from '@/widgets/integration/IntegrationBuilder';
import { IntegrationCard } from './integrationCard';
import Loading from './Loading';
import Error from './Error';

export default {
  name: "PlatformsIntegrationPopup",
  mixins: [ColorPaletteMixin, ScreenResolutionMixin],
  components: {
    IntegrationBuilderVue,
    IntegrationCard,
    Loading,
    Error
  },
  data() {
    return {
      tab: null,
      unsubscribe: null,
    };
  },
  computed: {
    ...mapGetters("serviceModals", {
      platformsIntegrationServiceModal: "platformsIntegrationServiceModal",
    }),
    fetching() {
      return this.platformsIntegrationServiceModal.fetching;
    },
    syncActive() {
      return this.platformsIntegrationServiceModal.syncActive;
    },
    hasErrors() {
      return this.platformsIntegrationServiceModal.hasErrors;
    },
    activeSource() {
      return this.platformsIntegrationServiceModal.sources
        .find((el) => el.id === this.tab) || {};
    },
    loadingMessage() {
      return this.fetching
        ? "Загрузка импортов данных..."
        : `Синхронизация данных по ${this.activeSource?.name}...`;
    },
    tabs() {
      return this.platformsIntegrationServiceModal.sources;
    },
    dialog: {
      get() {
        return this.platformsIntegrationServiceModal.dialog;
      },
      set(dialog) {
        !dialog && this.$store.commit("serviceModals/CLOSE_PLATFORMS_INTEGRATION_MODAL");
      },
    },
  },
  methods: {
    async addIntegration(id) {
      await this.$store.dispatch("serviceModals/fetchPlatformIntegrations");
      this.tab = id;
    },
    async onStoreSubscribe(mutation) {
      if (mutation.type === "serviceModals/DISPLAY_PLATFORMS_INTEGRATION_MODAL") {
        await this.$store.dispatch("serviceModals/fetchPlatformIntegrations");
        
        // установка первой интеграции, если есть интеграции
        if (this.tabs.length) this.tab = this.tabs[0].id;
      }
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe(this.onStoreSubscribe);
  },
  beforeDestroy() {
    this.unsubscribe && this.unsubscribe();
  },
};
</script>
