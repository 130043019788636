import Vue from 'vue';
import TimeoutGenerator from "@/helpers/TimeoutGenerator";
import ProductDataFactory from "@/models/ProductDataFactory";
import { getField } from 'vuex-map-fields';

const eventTypes = {
    productAnimation: 0xAF,
    refreshBucket: 0xba,
    refreshInventory: 0xbc,
    notifyAboutNewInventoryProducts: 0xbd
}

const gradientAnimations = {
    classes: {
        success: "green-gradient-animation",
        slack: "bloody-gradient-animation",
        discharge: "hanblue-gradient-animation"
    },
    timeout: 3000
}

const distinctArrays = (arrX) => {
    let codes = Array.from(new Set([...arrX].map(x => x.sku)));
    return codes
        .map((sku) => {
            return arrX.find((x) => x.sku === sku)
        });
}

const state = () => ({
    tcpFetchingStatus: null,
    animationEvents: [],
    deferredBuffer: [],
    instantSystemEvent: 0x00,
    recentlyCreatedProducts: [],
    recentlyDeleted: [],
    recentlyDiscontinued: [],
    recentlyClickedProduct: "",
    currentlyOpenProduct: null
});

const actions = {
    resetSandbox({ dispatch }) {
        dispatch('clearRecentlyCreatedProducts');
        dispatch('clearRecentlyDeleted');
        dispatch('clearRecentlyDiscontinued');
        dispatch('closeSystemEvent');
        dispatch('clearRecentlyClickedProduct');
    },
    registerSystemEvent({ commit }, code) {
        commit('SET_SYSTEM_EVENT', code);
    },
    closeSystemEvent({ commit }) {
        commit('SET_SYSTEM_EVENT', 0x00);
    },
    async pushAnimationEvents({ state, commit, dispatch }, { products, withDubbedAnimation = false }) {
        const events = products.map(({ sku }) => {
            let gradientAnimationIn = undefined;

            if (
                state.recentlyDeleted.some((deleted) => deleted?.sku === sku)
            ) {
                gradientAnimationIn = gradientAnimations.classes.slack;
            } else if (
                state.recentlyDiscontinued.some((disc) => disc?.sku === sku)
            ) {
                gradientAnimationIn = gradientAnimations.classes.discharge;
            } else {
                gradientAnimationIn = gradientAnimations.classes.success;
            }
            return { sku, e: eventTypes.productAnimation, gradientAnimationIn }
        });
        if (withDubbedAnimation) {
            commit('SET_DEFERRED_BUFFER', [...events]);
        }
        await dispatch("performAnimations", events);
    },
    async recoverAnimationsFromDeferredBuffer({ state, dispatch, commit }) {
        await dispatch("performAnimations", state.deferredBuffer);
        commit('SET_DEFERRED_BUFFER', []);
    },
    async performAnimations({ commit }, events) {
        await TimeoutGenerator.timeWait(100);
        commit('PUBLISH_ANIMATION_EVENTS', events);
        await TimeoutGenerator.timeWait(gradientAnimations.timeout);
        commit('CUT_ANIMATION_EVENTS', eventTypes.productAnimation);
    },
    refreshProductTag({ state, commit, rootGetters }, { newTag, oldTag }) {
        state.recentlyCreatedProducts.forEach((element, index) => {
            if (Array.isArray(element.tags)) {
                let tagIndex = element.tags.findIndex(t => t == oldTag.name)
                if (tagIndex >= 0) {
                    let copiedProduct = { ...element };
                    copiedProduct.tags[tagIndex] = newTag.name;
                    commit('CHANGE_RECENTLY_CREATED_PRODUCT', {
                        index,
                        product: copiedProduct
                    })
                }
            }
        });
        rootGetters["datasources/bucketProducts"].forEach((element) => {
            if (Array.isArray(element.tags)) {
                let tagIndex = element.tags.findIndex(t => t == oldTag.name)
                if (tagIndex >= 0) {
                    let copiedProduct = { ...element };
                    copiedProduct.tags[tagIndex] = newTag.name;
                    commit('datasources/CHANGE_BUCKET_PRODUCT', copiedProduct, { root: true });
                }
            }
        });
    },
    addLatestProductUpdates({ state, commit, dispatch }, products) {
        for (const iterator of products) {
            let indxOfRecentlyCreatedRef = state.recentlyCreatedProducts
                .findIndex((recentlyProduct) => recentlyProduct.sku === iterator.sku);

            if (indxOfRecentlyCreatedRef >= 0) {
                commit('CHANGE_RECENTLY_CREATED_PRODUCT', {
                    index: indxOfRecentlyCreatedRef,
                    product: iterator
                })
            } else {
                commit('datasources/CHANGE_BUCKET_PRODUCT', iterator, { root: true });
            }
        }
        dispatch('pushAnimationEvents', { products });
    },
    async createRecentlyCreatedProducts({ commit, state, dispatch }, {
        products,
        canCreateDeferredJobs
    }) {
        let newProducts = products.map(item => {
            return {
                ...item, recentlyCreated: true, tags: []
            }
        });
        let mergedArrays = distinctArrays([
            ...state.recentlyCreatedProducts,
            ...newProducts
        ]).reverse();

        for (const iterator of mergedArrays) {
            if (state.recentlyDiscontinued.some(rec => rec.sku === iterator.sku)) {
                commit('ERASE_RECENTLY_DISCONTINUED_PRODUCT', iterator.sku);
            }
            if (state.recentlyDeleted.some(rec => rec.sku === iterator.sku)) {
                commit('ERASE_RECENTLY_DELETED_PRODUCT', iterator.sku);
            }
        }

        commit('SET_RECENTLY_CREATED_PRODUCTS', mergedArrays);
        await dispatch('pushAnimationEvents', { products, withDubbedAnimation: canCreateDeferredJobs });
    },
    createRecentlyDeletedProducts({ commit, state, dispatch }, products) {
        let oldProducts = products.map(item => {
            return {
                ...item, recentlyDeleted: true
            }
        });
        let union = distinctArrays([
            ...state.recentlyDeleted,
            ...oldProducts
        ]);

        for (const iterator of union) {
            if (state.recentlyCreatedProducts.some(rec => rec.sku === iterator.sku)) {
                commit('ERASE_RECENTLY_CREATED_PRODUCT', iterator.sku);
            }
        }

        commit('SET_RECENTLY_DELETED_PRODUCTS', union);
        dispatch('pushAnimationEvents', { products });
    },
    createRecentlyDiscontinuedProducts({ commit, state, dispatch }, products) {
        let oldProducts = products.map(item => {
            return {
                ...item, recentlyDiscontinued: true
            }
        });
        let union = distinctArrays([
            ...state.recentlyDiscontinued,
            ...oldProducts
        ]);

        for (const iterator of union) {
            if (state.recentlyCreatedProducts.some(rec => rec.sku === iterator.sku)) {
                commit('ERASE_RECENTLY_CREATED_PRODUCT', iterator.sku);
            }
        }

        commit('SET_RECENTLY_DISCONTINUED_PRODUCTS', union);
        dispatch('pushAnimationEvents', { products });
    },
    modifyNonInteractiveProducts({ state, commit }, products) {
        let D = state.recentlyDeleted;
        let S = state.recentlyDiscontinued;

        commit('SET_RECENTLY_DELETED_PRODUCTS', D.filter(x => !products.some(d => x.sku === d.sku)));
        commit('SET_RECENTLY_DISCONTINUED_PRODUCTS', S.filter(x => !products.some(d => x.sku === d.sku)));
    },
    clearRecentlyCreatedProducts({ commit }) {
        commit('SET_RECENTLY_CREATED_PRODUCTS', []);
    },
    clearRecentlyDeleted({ commit }) {
        commit('SET_RECENTLY_DELETED_PRODUCTS', []);
    },
    clearRecentlyDiscontinued({ commit }) {
        commit('SET_RECENTLY_DISCONTINUED_PRODUCTS', []);
    },
    setRecentlyClickedProduct({ commit }, sku)  {
        commit('SET_RECENTLY_CLICKED_PRODUCT', sku);
    },
    clearRecentlyClickedProduct({ commit }) {
        commit('SET_RECENTLY_CLICKED_PRODUCT', "");
    },
}

// mutations
const mutations = {
    SET_SYSTEM_EVENT(state, event) {
        state.instantSystemEvent = event;
    },
    SET_TCP_FETCHING_STATUS(state, code) {
        state.tcpFetchingStatus = code;
    },
    CUT_ANIMATION_EVENTS(state, eventType) {
        state.animationEvents = [...state.animationEvents.filter(item => item.e !== eventType)];
    },
    PUBLISH_ANIMATION_EVENTS(state, events) {
        state.animationEvents = [
            ...state.animationEvents,
            ...events
        ]
    },
    SET_DEFERRED_BUFFER(state, deferredBuffer = []) {
        state.deferredBuffer = [
            ...deferredBuffer
        ];
    },
    SET_RECENTLY_CREATED_PRODUCTS(state, products) {
        state.recentlyCreatedProducts = [
            ...ProductDataFactory.produce(products)
        ]
    },
    CHANGE_RECENTLY_CREATED_PRODUCT(state, data) {
        Vue.set(state.recentlyCreatedProducts, data.index, {
            ...ProductDataFactory.refineProduct(data.product)
        });
    },
    ERASE_RECENTLY_CREATED_PRODUCT(state, sku) {
        let indx = state.recentlyCreatedProducts.findIndex(rec => rec.sku == sku);
        indx >= 0 && state.recentlyCreatedProducts.splice(indx, 1);
    },
    SET_RECENTLY_DELETED_PRODUCTS(state, products) {
        state.recentlyDeleted = [
            ...products
        ]
    },
    ERASE_RECENTLY_DELETED_PRODUCT(state, sku) {
        let indx = state.recentlyDeleted.findIndex(rec => rec.sku == sku);
        indx >= 0 && state.recentlyDeleted.splice(indx, 1);
    },
    SET_RECENTLY_DISCONTINUED_PRODUCTS(state, products) {
        state.recentlyDiscontinued = [
            ...products
        ]
    },
    ERASE_RECENTLY_DISCONTINUED_PRODUCT(state, sku) {
        let indx = state.recentlyDiscontinued.findIndex(rec => rec.sku == sku);
        indx >= 0 && state.recentlyDiscontinued.splice(indx, 1);
    },
    SET_RECENTLY_CLICKED_PRODUCT(state, sku) {
        state.recentlyClickedProduct = sku;
    },
    SET_CURRENTLY_OPEN_PRODUCT(state, data) {
        state.currentlyOpenProduct = Object.freeze(data);
    },
}

// getters
const getters = {
    tcpFetchingStatus: (state) => state.tcpFetchingStatus,
    instantSystemEvent: (state) => state.instantSystemEvent,
    animationEvents: (state) => state.animationEvents.filter(item => item.e === eventTypes.productAnimation),
    recentlyCreatedProducts: (state) => state.recentlyCreatedProducts,
    recentlyDeleted: (state) => state.recentlyDeleted,
    recentlyDiscontinued: (state) => state.recentlyDiscontinued,
    recentlyClickedProduct: (state) => state.recentlyClickedProduct,
    currentlyOpenProduct: (state) => state.currentlyOpenProduct,
    getField
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}