<template>
  <div id="inspire">
    <sidebar />
    <v-main>
      <systembar />
      <notification />
      <v-container id="layout-grid" fluid grid-list-xl class="pa-7">
        <h5 class="layout__title mb-7">{{ title }}</h5>
        <router-view />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Systembar from "../components/systembar/Systembar";
import Sidebar from "../components/sidebar/Sidebar";
import Notification from "../components/notification/Notification";

export default {
  name: "BasicLayout",
  components: {
    Systembar,
    Sidebar,
    Notification
  },
  computed: {
    ...mapGetters("core", {
      layout: "layout",
    }),
    title() {
      return this.layout.title;
    },
  },
};
</script>