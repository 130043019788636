export const notificatonStatuses = {
    error: 'error',
    warn: 'warn',
    success: 'success'
}

export const notificationColors = {
    [notificatonStatuses.error]: 'red',
    [notificatonStatuses.warn]: 'yellow',
    [notificatonStatuses.success]: 'green',
}

export const loadingStates = {
    BEGIN: 'begin',
    PENDING: 'pending',
    BUSY: 'busy',
    SUCCESS: 'success',
    ERROR: 'error'
}

export const tcpServerErrorCodes = {
    "TCP_UNKNOWN": {
        icon: "mdi-connection",
        msg: "Неизвестная ошибка! Проверьте соединение с интернетом или обратитесь в тех.поддержку!"
    },
    "TCP400": {
        icon: "mdi-content-save-alert",
        msg: "Неправильный запрос"
    },
    "TCP401": {
        icon: "mdi-account-cancel",
        msg: "Вы не авторизованы системой! Попробуйте перелогиниться в систему!"
    },
    "TCP403": {
        icon: "mdi-lock",
        msg: "Доступ закрыт! Обратитесь в тех.поддержку!"
    },
    "TCP404": {
        icon: "mdi-cancel",
        msg: "По вашему запросу ничего не найдено!"
    },
    "TCP408": {
        icon: "mdi-close-network-outline",
        msg: "Сервер закрыл соединение, так как время ожидания передачи данных от клиента истекло!"
    },
    "TCP500": {
        icon: "mdi-bug",
        msg: "Произошла внутренная ошибка на сервере! Обратитесь в тех.поддержку!"
    },
    "TCP503": {
        icon: "mdi-server-remove",
        msg: "Сервер временно не имеет возможности обрабатывать запросы по техническим причинам!"
    }
}

export const hotKeys = {
    CREATE_PRODUCT: {
        combinations: ['altKey', 'KeyN'],
        print: "ALT + N",
        tip: "Создать товар"
    },
    FORWARD_TO_SUPPLIERS: {
        combinations: ['altKey', 'KeyO'],
        print: "ALT + O",
        tip: "Быстрый поиск товаров по вашим поставщикам"
    },
    FOCUS_TO_SEARCHBOX: {
        combinations: ['ctrlKey', 'KeyK'],
        print: "CTRL + K",
        tip: "Фокусируйтесь на поисковой строке"
    },
    CANCEL: {
        code: "Escape",
        print: "Escape"
    }
}

export const bucketNamespace = {
    DATASOURCES: 'datasources',
    SUPPLIERS: 'suppliers'
}

export const sortingTypes = [
    { type: "default", value: "По умолчанию" },
    { type: "dateCreatedAsc", value: "Старые товары ↑" },
    { type: "dateCreatedDesc", value: "Новые товары ↓" },
    { type: "priceDesc", value: "Дорогие ↑" },
    { type: "priceAsc", value: "Дешевые ↓" },
    { type: "profitDesc", value: "По прибыли ↑" },
    { type: "relevance", value: "По поиску" }
]

export const actions = {
    SHIFT_PRODUCTS_UP: 0x1A,
    REMOVE_PRODUCTS: 0x1B
}

export const onlinePlatforms = {
    ALL: 'all',
    KASPI: 'kaspi',
    FORTE: 'forte',
    Satu: 'satu',
    Jmart: 'jmart',
    'Tomas.kz': 'tomas',
    Wildberries: 'wildberries',
    YandexMarket: 'yandexmarket',
    Lamoda: 'lamoda',
    HALYK: 'halyk',
    OZON: 'ozon',
    Website: 'website',
    Website2: 'website2',
    WILDBERRIES: 'wildberries',
}

export const datasources = {
    '1C': '1c',
    AKCENT: 'akcent',
    AKCENT2: 'akcent2',
    'Al-Style': 'alstyle',
    'Белая Техника': 'bv',
    'STN': 'stn',
    'Azerti': 'azerti',
    'Компортал': 'komportal',
    'Компании': 'manual',
    'Astro BT': 'astrobt',
    'KASPIXML': 'kaspixml',
    'MARVEL KAZAKHSTAN': 'marvel',
    'RESANTA': 'resanta',
    PRIMETOOLS: 'primetools',
    ASBIS: 'asbis',
    TESTSUPPLIER: 'testsupplier',
    TECHNOGRAD: 'tgrad',
    'Marketradar': 'marketradar'
}

export const platfotmIntegrationStates = {
    SUCCESS: {
        ruLocal: 'Успешно',
        colorText: 'light-green--text'
    },
    ERROR: {
        ruLocal: 'Ошибка',
        colorText: 'red--text'
    },
    PROGRESS: {
        ruLocal: 'В процессе',
        colorText: 'amber--text'
    }
}

export const glyphs = {
    KZT: "₸",
    PERCENT: "%"
}

export const salesTriggers = {
    SALE_ON: 0x01,
    SALE_OFF: 0x00
}

export const confirmationTypes = {
    DELETE_PRODUCT: 1,
    REMOVE_PRODUCTS: 0x02,
    ADD_PRODUCT: 0x03,
    CREATE_PRODUCT: 0x05,
    SAVE_PRODUCT: 0x06,
    CHARGE_PRODUCTS: 10,
    REJECT_PRODUCTS: 20,
    DEACTIVATE_PRODUCT: 40,
    SELECT_DELIVERY_POINTS: 0x050,
    PRICE_MARKUP: 0xAA,
    PRICE_MARGIN: 0xAB,
    PARTIAL_UPDATE_PRODUCT: 0xAC,
    PRICE_DISCOUNT: 0xAD,
    TRADING_BOTS: 0xAE,
    NEST_CATEGORY_IN_PRODUCTS: 0xCD,
    NEST_TAGS_IN_PRODUCTS: 0xDE,
    NEST_PLATFORMS_IN_PRODUCTS: 0xEE,
    CRAFT_COMPOSITE_PRODUCT: 0xEF,
    INVENTORY_MODIFIED: 0xFA,
    CRAFT_DUPLICATE_PRODUCT: 0xFF,
}

export const lowStockActions = {
    NOTIFICATION: "notification",
    REMOVE_OM_SALE: "remove_from_sale",
    NOTHING: "nothing",
    PREORDER_2_DAYS: "preorder_2_days",
    PREORDER_5_DAYS: "preorder_5_days",
    PREORDER_WEEK: "preorder_week",
    PREORDER_14_DAYS: "preorder_14_days",
    PREORDER_20_DAYS: "preorder_20_days",
    PREORDER_30_DAYS: "preorder_30_days",
    PREORDER_45_DAYS: "preorder_45_days",
}

export const orderStates = {
    NEW: "NEW",
    SIGN_REQUIRED: "SIGN_REQUIRED",
    KASPI_DELIVERY: "KASPI_DELIVERY",
    DELIVERY: "DELIVERY",
    ARCHIVE: "ARCHIVE",
    PICKUP: "PICKUP",
}

export const orderActions = {
    ACCEPT: "ACCEPT",
    CANCEL: "CANCEL",
}

export const alarmTypes = {
    ORDERS_SYNC: 0XFA
}

export const importPlugins = {
    RESANTA_EXCEL: "RESANTA_EXCEL"
}

export const xlsImportOperationTypes = {
    DEFAULT: 0xAA,
    BATCH: 0xBA
}

export const xlsWorkerCommands = {
    SWEEP: 0x00,
    READ: 0x0A,
    READ$BUFFERIZE: 0x0B,
    MODIFY$BUFFER$SAVE: 0xCC
}

export const xlsResolvers = {
    OUT$SHEET: 0xCA,
    OUT$STREAM: 0xCC
}

export const alerts = {
    PRODUCT_LIMIT_EXCEED: 'product_limit_exceed',
    PRODUCT_LIMIT_ABOUT_EXCEED: 'product_limit_about_exceed',
    ACCOUNT_SUSPENDED: 'account_suspended',
    SUBSCRIPTION_EXPIRING: 'subscription_expiring',
    TRIAL_EXPIRING: 'trial_expiring',
}

export const reportTypes = {
    KASPI_URLS: 1,
    ALL_PRODUCTS: 2,
}

export const xlsImportHeaders = {
    SKU: {
        key: "sku",
        localization: "sku",
    },
    MODEL: {
        key: "model",
        localization: "наименование",
    },
    BRAND: {
        key: "brand",
        localization: "производитель",
    },
    PRICE: {
        key: "price",
        localization: "цена продажи",
    },
    PRICE_HALYK: {
        key: "price_halyk",
        localization: "цена продажи halyk",
    },
    PRICE_FORTE: {
        key: "price_forte",
        localization: "цена продажи forte",
    },
    PRICE_JMART: {
        key: "price_jmart",
        localization: "цена продажи jmart",
    },
    DEALER_PRICE: {
        key: "dealer_price",
        localization: "закупочная цена",
    },
    PREORDER: {
        key: "preorder",
        localization: "предзаказ",
    },
    KASPI_URL: {
        key: "kaspi_url",
        localization: "ссылка kaspi",
    },
    MARGIN_PERCENT: {
        key: "margin_percent",
        localization: "накрутка %",
    },
    MARGIN: {
        key: "margin",
        localization: "наценка на закупочную %",
    },
    MARGIN_HALYK: {
        key: "margin_halyk",
        localization: "наценка на закупочную для halyk market %",
    },
    MARGIN_JMART: {
        key: "margin_jmart",
        localization: "наценка на закупочную для jmart %",
    },
    MARGIN_FORTE: {
        key: "margin_forte",
        localization: "наценка на закупочную для forte %",
    },
    EXPORT_SKU: {
        key: "export_sku",
        localization: "внешний артикул",
    },
    KASPI_PPS: {
        key: "kaspi_pps",
        localization: "точки вывоза каспи",
    },
}