<script>
import { INTEGRATION_BUILDER_KEY } from '../../../model';


const builderKey = INTEGRATION_BUILDER_KEY;

/**
 * Форм параметров данных.
 */
export default {
  name: 'DataParamsForm',
  inject: [builderKey],
  computed: {
    builder() {
      return this[builderKey];
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    }
  }
}
</script>

<template>
  <v-form ref="form" class="data-params-form d-flex flex-column">
    <slot></slot>
  </v-form>
</template>

<style>
.data-params-form {
  gap: 1rem;
}
</style>
