<script>
import { INTEGRATION_KEY } from './constants';
import IntegrationHeader from './Header';
import DataParamsSection from './DataParams.section';
import PriceSection from './Price.section';
import SyncParams from './SyncParams.section';
import ScreenResolutionMixin from "@/mixins/ScreenResolution";

export default {
  components: {
    IntegrationHeader,
    DataParamsSection,
    PriceSection,
    SyncParams
  },
  mixins: [ScreenResolutionMixin],
  props: {
    integration: {
      type: Object,
      required: true,
    },
  },
  provide() {
    return {
      [INTEGRATION_KEY]: this.integration,
    }
  }
}
</script>

<template>
  <v-card flat>
    
    <IntegrationHeader />

    <v-card-text :class="[!isMobile ? 'px-7' : 'px-0', 'pt-0 pb-11']">
      <v-layout row wrap class="fill-height void">
        <DataParamsSection />

        <SyncParams />

        <PriceSection />
      </v-layout>
    </v-card-text>
  </v-card>
</template>
