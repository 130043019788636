<script>
import { INPUTS_KEY } from './constants';
import { INTEGRATION_BUILDER_KEY } from '../../../model';
import { PRICE_MAPPING } from '@/entities/integration';

const builderKey = INTEGRATION_BUILDER_KEY;

export default {
  props: {
    price: Object
  },
  inject: [INPUTS_KEY, builderKey],
  methods: {
    inputs() {
      return this[INPUTS_KEY]();
    },
  },
  computed: {
    builder() {
      return this[builderKey];
    },
    rules() {
      return [
        (val) => {
          if (val === 'IGNORE' || !this.inputs()) return true;

          const items = this.inputs().filter(inp => inp.type === val);
          if (items.length > 1) return this.$t('integration.addIntegration.stepper.step.priceType.errors.dublicatPriceError');

          return true
        }
      ]
    }
  },
  data() {
    return {
      error: null,
      mappintPriceTypes: Object.values(PRICE_MAPPING).map((priceType) => ({
        text: this.$t(`integration.mappingPrice.${priceType}`),
        value: priceType,
      })),
      type: PRICE_MAPPING.IGNORE
    }
  },
}
</script>

<template>
  <v-card width="100%">
    <v-card-text class="d-flex justify-space-between flex-sm-column flex-md-row flex-nowrap">
      <v-flex sm12 md4>
        <v-card-subtitle class="py-0">
          {{ $t('integration.addIntegration.stepper.step.priceType.content.sourcePriceLabel', {name: builder.dataParams.name}) }}
        </v-card-subtitle>
        <v-card-title class="pt-0">
          {{ price.name }}
        </v-card-title>
        
        <v-card-subtitle>
          {{ price.id }}
        </v-card-subtitle>
      </v-flex>

      <v-flex sm12 md4 class="text-md-center text-sm-start pa-sm-4 pa-md-0">
        {{ $t('integration.addIntegration.stepper.step.priceType.content.productCountLabel', {count: price.productCount ? price.productCount : '-'}) }}
      </v-flex>
      
      <v-flex sm12 md4 class="d-flex justify-end pr-4">
        <v-select
          :rules="rules"
          class="flex-shrink-0 flex-grow-0"
          style="width: 250px"
          :label="$t('integration.addIntegration.stepper.step.priceType.content.mappingToLabel')"
          :items="mappintPriceTypes"
          v-model="type"
        />
      </v-flex>
    </v-card-text>
  </v-card>
</template>
