import CustomerManagementService from '../../../../services/CustomerManagementService';
import store from '@/store'

/**
 * 
 * @param {string} id 
 * @param {
 *  {
 *    id: string;
 *    name: string;
 *    mappingTo: "IGNORE" | "RETAIL" | "DEALER" | "MIN_PRICE"
 *  }[]
 * } inputDTO 
 */
export async function editPriceParameters(id, inputDTO) {
  try {
    const integration = store
      .getters['serviceModals/platformsIntegrationServiceModal']
      .sources.find(el => el.id === id);
      
    const res = await CustomerManagementService.editIntegration({
      id,
      prices: inputDTO,
      sourceParameters: integration.sourceParameters,
      syncParameters: integration.syncParameters,
    });

    integration.prices = res.data.prices;
  } catch(error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else error;
  }
}
