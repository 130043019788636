<script>
import { INTEGRATION_BUILDER_KEY } from '../../../model';
import DataParamsForm from './DataParams.form.vue';


const builderKey = INTEGRATION_BUILDER_KEY;

export default {
  name: 'MoyskladParamsForm',
  inject: [builderKey],
  components: {
    DataParamsForm
  },
  data() {
    return {
      params: {
      }
    }
  },
  created() {
    this.builder.dataParams = this.params;
  },
  computed: {
    builder() {
      return this[builderKey];
    },
    rules() {
      return [
        (v) => (v.trim()).length > 0 || 'Required'
      ]
    },
  },
}
</script>

<template>
  <DataParamsForm>
    Not implemented
  </DataParamsForm>
</template>
