<template>
  <v-navigation-drawer
    id="drawer"
    v-model="drawer"
    app
    absolute
    left
    width="244"
    :touchless="touchless"
    :temporary="temporary"
    :mini-variant-width="navBar.miniWidth"
    :mini-variant="mini"
  >
    <template v-slot:prepend>
      <v-img
        v-if="mini"
        src="@/assets/img/marketradar/marketradar_32x32.png"
        contain
        width="32"
        height="32"
        class="ml-3 mt-6"
      />
      <v-img
        v-else
        src="@/assets/img/brands/marketradar.png"
        contain
        width="140"
        height="54"
        class="ml-5 mt-6"
      />
      <template v-if="isMobile">
        <v-list-item
          two-line
          class="void mt-4 py-5 pl-5"
          @click="navigate('/settings')"
        >
          <v-list-item-content>
            <v-list-item-title class="color__white-span">{{
              account
            }}</v-list-item-title>
            <span class="color__suvagrey-span">Настройки</span>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon>
              <v-icon size="24" :color="colors.suvaGrey">
                mdi-arrow-right-thick
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </template>

    <v-divider></v-divider>

    <template>
      <!-- Основное -->
      <v-list dense>
        <div v-for="item in menuBasicGroup" :key="item.title">
          <v-list-item
            v-if="!item.sublinks"
            @click="navigate(item.href)"
            link
            :class="[
              'nav-link',
              'py-5',
              'px-5',
              navBar.active === item.name && activeNavClass,
            ]"
          >
            <v-list-item-icon :class="['mr-5', mini && 'ml-4']">
              <v-icon
                :color="
                  navBar.active === item.name ? colors.white : colors.suvaGrey
                "
                size="22"
                >{{ item.icon }}</v-icon
              >
            </v-list-item-icon>

            <v-list-item-content>
              <span
                :class="
                  navBar.active === item.name
                    ? 'color__white-span'
                    : 'color__suvagrey-span'
                "
              >
                {{ item.title }}
                <counter
                  v-if="item.badge && item.badge.content"
                  color="red"
                  :value="item.badge.content"
                  class="ml-2"
                ></counter>
              </span>
            </v-list-item-content>
          </v-list-item>

          <v-list-group v-else no-action sub-group prepend-icon="mdi-menu-down">
            <template v-slot:activator>
              <v-list-item class="group-link px-0">
                <v-list-item-content>
                  <span class="color__suvagrey-span">{{ item.title }}</span>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item
              v-for="sublink in item.sublinks"
              link
              @click="navigate(sublink.href)"
              :class="[
                'nav-link',
                'py-5',
                'pl-10',
                navBar.active === sublink.name && activeNavClass,
              ]"
              :key="sublink.title"
            >
              <v-list-item-icon class="mr-5">
                <v-icon
                  :color="
                    navBar.active === sublink.name
                      ? colors.white
                      : colors.suvaGrey
                  "
                  size="22"
                  >{{ sublink.icon }}</v-icon
                >
              </v-list-item-icon>

              <v-list-item-content>
                <span
                  :class="
                    navBar.active === sublink.name
                      ? 'color__white-span'
                      : 'color__suvagrey-span'
                  "
                  >{{ sublink.title }}</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>
        <v-divider></v-divider>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import ScreenResolution from "@/mixins/ScreenResolution";
import ColorPalette from "@/mixins/ColorPalette";
import Counter from "@/components/counter/Counter";
import "./sidebar.css";

export default {
  name: "Sidebar",
  components: {
    Counter,
  },
  mixins: [ScreenResolution, ColorPalette],
  data() {
    return {
      touchless: true,
      activeNavClass: "active-nav",
    };
  },
  computed: {
    ...mapGetters("core", {
      navBar: "navBar",
    }),
    ...mapGetters("profile", {
      company: "company",
      profileIsActive: "profileIsActive",
    }),
    ...mapGetters("orders", {
      freshOrdersCount: "freshOrdersCount",
    }),
    account() {
      return this.company.name;
    },
    temporary() {
      if (this.windowSize.width <= 1024) {
        return true;
      }
      return false;
    },
    menuBasicGroup() {
      if (this.profileIsActive) {
        return [
          {
            title: "Статистика",
            icon: "mdi-chart-donut",
            href: "/dashboard",
            name: "Dashboard",
          },
          {
            title: "Работа с товарами",
            icon: "mdi-storefront-outline",
            href: "/products",
            name: "Products",
          },
          {
            title: "Работа с заказами",
            icon: "mdi-face-agent",
            href: "/orders",
            name: "Orders",
            badge: {
              content: this.freshOrdersCount > 0 && this.freshOrdersCount,
            },
          },
          {
            title: "Data Expert",
            icon: "mdi-spider-thread",
            href: "/dataexpert",
            name: "DataExpert",
          },
          {
            title: "Экспорт",
            icon: "mdi-google-analytics",
            sublinks: [
              {
                title: "Отчеты",
                icon: "mdi-finance",
                href: "/analytics/reports",
                name: "Reports",
              },
            ],
          },
        ];
      }
      return [];
    },
    drawer: {
      set(flag) {
        this.$store.dispatch("core/toggleDrawer", flag);
      },
      get() {
        return this.navBar.drawer;
      },
    },
    mini: {
      get() {
        return this.navBar.mini;
      },
    },
  },
  methods: {
    navigate(url) {
      this.$router.push(url);
    },
  },
};
</script>