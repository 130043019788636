<script>
import { BaseStage } from './shared'


export default {
  name: 'IntegrationVerification',
  extends: BaseStage,
  data() {
    return {
      verified: false,
      error: null,
      success: false,
    }
  },
  methods: {
    async integrationVerify() {
      this.builder.loading = true;
      try {
        await this.builder.dataVerify();
        this.success = true;
        this.verified = true;
      } catch (err) {
        this.error = err;
      } finally {
        this.builder.loading = false;
      }
    },
    activated() {
      this.integrationVerify();
    },
    deactivated() {
      this.verified = false;
      this.error = null;
      this.success = false;
    },
    verify() {
      return this.verified;
    }
  }
}
</script>

<template>
  <v-row class="integration-type-setter" justify="center">
    
    <v-alert width="100%" type="error" v-if="error">{{ error.message }}</v-alert>
    <v-alert width="100%" type="success" v-if="success">
      {{ $t('integration.addIntegration.stepper.step.verification.content.alert.successMessage') }}
    </v-alert>
    
    <v-progress-circular
      v-if="builder.loading"
      :size="70"
      :width="10"
      color="primary"
      indeterminate
    ></v-progress-circular>

    <v-card width="100%" v-if="success">
      <v-card-title>
        {{ $t('integration.addIntegration.stepper.step.verification.content.card.title') }}
      </v-card-title>
      
      <v-list-item>
        {{ $t('integration.addIntegration.stepper.step.verification.content.card.productsLabel' )}} {{ builder.integrationData.products.count }}
      </v-list-item>
      <v-list-item>
        {{ $t('integration.addIntegration.stepper.step.verification.content.card.pricesLabel' )}} {{ builder.integrationData.prices.length }}
      </v-list-item>
      <v-list-item>
        {{ $t('integration.addIntegration.stepper.step.verification.content.card.outletsLabel' )}} {{ builder.integrationData.outlets.length }}
      </v-list-item>
    </v-card>
  </v-row>
</template>
