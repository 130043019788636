const state = () => ({
    treeViewCategoriesForm: {
        dialog: false,
        treeViewOptions: {
            category: null,
            categories: [],
            rootCategory: null
        }
    }
});


const actions = {
    /*          TreeViewCategories form     */
    openTreeViewCategoriesForm({ commit, rootGetters, dispatch },
        { category, categories, rootCategory, isSellerCategories }
    ) {
        const kaspiCategoriesTree = rootGetters["platforms/kaspiCategoriesTree"];

        if (!kaspiCategoriesTree.length) {      // set cache
            isSellerCategories ? dispatch('platforms/fetchSellerCategoriesTree', null, { root: true }) 
                : dispatch('platforms/fetchCategoriesTree', null, { root: true });
        }
        
        commit('TOGGLE_TREEVIEW_CATEGORIES_FORM', true);
        dispatch("loadTreeViewOptions", { category, categories, rootCategory });
    },
    loadTreeViewOptions({ commit }, data) {
        commit('SET_TREE_VIEW_OPTIONS', data);
    },
    resetTreeViewOptions({ commit }) {
        commit('SET_TREE_VIEW_OPTIONS', {
            category: null,
            categories: [],
            rootCategory: null
        });
    },
    closeTreeViewCategoriesForm({ commit, dispatch }) {
        commit('TOGGLE_TREEVIEW_CATEGORIES_FORM', false);
        dispatch('resetTreeViewOptions');
    },
};

// mutations
const mutations = {
    TOGGLE_TREEVIEW_CATEGORIES_FORM(state, dialog) {
        state.treeViewCategoriesForm.dialog = dialog;
    },
    SET_TREE_VIEW_OPTIONS(state, options) {
        state.treeViewCategoriesForm.treeViewOptions = options;
    }

}

// getters
const getters = {
    treeViewCategoriesForm: (state) => state.treeViewCategoriesForm
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}

