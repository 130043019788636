<script>
import { INTEGRATION_BUILDER_KEY } from '../model';

const builderKey = INTEGRATION_BUILDER_KEY;

export default {
  name: 'InitIntegrationStageVue',
  inject: [builderKey],
  props: {
    isActive: Boolean,
    step: Number,
  },
  computed: {
    builder() {
      return this[builderKey];
    }
  },
  watch: {
    isActive(val) {
      if (val) this.$scopedSlots.default()[0].componentInstance.activated();
      else this.$scopedSlots.default()[0].componentInstance.deactivated();
    },
  },
  methods: {
    next() {
      const isValid = this.$scopedSlots.default()[0].componentInstance.verify();
      if (isValid) this.builder.next();
    },
    prev() {
      this.builder.prev();
    }
  },
}
</script>

<template>
  <v-stepper-content :step="step">
    <div class="mb-4">
      <slot></slot>
    </div>
    
    <v-alert type="error" v-if="builder.error">
      {{ builder.error.message }}
    </v-alert>

    <v-card-actions class="pa-0 justify-end gap-4">
      <v-btn
        height="40"
        width="120"
        class="figma-btn"
        outlined
        type="button"
        :disabled="builder.loading"
        @click="prev"
      >{{ $t("integration.addIntegration.stepper.button.prev") }}</v-btn>
      
      <v-btn
        v-if="builder.step !== builder.stepCount"
        height="40"
        width="120"
        color="primary"
        class="figma-btn white--text"
        type="button"
        :loading="builder.loading"
        @click="next"
      >{{ $t("integration.addIntegration.stepper.button.next") }}</v-btn>

      <v-btn
        v-else
        height="40"
        width="120"
        color="primary"
        class="figma-btn white--text"
        type="button"
        :loading="builder.loading"
        @click="next"
      >{{ $t("integration.addIntegration.stepper.button.finish") }}</v-btn>
    </v-card-actions>
  </v-stepper-content>
</template>
