import './types';
import OpenServerApi from "@/interfaces/OpenServerApi";
import { ADD_CITY_PATH, ADD_PLATFORM_PATH, DELETE_PLATFORM_PATH } from './constants';

class CompanyDataService {
    /**
     * Добавить платформу
     * @param {AddPlatformDTO} inputDTO 
     * @returns {Promise<void>}
     */
    addPlatform(inputDTO) {
        return OpenServerApi().post(ADD_PLATFORM_PATH, inputDTO);
    }
    /**
     * Удалить платформу
     * @param {DeletePlatformDTO} inputDTO 
     * @returns 
     */
    deletePlatform(inputDTO) {
        return OpenServerApi().post(DELETE_PLATFORM_PATH, inputDTO);
    }


    createCompany(payload) {
        return OpenServerApi().post('/company/create', payload);
    }
    getProfile() {
        return OpenServerApi().get('/company');
    }
    plugAnalytics() {
        return OpenServerApi().post('/analytics/orders/add');
    }
    createProductTag(tag) {
        return OpenServerApi().post('/settings/workspace/tags/add', tag);
    }
    updateProductTag(oldName, tag) {
        return OpenServerApi().post('/settings/workspace/tags/update', {
            oldName,
            ...tag
        });
    }
    updatePP(body) {
        return OpenServerApi().post('/settings/platformpp/update', body)
    }

    /**
     * Добавить точку вывоза
     * @param {AddPickupPointDTO} inputDTO
     * @return {Promise<void>}
     */
    addPickupPoint(inputDTO) {
        return OpenServerApi().post('/settings/platformpp/create', inputDTO);
    }

    /**
     * Удаление точки вывоза
     * @param {DeletePickupPointDTO} inputDTO 
     * @returns {Promise<void>}
     */
    deletePickupPoints(inputDTO) {
        return OpenServerApi().post('/settings/platformpp/delete', inputDTO);
    }

    /**
     * Обновить список городов
     * @param {PatchCitiesDTO} inputDTO 
     */
    patchCities(inputDTO) {
        return OpenServerApi().post(ADD_CITY_PATH, inputDTO);
    }
}

export default new CompanyDataService();
