import { notificatonStatuses, loadingStates, onlinePlatforms } from "@/enumerations";
import { getField, updateField } from 'vuex-map-fields';

const state = () => ({
    loadingStatus: loadingStates.BEGIN,
    layout: {
        title: 'n/A'
    },
    ui: {
        navBar: {
            active: null,
            drawer: true,
            mini: false,
            maxWidth: 244,
            miniWidth: 60
        },
        notification: {
            status: null,
            message: null
        },
        dialogs: {
            confirm: {
                dialog: false,
                type: null,
                headline: null,
                dialogText: null,
                cancelBtnLabel: "Отмена",
                confirmBtnLabel: "Да"
            },
            simple: {
                dialog: false,
                dialogText: null,
                cancelBtnLabel: "Закрыть",
            },
            thankYou: {
                dialog: false,
                dialogText: null,
                cancelBtnLabel: "Закрыть",
            }
        },
        marketBoxes: [
            {
                id: onlinePlatforms["ALL"],
                asset: require("@/assets/img/online-platforms/all_marketplaces.png"),
                alt: "Все платформы",
                link: "#",
                priority: 1,
                isLocked: false,
            },
            {
                id: onlinePlatforms["KASPI"],
                asset: require("@/assets/img/brands/kaspi.png"),
                alt: "Kaspi",
                link: "https://kaspi.kz/shop",
                priority: 2,
                isLocked: true,
            },
            {
                id: onlinePlatforms["FORTE"],
                asset: require("@/assets/img/brands/forte.png"),
                alt: "Forte",
                link: "https://market.forte.kz",
                priority: 3,
                isLocked: true,
            },
            {
                id: onlinePlatforms["HALYK"],
                asset: require("@/assets/img/online-platforms/halyk.png"),
                alt: "Halyk Market",
                link: "https://halykmarket.kz/",
                priority: 4,
                isLocked: true,
            },
            {
                id: onlinePlatforms["Jmart"],
                asset: require("@/assets/img/brands/jmart.png"),
                alt: "JMart",
                link: "https://jmart.kz",
                priority: 5,
                isLocked: true,
            },
            {
                id: onlinePlatforms["OZON"],
                asset: require("@/assets/img/online-platforms/ozon.png"),
                alt: "OZON",
                link: "https://ozon.ru/",
                priority: 6,
                isLocked: true,
            },
            {
                id: onlinePlatforms["WILDBERRIES"],
                asset: require("@/assets/img/online-platforms/wildberries-logo.png"),
                alt: "Wildberries",
                link: "https://www.wildberries.ru/",
                priority: 7,
                isLocked: true,
            },
            {
                id: onlinePlatforms["Satu"],
                asset: require("@/assets/img/online-platforms/satu.webp"),
                alt: "Satu.kz",
                link: "https://satu.kz",
                priority: 8,
                isLocked: true,
            },
            {
                id: onlinePlatforms["Tomas.kz"],
                asset: require("@/assets/img/online-platforms/tomas_2.png"),
                alt: "Tomas.kz",
                link: "https://tomas.kz",
                priority: 9,
                isLocked: true,
            },
            {
                id: onlinePlatforms["YandexMarket"],
                asset: require("@/assets/img/online-platforms/yandexmarket-logo.png"),
                alt: "YandexMarket",
                link: "https://market.yandex.kz",
                priority: 10,
                isLocked: true,
            },
            {
                id: onlinePlatforms["Website"],
                asset: require("@/assets/img/online-platforms/website-logo.png"),
                alt: "Интернет магазин",
                link: "https://marketradar.kz",
                priority: 11,
                isLocked: true,
            },
            {
                id: onlinePlatforms["Website2"],
                asset: require("@/assets/img/online-platforms/website-2-logo.png"),
                alt: "Интернет магазин 2",
                link: "https://marketradar.kz",
                priority: 12,
                isLocked: true,
            },
        ]
    }
});

const actions = {
    setTitle({ commit }, title) {
        commit("SET_TITLE", title);
    },
    /*     UI actions      */
    toggleConfirmDialog({ commit, state }) {
        commit('DISPLAY_CONFIRM_DIALOG', !state.ui.dialogs.confirm.dialog);
    },
    toggleDrawer({ commit }, flag) {
        commit('SET_DRAWER', flag);
    },
    autoMinimizeDrawer({ commit }, flag) {
        commit('CHANGE_DRAWER_SIZE', flag);
    },
    setActiveNav({ commit }, nav) {
        commit('SET_ACTIVE_NAV', nav);
    },
    setConfirmDialog({ commit }, options) {
        commit('SET_CONFIRM_DIALOG', options);
    },
    toggleSimpleDialog({ commit, state }) {
        commit('DISPLAY_SIMPLE_DIALOG', !state.ui.dialogs.simple.dialog);
    },
    setSimpleDialog({ commit }, dialogText) {
        commit('SET_SIMPLE_DIALOG', dialogText);
    },
    toggleThankYouDialog({ commit, state }) {
        commit('DISPLAY_THANK_YOU_DIALOG', !state.ui.dialogs.thankYou.dialog);
    },
    setThankYouDialog({ commit }, dialogText) {
        commit('SET_THANK_YOU_DIALOG', dialogText);
    },
    setInfoNotification({ commit }, message) {
        commit('SET_NOTIFICATION', {
            status: notificatonStatuses.success,
            message
        });
    },
    setWarnNotification({ commit }, message) {
        commit('SET_NOTIFICATION', {
            status: notificatonStatuses.warn,
            message
        });
    },
    setAlertNotification({ commit }, message = "Error") {
        commit('SET_NOTIFICATION', {
            status: notificatonStatuses.error,
            message
        });
    }
}

// mutations
const mutations = {
    updateField,
    SET_TITLE(state, title) {
        state.layout.title = title;
    },
    SET_DRAWER(state, drawer) {
        state.ui.navBar.drawer = drawer;
    },
    CHANGE_DRAWER_SIZE(state, mini) {
        state.ui.navBar.mini = mini;
    },
    SET_ACTIVE_NAV(state, activeNav) {
        state.ui.navBar.active = activeNav;
    },
    DISPLAY_SIMPLE_DIALOG(state, flag) {
        state.ui.dialogs.simple.dialog = flag;
    },
    SET_SIMPLE_DIALOG(state, dialogText) {
        state.ui.dialogs.simple.dialog = true;
        state.ui.dialogs.simple.dialogText = dialogText || "";
    },
    SET_THANK_YOU_DIALOG(state, dialogText) {
        state.ui.dialogs.thankYou.dialog = true;
        state.ui.dialogs.thankYou.dialogText = dialogText || "";
    },
    DISPLAY_THANK_YOU_DIALOG(state, flag) {
        state.ui.dialogs.thankYou.dialog = flag;
    },
    DISPLAY_CONFIRM_DIALOG(state, flag) {
        state.ui.dialogs.confirm.dialog = flag;
    },
    SET_CONFIRM_DIALOG(state, options) {
        state.ui.dialogs.confirm.dialog = true;
        state.ui.dialogs.confirm.type = options.type;
        state.ui.dialogs.confirm.headline = options.headline || "";
        state.ui.dialogs.confirm.dialogText = options.dialogText || "";
        state.ui.dialogs.confirm.cancelBtnLabel = options.cancelBtnLabel || "Отмена";
        state.ui.dialogs.confirm.confirmBtnLabel = options.confirmBtnLabel || "Да";
    },
    SET_NOTIFICATION(state, { status, message }) {
        state.ui.notification = {
            status, message
        };
    },
    SET_LOADING_STATUS(state, status, /*msg = ''*/) {
        state.loadingStatus = status;
    },
    SET_MARKET_DASHBOARD(state, boxes) {
        state.ui.marketBoxes = [...boxes]
    }
}

// getters
const getters = {
    layout: (state) => state.layout,
    notification: (state) => state.ui.notification,
    navBar: (state) => state.ui.navBar,
    marketBoxes: (state) => state.ui.marketBoxes,
    simpleDialog: (state) => state.ui.dialogs.simple,
    confirmDialog: (state) => state.ui.dialogs.confirm,
    thankYouDialog: (state) => state.ui.dialogs.thankYou,
    loadingStatus: (state) => state.loadingStatus,
    getField
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
