import Vue from 'vue';
import { getField, updateField } from 'vuex-map-fields';

const state = () => ({
    dataSourceSettingsForm: {
        dialog: false,
        platformID: null,
        enabledKaspi: false,
        kaspiMarginPercent: null,
        kaspiFeePercent: null,
        enabledHalyk: false,
        halykMarginPercent: null,
        jmartMarginPercent: null,
        ozonMarginPercent: null,
        halykLoanPeriod: null,
        enabledSatu: false,
    },
    tradingBotsForm: {
        dialog: false,
        tradingEnabled: true
    },
    priceMarkupForm: {
        dialog: false,
        marginPercent: null
    },
    priceMarginForm: {
        dialog: false,
        margin: null
    },
    priceDiscountForm: {
        dialog: false,
        discountPercent: null
    },
    deliveryPointsForm: {
        dialog: false,
        boxes: {}
    }
});

const actions = {
    /*      Data source settings form     */
    openDataSourceForm({ commit, rootGetters }, platformID) {
        const {
            enabledKaspi,
            kaspiFeePercent,
            kaspiMarginPercent,
            enabledHalyk,
            halykMarginPercent,
            jmartMarginPercent,
            ozonMarginPercent,
            halykLoanPeriod,
            enabledSatu, }
            = rootGetters["datasources/bucketInfo"]
        commit('SET_DATASOURCE_FORM', {
            dialog: true,
            enabledKaspi,
            kaspiFeePercent,
            kaspiMarginPercent,
            enabledHalyk,
            halykMarginPercent,
            jmartMarginPercent,
            ozonMarginPercent,
            halykLoanPeriod,
            enabledSatu,
            platformID,
        });
    },
    /*          Delivery point form             */
    openDeliveryPointsForm({ commit, rootGetters }) {
        const { kaspiPickupPoints } = rootGetters["profile/company"];
        var boxes = {};
        let points = kaspiPickupPoints && kaspiPickupPoints.length
            ? kaspiPickupPoints.map(pp => pp.name)
            : [];

        for (const pp of points) {
            boxes[pp] = false;
        }

        commit('TOGGLE_DELIVERY_POINTS_FORM', { dialog: true, boxes });
    },
    closeDeliveryPointsForm({ commit }) {
        commit('TOGGLE_DELIVERY_POINTS_FORM', { dialog: false, boxes: {} });
    },
    setDeliveryPoint({ commit, state }, key) {
        const box = state.deliveryPointsForm.boxes[key];
        let dispatch, preOrder;

        if (!box) {
            dispatch = true;
        }
        else {
            dispatch = !state.deliveryPointsForm.boxes[key].dispatch;
            preOrder = state.deliveryPointsForm.boxes[key].preOrder
                ? state.deliveryPointsForm.boxes[key].preOrder
                : 0;
        }

        commit('SET_DELIVERY_POINT', {
            key,
            dispatch,
            preOrder
        });
    },
    unselectAllDeliveryPoints({ commit }) {
        commit('RESET_ALL_DELIVERY_POINTS');
    },
    selectAllDeliveryPoints({ commit }) {
        commit('SELECT_ALL_DELIVERY_POINTS');
    },
    setPreOrder({ commit, state }, { key, preOrder = 0 }) {
        const { dispatch } = state.deliveryPointsForm.boxes[key];

        commit('SET_DELIVERY_POINT', {
            key,
            dispatch,
            preOrder
        });
    },
    /* bots */
    openTradingBotsForm({ commit }) {
        commit('DISPLAY_TRADING_BOTS_FORM');
    },
    setTradingBots({ commit }, tradingEnabled) {
        commit('SET_TRADING_BOTS', tradingEnabled);
    },
    closeTradingBotsForm({ commit }) {
        commit('RESET_TRADING_BOTS_FORM', {
            dialog: false,
            tradingEnabled: true
        });
    },
    /*          Price markup form             */
    openPriceMarkupForm({ commit }) {
        commit('DISPLAY_PRICE_MARKUP_FORM');
    },
    setPriceMarkup({ commit }, marginPercent) {
        commit('SET_PRICE_MARKUP', marginPercent);
    },
    closePriceMarkupForm({ commit }) {
        commit('RESET_PRICE_MARKUP_FORM', {
            dialog: false,
            marginPercent: null
        });
    },
    openPriceMarginForm({ commit }) {
        commit('DISPLAY_PRICE_MARGIN_FORM');
    },
    setPriceMargin({ commit }, margin) {
        commit('SET_PRICE_MARGIN', margin);
    },
    closePriceMarginForm({ commit }) {
        commit('RESET_PRICE_MARGIN_FORM', {
            dialog: false,
            margin: null
        });
    },
    openPriceDiscountForm({ commit }) {
        commit('DISPLAY_PRICE_DISCOUNT_FORM');
    },
    setPriceDiscount({ commit }, discountPercent) {
        commit('SET_PRICE_DISCOUNT', discountPercent);
    },
    closePriceDiscountForm({ commit }) {
        commit('RESET_PRICE_DISCOUNT_FORM', {
            dialog: false,
            discountPercent: null
        });
    },
}

// mutations
const mutations = {
    updateField,
    SET_DATASOURCE_FORM(state, settings) {
        state.dataSourceSettingsForm = { ...settings };
    },
    MUTATE_DATASOURCE_FORM_ITEM(state, { model, value }) {
        Vue.set(state.dataSourceSettingsForm, model, value);
    },
    // bots
    DISPLAY_TRADING_BOTS_FORM(state) {
        state.tradingBotsForm.dialog = true;
    },
    SET_TRADING_BOTS(state, tradingEnabled) {
        state.tradingBotsForm.tradingEnabled = tradingEnabled;
    },
    RESET_TRADING_BOTS_FORM(state, form) {
        state.tradingBotsForm = {
            ...form
        };
    },
    // markup
    DISPLAY_PRICE_MARKUP_FORM(state) {
        state.priceMarkupForm.dialog = true;
    },
    SET_PRICE_MARKUP(state, newPriceMarkup) {
        state.priceMarkupForm.marginPercent = newPriceMarkup;
    },
    RESET_PRICE_MARKUP_FORM(state, form) {
        state.priceMarkupForm = {
            ...form
        };
    },
    DISPLAY_PRICE_MARGIN_FORM(state) {
        state.priceMarginForm.dialog = true;
    },
    HIDE_PRICE_MARGIN_FORM(state) {
        state.priceMarginForm.dialog = false;
    },
    SET_PRICE_MARGIN(state, margin) {
        state.priceMarginForm.margin = margin;
    },
    RESET_PRICE_MARGIN_FORM(state, form) {
        state.priceMarginForm = {
            ...form
        };
    },
    DISPLAY_PRICE_DISCOUNT_FORM(state) {
        state.priceDiscountForm.dialog = true;
    },
    HIDE_PRICE_DISCOUNT_FORM(state) {
        state.priceDiscountForm.dialog = false;
    },
    SET_PRICE_DISCOUNT(state, discountPercent) {
        state.priceDiscountForm.discountPercent = discountPercent;
    },
    RESET_PRICE_DISCOUNT_FORM(state, form) {
        state.priceDiscountForm = {
            ...form
        };
    },
    TOGGLE_DELIVERY_POINTS_FORM(state, { dialog, boxes }) {
        state.deliveryPointsForm.dialog = dialog;
        state.deliveryPointsForm.boxes = boxes;
    },
    SET_DELIVERY_POINT(state, { key, dispatch, preOrder }) {
        state.deliveryPointsForm.boxes[key] = {
            dispatch, preOrder
        };
    },
    RESET_ALL_DELIVERY_POINTS(state) {
        for (let key of Object.keys(state.deliveryPointsForm.boxes)) {
            state.deliveryPointsForm.boxes[key] = {
                ...state.deliveryPointsForm.boxes[key],
                dispatch: false,
            }
        }
    },
    SELECT_ALL_DELIVERY_POINTS(state) {
        for (let key of Object.keys(state.deliveryPointsForm.boxes)) {
            state.deliveryPointsForm.boxes[key] = {
                ...state.deliveryPointsForm.boxes[key],
                dispatch: true,
            }
        }
    },
}

// getters
const getters = {
    dataSourceForm: (state) => state.dataSourceSettingsForm,
    tradingBotsForm: (state) => state.tradingBotsForm,
    priceMarkupForm: (state) => state.priceMarkupForm,
    priceMarginForm: (state) => state.priceMarginForm,
    priceDiscountForm: (state) => state.priceDiscountForm,
    deliveryPointsForm: (state) => state.deliveryPointsForm,
    allProductFormsAreClosed: (state) => {
        return !state.dataSourceSettingsForm.dialog &&
            !state.tradingBotsForm.dialog &&
            !state.priceMarkupForm.dialog &&
            !state.priceMarginForm.dialog &&
            !state.priceDiscountForm.dialog &&
            !state.deliveryPointsForm.dialog
    },
    getField
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}