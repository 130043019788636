import HashGenerator from "@/helpers/HashGenerator";

class SnapshotCache {
    static saveSnapshot(key, snapshot) {
        const ciphertext = HashGenerator.encrypt(
            key,
            typeof snapshot === 'object' ? JSON.stringify(snapshot) : snapshot
        );
        localStorage.setItem(key, ciphertext);
    }
    static removeSnapshot(key) {
        localStorage.removeItem(key);
    }
    static getSnapshot(key) {
        const ciphertext = localStorage.getItem(key);
        if (!ciphertext) {
            return false;
        }
        const descryptedSnapshot = HashGenerator.decrypt(key, ciphertext);
        return JSON.parse(descryptedSnapshot);
    }
    static removeAllSnapshots() {
        for (const key of Object.keys(localStorage)) {
            if (key.indexOf("snapshot_") >= 0) {
                console.log(`Snapshot key {${key}} was deleted.`)
                SnapshotCache.removeSnapshot(key);
            }
        }
    }
}

export default SnapshotCache;
