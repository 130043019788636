<script>
import VInfoBoard from '@/components/info-board/VInfoBoard.vue';

export default {
  components: {
    VInfoBoard
  }
}
</script>

<template>
  <v-row class="void" justify="center">
    <v-card elevation="0" min-height="582" class="void pa-4">
      <v-card-text>

        <v-info-board displayIcon="mdi-cable-data"
          message="Ошибка синхронизации! Обратитесь за помощью в тех.поддержку или же попробуйте выполнить данную операцию позднее"
        />

      </v-card-text>
    </v-card>
  </v-row>
</template>
