import TextFormatter from "@/helpers/TextFormatter";

function declOfNum(number, titles) {  
    let cases = [2, 0, 1, 1, 1, 2];  
    return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];  
}

export default {
    methods: {
        toDateFormat(dateString) {
            return dateString ? TextFormatter.dateFormat(dateString) : "—";
        },
        ruDateFormat(dateString) {
            return dateString ? TextFormatter.ruDateFormat(dateString) : "-";
        },
        dateTextFormat(dateString) {
            return dateString ? TextFormatter.dateTextFormat(dateString) : "-";
        },
        dateTextFormatWeekday(dateString) {
            return dateString ? TextFormatter.dateTextFormatWeekday(dateString) : "-";
        },
        dateTextFormatCompact(dateString) {
            return dateString ? TextFormatter.dateTextFormatCompact(dateString) : "-";
        },
        dateTextFormatDayMonth(dateString) {
            return dateString ? TextFormatter.dateTextFormatDayMonth(dateString) : "-";
        },
        timeFormat(dateString) {
            return dateString ? TextFormatter.timeFormat(dateString) : "-";
        },
        toMoneyFormat(money) {
            return money ? TextFormatter.moneyFormat(money) : "-";
        },
        formattedNumber(number) {
            return number ? TextFormatter.formattedNumber(number) : "-";
        },
        convertTimestamp2Date(seconds) {
            return new Date(seconds * 1000);
        },
        availableFormat(available) {
            return available ? "В наличии" : "Без наличия";
        },
        toProductsNumeral(count) {
            return `${count} ${declOfNum(count, ['товар', 'товара', 'товаров'])}`;
        },
        toKFormat(num) {
            return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num);
        }
    }
}