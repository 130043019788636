<script>
import DeleteIntegrationItem from './DeleteIntegration.item.vue';

export default {
  components: {
    DeleteIntegrationItem
  },
}
</script>

<template>
  <v-list>
    <DeleteIntegrationItem />
  </v-list>
</template>
