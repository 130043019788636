export class Stepper {
  /**
   * @type {number}
   */
  step = 1;

  /**
   * @type {number}
   */
  stepCount;
  
  /**
   * @param {number} stepCount 
   */
  constructor(stepCount) {
    this.stepCount = stepCount;
  }
  
  next() {
    if (this.step >= this.stepCount) {
      this.finish();
    } else {
      this.step++;
    }
  }

  prev() {
    if (this.step === 1) return;
    else {
      this.step--;
    }
  }

  finish() {
    throw new Error('Method not implemented')
  }
}
