<script>
import { editPriceParameters } from '../model';
import { PRICE_MAPPING } from '@/entities/integration';

export default {
  props: {
    id: String,
    priceParams: Array
  },
  data() {
    return {
      loading: false,
      error: null,
      params: [],
      mappintPriceTypes: Object.values(PRICE_MAPPING).map((priceType) => ({
        text: this.$t(`integration.mappingPrice.${priceType}`),
        value: priceType,
      })),
    }
  },
  created() {
    // копируем массивы без ссылок
    this.params = this.priceParams.map(el => ({...el}));
  },
  methods: {
    async save() {
      const isValid = this.$refs.form.validate();
      if (!isValid) return;

      this.loading = true;
      
      try {
        const params = [...this.params];

        await editPriceParameters(this.id, params);
        this.$emit('saved');
      } catch(error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      this.$emit('canceled');
    }
  },
  computed: {
    rules() {
      return [
        (val) => {
          if (val === 'IGNORE' || !this.params.length) return true;

          const items = this.params.filter(inp => inp.mappingTo === val);
          if (items.length > 1) return this.$t('integration.addIntegration.stepper.step.priceType.errors.dublicatPriceError');

          return true
        }
      ]
    }
  }
}
</script>

<template>
  <v-form ref="form" :disabled="this.loading" :value="true" @submit.prevent="save" class="d-flex flex-column edit-source-parameters-form">
    <v-alert v-if="error" type="error">{{ error.message || error }}</v-alert>

    <v-card>
      <v-card-text>
        <v-select
          v-for="price in params"
          :key="price.id"
          :label="price.name"
          :items="mappintPriceTypes"
          v-model="price.mappingTo"
          :rules="rules"
        />
      </v-card-text>
    </v-card>

    <div class="d-flex justify-space-between">
      <v-btn
        color="primary"
        class="figma-btn "
        outlined
        type="button"
        @click="cancel"
        :disabled="this.loading"
      >{{ $t('button.cancel') }}</v-btn>
      
      <v-btn
        color="primary"
        class="figma-btn "
        type="submit"
        :loading="this.loading"
      >{{ $t('button.save') }}</v-btn>
    </div>
  </v-form>
</template>

<style>
.edit-source-parameters-form {
  gap: 1rem;
}
</style>
