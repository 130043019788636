import OpenServerApi from "@/interfaces/OpenServerApi";

class PlatformsService {
    fetchKaspiCategoriesTree() {
        return OpenServerApi().get('/platforms/kaspi/categories');
    }

    downloadWildberriesStocksExcel() {
        return OpenServerApi().get(`/platforms/reports/wildberriesstocksexcel`, {
            responseType: 'blob'
        });
    }

    downloadWildberriesPricesExcel() {
        return OpenServerApi().get(`/platforms/reports/wildberriespricesexcel`, {
            responseType: 'blob'
        });
    }
}

export default new PlatformsService();