<template>
  <span :class="`meter ${color}`">{{ value }}</span>
</template>

<script>
export default {
  name: "Counter",
  props: {
    value: Number,
    color: String
  },
};
</script>

<style>
.meter {
  border-radius: 10px;
  color: #fff;
  height: 20px;
  letter-spacing: 0;
  padding: 0 3px;
  min-width: 20px;
  pointer-events: auto;
  position: absolute;
  text-align: center;
  text-indent: 0;
  top: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
}
</style>